<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>

    <div class="card_container" v-if="lotto.date_start">
      <div class="card_header">
        <v-row>
          <v-col xl="8" lg="8" md="12" sm="12" xs="12"  >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          </v-col>
          <v-col xl="4" lg="4" md="12" sm="12" xs="12"  >
            <v-select
                v-if="users.type === 'user'"
                v-model="employee_id"
                :items="employee"
                item-text="label"
                item-value="id"
                label="ข้อมูลพนักงาน"
                v-on:change="getLottoNumber(),GetEmployeeOneData()"
            ></v-select>
          </v-col>
          <v-row>
            <img class="loading_image_main" v-if="is_loading === true" src="./../../assets/image/loading.svg">
          </v-row>
        </v-row>
      </div>

      <v-row>
        <div class="print-icon-contrainer" align="right" >
          <v-icon v-on:click="OnPrint()" class="print-icon" aria-hidden="false">
            mdi-printer
          </v-icon>
        </div>
      </v-row>

      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 3 ตัว</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in tree_up_data_group" v-bind:key="result.id" xl="4" lg="4" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
            <table class="table-custom" border="1" >
              <tr class="header_print" >
                <th colspan="2" >เลข 3 ตัว</th>
              </tr>
              <tr>
                <th>ตัวเลข</th>
                <th>ราคาตัวตรง</th>
              </tr>
              <tr v-for="results in result" v-bind:key="results.id"  >
                <td>{{ results.numbers }}</td>
                <td>{{ results.price }}</td>
              </tr>
              <tr>
                <td class="sum_text" >รวมราคา</td>
                <td>{{ sumPrice(result) }}</td>
              </tr>
            </table>
          </div>

        </v-col>

      </v-row>


      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 3 ตัวโต๊ด</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in tree_tod_data_group" v-bind:key="result.id" xl="4" lg="4" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >เลข 3 ตัวโต๊ด</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคาตัวโต๊ด</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price_divide }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPriceDivide(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>


      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 2 ตัวบน</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in two_up_data_group" v-bind:key="result.id" xl="3" lg="3" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >เลข 2 ตัวบน</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPrice(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 2 ตัวล่าง</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in two_down_data_group" v-bind:key="result.id" xl="3" lg="3" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >เลข 2 ตัวล่าง</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPrice(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 2 ตัวโต๊ด</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in two_tod_data_group" v-bind:key="result.id" xl="3" lg="3" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >เลข 2 ตัวโต๊ด</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPrice(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 4 ตัวโต๊ด</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in four_tod_data_group" v-bind:key="result.id" xl="3" lg="3" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >เลข 4 ตัวโต๊ด</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPrice(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 5 ตัวโต๊ด</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in five_tod_data_group" v-bind:key="result.id" xl="3" lg="3" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >เลข 5 ตัวโต๊ด</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPrice(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >วิ่งบน</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in number_up_data_group" v-bind:key="result.id" xl="3" lg="3" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >วิ่งบน</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPrice(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>


      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >วิ่งล่าง</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in number_down_data_group" v-bind:key="result.id" xl="3" lg="3" md="6" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >วิ่งล่าง</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPrice(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>


      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >ปักบน</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in number_pick_up_data_group" v-bind:key="result.id" xl="12" lg="12" md="12" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="5" >ปักบน</th>
            </tr>
            <tr>
              <th rowspan="2" >ตัวเลข</th>
              <th colspan="3" >ราคา</th>
            </tr>
            <tr>
              <th>หน้า(ร้อย)</th>
              <th>กลาง(สิบ)</th>
              <th>หลัง(หน่วย)</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price_hundred }}</td>
              <td>{{ results.price_ten }}</td>
              <td>{{ results.price_unit }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPriceHundred(result) }}</td>
              <td>{{ sumPriceTen(result) }}</td>
              <td>{{ sumPriceUnit(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>


      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >ปักล่าง</h2>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-for="(result) in number_pick_down_data_group" v-bind:key="result.id" xl="12" lg="12" md="12" sm="12" xs="12" class="report_table" >
          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="4" >ปักล่าง</th>
            </tr>
            <tr>
              <th rowspan="2" >ตัวเลข</th>
              <th colspan="2" >ราคา</th>
            </tr>
            <tr>
              <th>กลาง(สิบ)</th>
              <th>หลัง(หน่วย)</th>
            </tr>
            <tr v-for="results in result" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price_ten }}</td>
              <td>{{ results.price_unit }}</td>
            </tr>
            <tr>
              <td class="sum_text" >รวมราคา</td>
              <td>{{ sumPriceTen(result) }}</td>
              <td>{{ sumPriceUnit(result) }}</td>
            </tr>
          </table>
          </div>
        </v-col>

      </v-row>


    </div>
    <div class="card_container" v-else>
      <div align="center" class="pt-5 pb-5">
        <h1>ไม่พบข้อมูลงวดกรุณาเริ่มงวดใหม่</h1>
      </div>
    </div>
  </v-breadcrumbs>
</template>

<script>
import {
  GetEmployee,
  GetLotto,
  GetLottoNumber,
  GetOneEmployee,
} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from "jquery";
import {number_suffle_three, ResponseData} from "../../lib/utility";


export default {
  name: "Users",
  data: () => ({
    baseUrl: baseUrl,
    valid: true,
    edit: false,
    id: false,

    day_:"",
    hour:"",
    minute:"",
    secound:"",



    tree_up_data: [],
    tree_up_data_group: [],
    tree_tod_data: [],
    tree_tod_data_group: [],
    two_up_data: [],
    two_up_data_group: [],
    two_down_data: [],
    two_down_data_group: [],
    two_tod_data: [],
    two_tod_data_group: [],
    four_tod_data: [],
    four_tod_data_group: [],
    five_tod_data: [],
    five_tod_data_group: [],
    number_up_data: [],
    number_up_data_group: [],
    number_down_data: [],
    number_down_data_group: [],
    number_pick_up_data: [],
    number_pick_up_data_group: [],
    number_pick_down_data: [],
    number_pick_down_data_group: [],
    three_up_limit_data: [],
    two_up_limit_data: [],
    two_down_limit_data: [],
    two_tod_limit_data: [],
    four_tod_limit_data: [],
    five_tod_limit_data: [],
    number_up_limit_data: [],
    number_down_limit_data: [],
    number_pick_up_limit_data: [],
    number_pick_down_limit_data: [],
    lotto: [],
    sum_price: [],
    users: {},
    employees: {},
    employee: {},
    machine_id: 0,
    orders: 0,
    title: "",
    employee_id: "",
    sum_percent:0,
    sum_total:0,

    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลแผนก',
        disabled: true,
        href: '/Department',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปชิ้นงาน',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'title'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],
    is_loading:false,
    remaining:{},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {},

  async created() {
    this.CheckLogin()
    await this.getLotto()
    await this.getLottoNumber()
    await this.GetEmployeeData()
  },

  methods: {

    async getLotto() {
      let Swal = this.$swal;
      this.is_loading = true;
      await GetLotto(this.users.merchant_id, (response) => {

        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            this.lotto = data;
            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });
    },
    async GetEmployeeOneData(){

      let Swal = this.$swal;


      await GetOneEmployee(this.employee_id, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)


        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;
            this.employees = data;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    async getLottoNumber() {

      let Swal = this.$swal;
      this.is_loading = true;

      const params = {}

      params.merchant_id = this.lotto.merchant_id
      params.lottery_id = this.lotto.id
      if (this.employee_id !== "") {
        params.employee_id = this.employee_id
      }


      await GetLottoNumber(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;

            //console.log("==========CheckThreeTod=========")
            //console.log(data.three_tod)
            //console.log("==========CheckThreeTod=========")
            this.CheckThreeTod(data);

            this.tree_up_data = data.three_up;
            this.tree_tod_data = data.three_tod;
            this.two_up_data = data.two_up;
            this.two_down_data = data.two_down;
            this.two_tod_data = data.two_tod;
            this.four_tod_data = data.four_tod;
            this.five_tod_data = data.five_tod;
            this.number_up_data = data.number_up;
            this.number_down_data = data.number_down;
            this.number_pick_up_data = data.number_pick_up;
            this.number_pick_down_data = data.number_pick_down;


            this.three_up_limit_data = data.three_up_limit;
            this.two_up_limit_data = data.two_up_limit;
            this.two_down_limit_data = data.two_down_limit;
            this.two_tod_limit_data = data.two_tod_limit;
            this.four_tod_limit_data = data.four_tod_limit;
            this.five_tod_limit_data = data.five_tod_limit;
            this.number_up_limit_data = data.number_up_limit;
            this.number_down_limit_data = data.number_down_limit;
            this.number_pick_up_limit_data = data.number_pick_up_limit;
            this.number_pick_down_limit_data = data.number_pick_down_limit;

            this.tree_summary = 0;
            this.tree_tod_summary = 0;
            this.two_summary = 0;
            this.two_down_summary = 0;
            this.two_tod_summary = 0;
            this.four_tod_summary = 0;
            this.five_tod_summary = 0;
            this.number_up_summary = 0;
            this.number_down_summary = 0;
            this.number_pickup_summary = 0;
            this.number_pickdown_summary = 0;

            this.tree_up_data.forEach((result) => {
              this.tree_summary += result.price ? result.price : 0;
              this.tree_tod_summary += result.price_divide ? result.price_divide : 0;
            });


            this.two_up_data.forEach((result) => {
              this.two_summary += result.price ? result.price : 0;
            });

            this.two_down_data.forEach((result) => {
              this.two_down_summary += result.price ? result.price : 0;
            });

            this.two_tod_data.forEach((result) => {
              this.two_tod_summary += result.price ? result.price : 0;
            });

            this.four_tod_data.forEach((result) => {
              this.four_tod_summary += result.price ? result.price : 0;
            });

            this.five_tod_data.forEach((result) => {
              this.five_tod_summary += result.price ? result.price : 0;
            });

            this.number_up_data.forEach((result) => {
              this.number_up_summary += result.price ? result.price : 0;
            });

            this.number_down_data.forEach((result) => {
              this.number_down_summary += result.price ? result.price : 0;
            });

            this.number_pick_up_data.forEach((result) => {
              this.number_pickup_summary += result.price_hundred ? result.price_hundred : 0;
              this.number_pickup_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickup_summary += result.price_unit ? result.price_unit : 0;
            });

            this.number_pick_down_data.forEach((result) => {
              this.number_pickdown_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickdown_summary += result.price_unit ? result.price_unit : 0;
            });

            this.GroupData()
            this.is_loading = false;

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    GroupData(){

      Array.prototype.chunk = function(size) {
        let result = [];

        while(this.length) {
          result.push(this.splice(0, size));
        }

        return result;
      }

      console.log("============GroupData============")
      this.tree_up_data_group = this.tree_up_data.chunk(30);
      this.tree_tod_data_group = this.tree_tod_data.chunk(30);
      this.two_up_data_group = this.two_up_data.chunk(30);
      this.two_down_data_group = this.two_down_data.chunk(30);
      this.two_tod_data_group = this.two_tod_data.chunk(30);
      this.four_tod_data_group = this.four_tod_data.chunk(30);
      this.five_tod_data_group = this.five_tod_data.chunk(30);
      this.number_up_data_group = this.number_up_data.chunk(30);
      this.number_down_data_group = this.number_down_data.chunk(30);
      this.number_pick_up_data_group = this.number_pick_up_data.chunk(30);
      this.number_pick_down_data_group = this.number_pick_down_data.chunk(30);




      console.log(this.tree_up_data_group)
      console.log(this.two_up_data_group)
      console.log("============GroupData============")
    },
    async GetEmployeeData(){

      let Swal = this.$swal;

      let params = {};

      params.merchant_id = this.lotto.merchant_id


      await GetEmployee(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        let employee = [];

        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            employee.push({
              "label":"ทั้งหมด",
              "id":0,
            })


            data.forEach(function (result){
              console.log(result)
              employee.push({
                "label":result.name,
                "id":result.id,
              })
            });

            this.employee = employee;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    CheckLogin() {

      const UserData = localStorage.getItem('user');
      if (!UserData && this.$router.currentRoute.path !== "/login") {
        window.location = "/login"
      } else {
        this.users = JSON.parse(UserData);
        if (this.users.type !== 'user') {
          this.employee_id = this.users.id;
          this.employees = this.users;
        }
        console.log(this.users)
      }
    },
    initialize() {

    },
    async validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        await this.AddUpdate();
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    sumPrice(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price;
      });

      return total;
    },
    sumPriceDivide(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_divide;
      });

      return total;
    },
    sumPriceHundred(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_hundred;
      });

      return total;
    },
    sumPriceTen(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_ten;
      });

      return total;
    },
    sumPriceUnit(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_unit;
      });

      return total;
    },
    CheckThreeTod(data){
      console.log("==========CheckThreeTod=========")

      let three_tod_numbers = [];

      data.three_tod.forEach((result)=>{


        three_tod_numbers.push({
          "numbers":result.numbers,
          "price_divide":result.price_divide,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let three_tod_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find_tod
        let find_tod_suffle

        if(three_tod_numbers_sort.length !== 0){
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_three(number_array);

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        console.log(find_tod)

        if(find_tod){
          find_tod.price_divide += result.price_divide;
        }else if(find_tod_suffle)
        {
          find_tod_suffle.price_divide += result.price_divide;
        }
        else{
          three_tod_numbers_sort.push({
            "numbers":result.numbers,
            "price_divide":result.price_divide,
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckThreeTod=========")
    },
    OnPrint(){
      window.print()
    },
  },


}


</script>

<style scoped>


@media print {
  .menu_custom_print{
    display: none;
  }
  .print-icon-contrainer{
    display: none;
  }
}

@media screen {

  .card_container {
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
  }

  .card_header {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
  }

  .card_content {
    padding: 1rem;
  }

  .img_thumb {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
  }

  .form_action {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .image_preview_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #image_preview {
    width: 20rem;
    text-align: center;
  }

  .btn_sale {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
  }

  .btn_sale:active {
    background: #0686E8;
    color: #FFFFFF;
  }

  .btn_sale.active {
    background: #0686E8;
    color: #FFFFFF;
  }

  .button_list {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sale_container {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form_cal {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
  }

  .input-custom {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
  }

  .table_custom {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
  }

  .head_title {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .head_title_sub {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .table_list {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .data_table {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .table_custom_summary {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
  }

  .head_title_summary {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .head_title_sub_summary {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .table_list_summary {
    width: 80%;
    display: flex;
    justify-content: center;
  }

  .data_table_summary {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
  }

  .delete_btn {
    color: red !important;
  }

  .btn_sale:disabled {
    filter: saturate(0);
  }

  .input-custom:disabled {
    filter: saturate(0);
  }

  .loading_image {
    height: 8rem;
    margin-bottom: -5rem;
  }
  .header_top_report{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
  }
  .table-custom{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  .table-custom tr th{
    padding: 0.5rem;
    padding-inline: 2rem;
  }
  .table-custom tr td{
    padding: 0.5rem;
    text-align: center;
  }
  .table-custom tr td.sum_text{
    text-align: left;
  }
  .report_table{
    padding: 0.5rem;
  }
  .loading_image_main{
    margin-block: -5rem;
  }
  .print-icon-contrainer{
    width: 100%;
    padding:1rem;
  }
  .print-icon{
    color: #0686E8 !important;
    font-size: 3rem;
    padding: 1rem;
    padding-bottom: 0;
  }
}

</style>

<template>

  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" v-if="lotto.date_start && lotto.is_open == 'true'">
      <div class="card_header">
        <div>ทำรายการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }} </div>
      </div>
      <div class="card_content">
        <v-row class="row">
          <v-col xl="8" lg="8">

            <div class="button_list row">
              <v-row justify="center" >
                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                  <button class="btn_sale" :class="{ active : tree_up === true}" v-on:click="ResetBtn(),tree_up = true,Checked()">3
                    ตัวบน
                  </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : two_up === true}" v-on:click="ResetBtn(),two_up = true,Checked()">2
                  ตัวบน
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : two_down === true}" v-on:click="ResetBtn(),two_down = true,Checked()">2
                  ตัวล่าง
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : two_tod === true}" v-on:click="ResetBtn(),two_tod = true,Checked()">2
                  ตัวโต๊ด
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : four_tod === true}" v-on:click="ResetBtn(),four_tod = true,Checked()">4
                  ตัวโต๊ด
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : five_tod === true}" v-on:click="ResetBtn(),five_tod = true,Checked()">5
                  ตัวโต๊ด
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : number_up === true}" v-on:click="ResetBtn(),number_up = true,Checked()">
                  วิ่งบน
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : number_down === true}"
                        v-on:click="ResetBtn(),number_down = true,Checked()">วิ่งล่าง
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : number_pick_up === true}"
                        v-on:click="ResetBtn(),number_pick_up = true,Checked()">วิ่งปักบน
                </button>
                  </v-row>
                </v-col>

                <v-col xl="3" lg="3" md="6" sm="6" xs="6" cols="6"  >
                  <v-row justify="center" class="pa-3" >
                <button class="btn_sale" :class="{ active : number_pick_down === true}"
                        v-on:click="ResetBtn(),number_pick_down = true,Checked()">วิ่งปักล่าง
                </button>
                  </v-row>
                </v-col>

              </v-row>


            </div>

            <div v-if="is_close" @click="is_close = false" class="is_limit" >ตัวเลขที่ถูกปิดรับ {{ number_close.length ?  number_close.toString() : '' }}</div>

            <div class="sale_container" v-if="tree_up === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true' " >
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                    <font>3 ตัวบน</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                    <input type="number"
                           ref="tree_ip_1"
                           @keyup.enter="NextInput('tree_ip_2')"
                           @input="ThreeUpLength"
                           placeholder="ตัวเลข"
                           :value="tree_up_number"
                           class="input-custom"/>
                    <font>=</font>
                    <input type="number"
                           placeholder="ตัวตรง"
                           @keyup.enter="NextInput('tree_ip_3')"
                           ref="tree_ip_2"
                           v-model="tree_up_price" class="input-custom"/>
                    <font>X</font>
                    <input type="number" placeholder="โต๊ด"
                           @keyup.enter="[CheckThreeUp() === false && AddTreeUp(),NextInput('tree_ip_1')]"
                           ref="tree_ip_3"
                           :disabled="tree_up_group === true || this.tree_up_number.length === 4"
                           v-model="tree_up_price_divide" class="input-custom"/>

                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                    <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddTreeUp()" :disabled="CheckThreeUp()" >ตกลง</button>
                  </v-col>
                </v-row>



              </div>

              <div class="form_cal mt-2" v-if="users.type !== 'user' && lotto.is_edit == 'true'" >
                <v-row class="pa-3" >
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                    <font> </font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input"  >
                    <input type="number"
                           ref="tree_ip_1g"
                           @keyup.enter="NextInput('tree_ip_2g')"
                           @input="ThreeUpGroupLength"
                           placeholder="ตัวเลข"
                           :value="tree_up_number_group"
                           class="input-custom"/>
                    <font>=</font>
                    <input type="number" placeholder="ตัวตรง"
                           ref="tree_ip_2g"
                           @keyup.enter="CheckThreeUpGroup() === false && AddTreeGroup()"
                           v-model="tree_up_price_group" class="input-custom"/>

                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                    <button v-if="this.is_loading === false" class="btn_sale" :disabled="CheckThreeUpGroup()"
                            v-on:click="tree_up_group = true,AddTreeGroup()">
                      ชุด
                    </button>
                  </v-col>
                </v-row>
              </div>






              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">


              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select @change="Checked" class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select @change="Checked"  class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in tree_up_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>


              <div class="table_list">
                <table class="table_custom table_list_responsive" border="1">
                  <tr>
                    <th  :colspan="lotto.is_edit == 'true' ? 7 : 6" class="head_title">3 ตัวบน</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>

                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคาตรง</th>
                    <th class="head_title_sub">ราคาโต๊ด</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in tree_up_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                          class="checkbox_main"
                          v-model="delete_select"
                          :value="result.id"
                      ></v-checkbox>

                    </td>
                    <td class="data_table"  >  {{ result.numbers }}</td>
                    <td class="data_table"  >  {{ result.price }}</td>
                    <td class="data_table"  >  {{ result.price_divide }}</td>
                    <td class="data_table"  > {{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="two_up === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>2 ตัวบน</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number" placeholder="ตัวเลข"
                       ref="two_ip_up_1"
                       @keyup.enter="NextInput('two_ip_up_2')"
                       @input="TwoUpLength"
                       :value="two_up_number"
                       class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="two_ip_up_2"
                       @keyup.enter="NextInput('two_ip_up_3')"
                       placeholder="ตัวตรง" v-model="two_up_price" class="input-custom"/>
                <font>X</font>
                <input type="number"
                       ref="two_ip_up_3"
                       @keyup.enter="[CheckTwoUp() === false && AddTwoUp(),NextInput('two_ip_up_1')]"
                       placeholder="กลับ" v-model="two_up_price_divide" class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddTwoUp()" :disabled="CheckTwoUp()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>








              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">

              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in two_up_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>

              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th colspan="6" class="head_title">2 ตัวบน</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>
                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคา</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in two_up_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>
                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="two_down === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>2 ตัวล่าง</font>
                </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number" placeholder="ตัวเลข"
                       ref="two_ip_down_1"
                       @keyup.enter="NextInput('two_ip_down_2')"
                       @input="TwoDownLength"
                       :value="two_down_number"
                       class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="two_ip_down_2"
                       @keyup.enter="NextInput('two_ip_down_3')"
                       placeholder="ตัวตรง" v-model="two_down_price"
                       class="input-custom"/>
                <font>X</font>
                <input type="number" placeholder="กลับ"
                       ref="two_ip_down_3"
                       @keyup.enter="[CheckTwoDown() === false && AddTwoDown(),NextInput('two_ip_down_1')]"
                       v-model="two_down_price_divide" class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddTwoDown()" :disabled="CheckTwoDown()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>




              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">

              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in two_down_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>

              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th :colspan="lotto.is_edit == 'true' ? 6 : 5" class="head_title">2 ตัวล่าง</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>

                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคา</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in two_down_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>

                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="two_tod === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>2 ตัวโต๊ด</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number" placeholder="ตัวเลข"
                       ref="two_ip_tod_1"
                       @keyup.enter="NextInput('two_ip_tod_2')"
                       @input="TwoTodLength"
                       :value="two_tod_number"
                       class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="two_ip_tod_2"
                       @keyup.enter="[CheckTwoTod() === false && AddTwoTod(),NextInput('two_ip_tod_1')]"
                       placeholder="ราคา" v-model="two_tod_price"
                       class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddTwoTod()" :disabled="CheckTwoTod()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>




              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">


              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in two_tod_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>


              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th :colspan="lotto.is_edit == 'true' ? 6 : 5" class="head_title">2 ตัวโต๊ด</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>
                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคา</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in two_tod_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>

                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="four_tod === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>4 ตัวโต๊ด</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number"   placeholder="ตัวเลข"
                       ref="two_ip_four_1"
                       @keyup.enter="NextInput('two_ip_four_2')"
                       @input="FourTodLength"
                       :value="four_tod_number"
                       class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="two_ip_four_2"
                       @keyup.enter="[CheckFourTod() === false && AddFourTod(),NextInput('two_ip_four_1')]"
                       placeholder="ราคา" v-model="four_tod_price" class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddFourTod()" :disabled="CheckFourTod()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>

              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">


              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in four_tod_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>


              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th  :colspan="lotto.is_edit == 'true' ? 6 : 5" class="head_title">4 ตัวโต๊ด</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>
                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคา</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in four_tod_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>

                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="five_tod === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>5 ตัวโต๊ด</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number"
                       ref="two_ip_five_1"
                       @keyup.enter="NextInput('two_ip_five_2')"
                       @input="FiveTodLength"
                       :value="five_tod_number"
                       placeholder="ตัวเลข" maxlength="5"  class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="two_ip_five_2"
                       @keyup.enter="[CheckFiveTod() === false && AddFiveTod(),NextInput('two_ip_five_1')]"
                       placeholder="ราคา" v-model="five_tod_price" class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddFiveTod()" :disabled="CheckFiveTod()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>


              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">

              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in five_tod_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>

              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th colspan="6" class="head_title">5 ตัวโต๊ด</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>
                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคา</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in five_tod_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>
                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="number_up === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>วิ่งบน</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number"
                       ref="numberup_ip_1"
                       @keyup.enter="NextInput('numberup_ip_2')"
                       @input="NumberUpTodLength"
                       placeholder="ตัวเลข"
                       :value="number_up_number"
                       class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="numberup_ip_2"
                       @keyup.enter="[CheckNumberUp() === false && AddNumberUp(),NextInput('numberup_ip_1')]"
                       placeholder="ราคา" v-model="number_up_price" class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddNumberUp()" :disabled="CheckNumberUp()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>



              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">


              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in number_up_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>

              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th  :colspan="lotto.is_edit == 'true' ? 7 : 6" class="head_title">วิ่งบน</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>

                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคา</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in number_up_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>

                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="number_down === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>วิ่งล่าง</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number"
                       ref="number_down_1"
                       @keyup.enter="NextInput('number_down_2')"
                       placeholder="ตัวเลข"
                       @input="NumberDownTodLength"
                       :value="number_down_number"
                       class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="number_down_2"
                       @keyup.enter="[CheckNumberDown() === false && AddNumberDown(),NextInput('number_down_1')]"
                       placeholder="ราคา" v-model="number_down_price" class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddNumberDown()" :disabled="CheckNumberDown()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>




              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">

              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in number_down_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>

              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th  :colspan="lotto.is_edit == 'true' ? 6 : 5" class="head_title">วิ่งล่าง</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>
                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคา</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in number_down_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">

                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>

                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="number_pick_up === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>ปักบน</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number"
                       ref="number_pick_up_1"
                       @keyup.enter="NextInput('number_pick_up_2')"
                       @input="NumberPickupLength"
                       placeholder="ตัวเลข"
                       :value="number_pick_up_number"
                       class="input-custom"/>
                <font>=</font>
                <input
                      ref="number_pick_up_2"
                      @keyup.enter="NextInput('number_pick_up_3')"
                      type="number" placeholder="หน้า"
                      v-model="number_pick_up_hundred"
                      class="input-custom-pick"/>
                <font>X</font>
                <input type="number"
                       ref="number_pick_up_3"
                       @keyup.enter="NextInput('number_pick_up_4')"
                       placeholder="กลาง"
                       v-model="number_pick_up_tens"
                       class="input-custom-pick"/>
                <font>X</font>
                <input type="number" placeholder="หลัง" v-model="number_pick_up_unit"
                       ref="number_pick_up_4"
                       @keyup.enter="[CheckNumberPickUp() === false && AddNumberPickUp(),NextInput('number_pick_up_1')]"
                       class="input-custom-pick"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddNumberPickUp()" :disabled="CheckNumberPickUp()">ตกลง</button>
                  </v-col>
                </v-row>
              </div>


              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">


              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in number_pick_up_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>


              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th  :colspan="lotto.is_edit == 'true' ? 8 : 7" class="head_title">ปักบน</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>
                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคาหลักร้อย</th>
                    <th class="head_title_sub">ราคาหลักสิบ</th>
                    <th class="head_title_sub">ราคาหลักหน่วย</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>

                  </tr>
                  <tr v-for="(result) in number_pick_up_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>
                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price_hundred }}</td>
                    <td class="data_table">{{ result.price_ten }}</td>
                    <td class="data_table">{{ result.price_unit }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="sale_container" v-if="number_pick_down === true">
              <div class="form_cal" v-if="users.type !== 'user' && lotto.is_edit == 'true'">
                <v-row>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <font>ปักล่าง</font>
                  </v-col>
                  <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <input type="number" placeholder="ตัวเลข"
                       ref="number_pick_down_1"
                       @keyup.enter="NextInput('number_pick_down_2')"
                       @input="NumberPickDownLength"
                       :value="number_pick_down_number"
                       class="input-custom"/>
                <font>=</font>
                <input type="number"
                       ref="number_pick_down_2"
                       @keyup.enter="NextInput('number_pick_down_3')"
                       placeholder="หน้า" v-model="number_pick_down_tens" class="input-custom"/>
                <font>X</font>
                <input type="number"
                       placeholder="หลัง"
                       ref="number_pick_down_3"
                       @keyup.enter="[CheckNumberPickDown() === false && AddNumberPickDown(),NextInput('number_pick_down_1')]"
                       v-model="number_pick_down_unit" class="input-custom"/>
                  </v-col>
                  <v-col xl="2" lg="2" md="12" sm="12" xs="12" cols="12" class="list_input" >
                <button class="btn_sale" v-if="this.is_loading === false" v-on:click="AddNumberPickDown()" :disabled="CheckNumberPickDown()">ตกลง
                </button>
                  </v-col>
                </v-row>
              </div>



              <img v-if="is_loading === true" class="loading_image" src="../../assets/image/loading.svg">

              <v-row class="mt-5 filter-list" >
                <v-col xl="4"  >
                  จำนวน<br>
                  <select class="select-custom" v-model="limit_page"  v-on:change="getLottoNumberSelect()"  >
                    <option value="5" >5</option>
                    <option value="10" >10</option>
                    <option value="20" >20</option>
                    <option value="30" >30</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="500" >500</option>
                  </select>
                </v-col>
                <v-col xl="4" ></v-col>
                <v-col xl="4" align="right" >
                  หน้า<br>
                  <select class="select-custom" v-model="page" >
                    <option  v-for="(result,index) in number_pick_down_data_group" v-bind:key="result.id" :value="index" >{{ (index+1) }}</option>
                  </select>
                </v-col>
              </v-row>

              <div class="table_list">
                <table class="table_custom" border="1">
                  <tr>
                    <th :colspan="lotto.is_edit == 'true' ? 7 : 5" class="head_title">ปักล่าง</th>
                  </tr>
                  <tr>
                    <th class="head_title_sub" v-if="lotto.is_edit == 'true'"  >
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="DeleteSelectAll"
                      ></v-checkbox>
                    </th>
                    <th class="head_title_sub">เลข</th>
                    <th class="head_title_sub">ราคาหลักสิบ</th>
                    <th class="head_title_sub">ราคาหลักหน่วย</th>
                    <th class="head_title_sub">ผู้ใช้</th>
                    <th class="head_title_sub">เวลา</th>
                    <th class="head_title_sub">เลขอั้น</th>
                  </tr>
                  <tr v-for="(result) in number_pick_down_data_group[page]" v-bind:key="result.id">
                    <td class="data_table">
                      <v-checkbox v-if="lotto.is_edit == 'true'"
                                  class="checkbox_main"
                                  v-model="delete_select"
                                  :value="result.id"
                      ></v-checkbox>
                    </td>
                    <td class="data_table">{{ result.numbers }}</td>
                    <td class="data_table">{{ result.price_ten }}</td>
                    <td class="data_table">{{ result.price_unit }}</td>
                    <td class="data_table">{{ result.employee ? result.employee.name : "-" }}</td>
                    <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
                    <td class="data_table">{{ result.is_limit == 'true' ? 'อั้น':'-' }}</td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="col-3" >
              <button class="btn_del_cut_full" v-if="is_loading === false && this.delete_select.length !== 0"
                      v-on:click="RemoveSelect()" >ลบที่เลือก</button>
            </div>



            <div>

            </div>

          </v-col>


          <v-col xl="4" lg="4">

            <v-select
                v-if="users.type === 'user'"
                v-model="employee_id"
                :items="employee"
                item-text="label"
                item-value="id"
                label="ข้อมูลพนักงาน"
                v-on:change="getLottoNumber(),GetEmployeeOneData()"
            ></v-select>

            <table class="table_custom_summary" border="1">
              <tr>
                <th class="head_title_sub_summary">ชนิดเลข</th>
                <th class="head_title_sub_summary">ราคาจำหน่ายทั้งหมด</th>
                <th class="head_title_sub_summary">%</th>
                <th class="head_title_sub_summary">คงเหลือ</th>
              </tr>
              <tr>
                <td class="data_table_summary">เลข 3 ตัวตรง</td>
                <td class="data_table_summary">{{ this.tree_summary }}</td>
                <td class="data_table_summary">{{ this.tree_summary !== 0 && this.employees.id  ? ((this.employees.three_up * this.tree_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.tree_summary !== 0 && this.employees.id  ? (this.tree_summary-((this.employees.three_up * this.tree_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">เลข 3 ตัวโต๊ด</td>
                <td class="data_table_summary">{{ this.tree_tod_summary }}</td>
                <td class="data_table_summary">{{ this.tree_tod_summary !== 0 && this.employees.id  ? ((this.employees.three_tod * this.tree_tod_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.tree_tod_summary !== 0 && this.employees.id  ?  (this.tree_tod_summary-((this.employees.three_tod * this.tree_tod_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">เลข 2 ตัวบน</td>
                <td class="data_table_summary">{{ this.two_summary }}</td>
                <td class="data_table_summary">{{ this.two_summary !== 0 && this.employees.id  ? ((this.employees.two_up * this.two_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.two_summary !== 0 && this.employees.id  ?  (this.two_summary-((this.employees.two_up * this.two_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">เลข 2 ตัวล่าง</td>
                <td class="data_table_summary">{{ this.two_down_summary }}</td>
                <td class="data_table_summary">{{ this.two_down_summary !== 0 && this.employees.id  ? ((this.employees.two_down * this.two_down_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.two_down_summary !== 0 && this.employees.id  ?  (this.two_down_summary-((this.employees.two_down * this.two_down_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">เลข 2 ตัวโต๊ด</td>
                <td class="data_table_summary">{{ this.two_tod_summary }}</td>
                <td class="data_table_summary">{{ this.two_tod_summary !== 0 && this.employees.id  ? ((this.employees.two_tod * this.two_tod_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.two_tod_summary !== 0 && this.employees.id  ?  (this.two_tod_summary-((this.employees.two_tod * this.two_tod_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">เลข 4 ตัวโต๊ด</td>
                <td class="data_table_summary">{{ this.four_tod_summary }}</td>
                <td class="data_table_summary">{{ this.four_tod_summary !== 0 && this.employees.id  ? ((this.employees.four_tod * this.four_tod_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.four_tod_summary !== 0 && this.employees.id  ?  (this.four_tod_summary-((this.employees.four_tod * this.four_tod_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">เลข 5 ตัวโต๊ด</td>
                <td class="data_table_summary">{{ this.five_tod_summary }}</td>
                <td class="data_table_summary">{{ this.five_tod_summary !== 0 && this.employees.id  ? ((this.employees.five_tod * this.five_tod_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.five_tod_summary !== 0 && this.employees.id  ?  (this.five_tod_summary-((this.employees.five_tod * this.five_tod_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">วิ่งบน</td>
                <td class="data_table_summary">{{ this.number_up_summary }}</td>
                <td class="data_table_summary">{{ this.number_up_summary !== 0 && this.employees.id  ? ((this.employees.number_up * this.number_up_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.number_up_summary !== 0 && this.employees.id  ?  (this.number_up_summary-((this.employees.number_up * this.number_up_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">วิ่งล่าง</td>
                <td class="data_table_summary">{{ this.number_down_summary }}</td>
                <td class="data_table_summary">{{ this.number_down_summary !== 0 && this.employees.id  ? ((this.employees.number_down * this.number_down_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.number_down_summary !== 0 && this.employees.id  ?  (this.number_down_summary-((this.employees.number_down * this.number_down_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">วิ่งปักตำแหน่งบน</td>
                <td class="data_table_summary">{{ this.number_pickup_summary }}</td>
                <td class="data_table_summary">{{ this.number_pickup_summary !== 0 && this.employees.id  ? ((this.employees.number_pick_up * this.number_pickup_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.number_pickup_summary !== 0 && this.employees.id  ?  (this.number_pickup_summary-((this.employees.number_pick_up * this.number_pickup_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">วิ่งปักตำแหน่งล่าง</td>
                <td class="data_table_summary">{{ this.number_pickdown_summary }}</td>
                <td class="data_table_summary">{{ this.number_pickdown_summary !== 0 && this.employees.id  ? ((this.employees.number_pick_down * this.number_pickdown_summary)/100) : 0  }}</td>
                <td class="data_table_summary">{{ this.number_pickdown_summary !== 0 && this.employees.id  ?  (this.number_pickdown_summary-((this.employees.number_pick_down * this.number_pickdown_summary)/100)) : 0 }}</td>
              </tr>
              <tr>
                <td class="data_table_summary">รวมจำหน่ายทั้งหมด</td>
                <td class="data_table_summary">{{
                    (
                        this.number_pickdown_summary +
                        this.tree_summary +
                        this.tree_tod_summary +
                        this.two_summary +
                        this.two_down_summary +
                        this.two_tod_summary +
                        this.four_tod_summary +
                        this.five_tod_summary +
                        this.number_up_summary +
                        this.number_down_summary +
                        this.number_pickup_summary +
                        this.number_pickdown_summary +
                        this.number_pickdown_summary
                    ).toFixed(2)
                  }}
                </td>
                <td class="data_table_summary">
                  {{
                    this.employees.id  ?  (((this.employees.three_up * this.tree_summary)/100)+
                    ((this.employees.three_tod * this.tree_tod_summary)/100)+
                    ((this.employees.two_up * this.two_summary)/100)+
                    ((this.employees.two_down * this.two_down_summary)/100)+
                    ((this.employees.two_tod * this.two_tod_summary)/100)+
                    ((this.employees.four_tod * this.four_tod_summary)/100)+
                    ((this.employees.five_tod * this.five_tod_summary)/100)+
                    ((this.employees.number_up * this.number_up_summary)/100)+
                    ((this.employees.number_down * this.number_down_summary)/100)+
                    ((this.employees.number_pick_up * this.number_pickup_summary)/100)+
                    ((this.employees.number_pick_down * this.number_pickdown_summary)/100)).toFixed(2) : 0
                  }}
                </td>
                <td class="data_table_summary">
                  {{
                    this.employees.id  ?
                        ((this.tree_summary-((this.employees.three_up * this.tree_summary)/100))+
                    (this.tree_tod_summary-((this.employees.three_tod * this.tree_tod_summary)/100))+
                    (this.two_summary-((this.employees.two_up * this.two_summary)/100))+
                    (this.two_down_summary-((this.employees.two_down * this.two_down_summary)/100))+
                    (this.two_tod_summary-((this.employees.two_tod * this.two_tod_summary)/100))+
                    (this.four_tod_summary-((this.employees.four_tod * this.four_tod_summary)/100))+
                    (this.five_tod_summary-((this.employees.five_tod * this.five_tod_summary)/100))+
                    (this.number_up_summary-((this.employees.number_up * this.number_up_summary)/100))+
                    (this.number_down_summary-((this.employees.number_down * this.number_down_summary)/100))+
                    (this.number_pickup_summary-((this.employees.number_pick_up * this.number_pickup_summary)/100))+
                    (this.number_pickdown_summary-((this.employees.number_pick_down * this.number_pickdown_summary)/100))).toFixed(2) : 0
                  }}
                </td>
              </tr>
            </table>


            <table v-if="tree_up === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="5" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
                <th class="head_title_sub_summary">เวลา</th>
              </tr>
              <tr v-for="(result) in three_up_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
                <td class="data_table" v-html="result.date_thai_add_front_sort"  ></td>
              </tr>
            </table>


            <table v-if="two_up === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in two_up_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>


            <table v-if="two_down === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in two_down_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>


            <table v-if="two_tod === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in two_tod_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>


            <table v-if="four_tod === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in four_tod_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>


            <table v-if="five_tod === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in five_tod_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>


            <table v-if="number_up === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in number_up_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>


            <table v-if="number_down === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in number_down_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>

            <table v-if="number_pick_up === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in number_pick_up_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>


            <table v-if="number_pick_down === true" class="table_custom_summary" border="1">
              <tr>
                <th colspan="3" class="head_title_sub_summary">รายการอั้น</th>
              </tr>
              <tr>
                <th class="head_title_sub_summary">เลข</th>
                <th class="head_title_sub_summary">ปิดรับ</th>
              </tr>
              <tr v-for="(result) in number_pick_down_limit_data" v-bind:key="result.id" >
                <td  class="data_table" >{{ result.numbers }}</td>
                <td  class="data_table" >{{ result.is_close === "true" ? "ปิด":"-"  }}</td>
              </tr>
            </table>

          </v-col>

        </v-row>


      </div>
    </div>
    <div class="card_container" v-else>
      <div align="center" class="pt-5 pb-5">
        <h1 v-if="lotto.is_open == 'false'" >ไม่สามารถดูข้อมูลได้</h1>
        <h1 v-else >ไม่พบข้อมูลงวดกรุณาเริ่มงวดใหม่</h1>
      </div>

    </div>
  </v-breadcrumbs>
</template>

<script>
import {
  DeleteMultipleNumber,
  DeleteOneNumber,
  GetEmployee,
  GetLotto,
  GetLottoNumber,
  GetOneEmployee,
  PostLottoNumber,
  PostLottoNumberLimit
} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from "jquery";
import {number_suffle_three, ResponseData} from "../../lib/utility";
import moment from 'moment';

export default {
  name: "Sale",
  title: 'Default Title',
  // all titles will be injected into this template
  titleTemplate: '%s | My Awesome Webapp',
  data: () => ({
    baseUrl: baseUrl,
    valid: true,
    edit: false,
    id: false,

    day_:"",
    hour:"",
    minute:"",
    secound:"",

    tree_up: true,
    tree_up_group: false,
    tree_up_number: "",
    tree_up_number_group: "",
    tree_up_price: "",
    tree_up_price_group: "",
    tree_up_price_divide: "",

    two_up: false,
    two_up_number: "",
    two_up_price: "",
    two_up_price_divide: "",

    two_down: false,
    two_down_number: "",
    two_down_price: "",
    two_down_price_divide: "",

    two_tod: false,
    two_tod_number: "",
    two_tod_price: "",
    two_tod_price_divide: "",

    four_tod: false,
    four_tod_number: "",
    four_tod_price: "",
    four_tod_price_divide: "",

    five_tod: false,
    five_tod_number: "",
    five_tod_price: "",
    five_tod_price_divide: "",

    number_up: false,
    number_up_number: "",
    number_up_price: "",
    number_up_price_divide: "",

    number_down: false,
    number_down_number: "",
    number_down_price: "",
    number_down_price_divide: "",

    number_pick_up: false,
    number_pick_up_number: "",
    number_pick_up_hundred: "",
    number_pick_up_tens: "",
    number_pick_up_unit: "",

    number_pick_down: false,
    number_pick_down_number: "",
    number_pick_down_tens: "",
    number_pick_down_unit: "",

    three_limit_number : "",
    three_limit_buy : "",
    three_limit_percent : "",
    three_limit_flip : false,
    three_limit_tod : false,
    three_limit_is_all : false,
    three_limit_is_close : false,


    two_up_limit_number : "",
    two_up_limit_buy : "",
    two_up_limit_percent : "",
    two_up_limit_flip : false,
    two_up_limit_tod : false,
    two_up_limit_is_all : false,
    two_up_limit_is_close : false,


    two_down_limit_number : "",
    two_down_limit_buy : "",
    two_down_limit_percent : "",
    two_down_limit_flip : false,
    two_down_limit_tod : false,
    two_down_limit_is_all : false,
    two_down_limit_is_close : false,


    two_tod_limit_number : "",
    two_tod_limit_buy : "",
    two_tod_limit_percent : "",
    two_tod_limit_flip : false,
    two_tod_limit_tod : false,
    two_tod_limit_is_all : false,
    two_tod_limit_is_close : false,


    four_tod_limit_number : "",
    four_tod_limit_buy : "",
    four_tod_limit_percent : "",
    four_tod_limit_flip : false,
    four_tod_limit_tod : false,
    four_tod_limit_is_all : false,
    four_tod_limit_is_close : false,

    five_tod_limit_number : "",
    five_tod_limit_buy : "",
    five_tod_limit_percent : "",
    five_tod_limit_flip : false,
    five_tod_limit_tod : false,
    five_tod_limit_is_all : false,
    five_tod_limit_is_close : false,


    number_up_limit_number : "",
    number_up_limit_buy : "",
    number_up_limit_percent : "",
    number_up_limit_flip : false,
    number_up_limit_tod : false,
    number_up_limit_is_all : false,
    number_up_limit_is_close : false,


    number_down_limit_number : "",
    number_down_limit_buy : "",
    number_down_limit_percent : "",
    number_down_limit_flip : false,
    number_down_limit_tod : false,
    number_down_limit_is_all : false,
    number_down_limit_is_close : false,


    number_pick_up_limit_number : "",
    number_pick_up_limit_buy : "",
    number_pick_up_limit_percent : "",
    number_pick_up_limit_flip : false,
    number_pick_up_limit_tod : false,
    number_pick_up_limit_is_all : false,
    number_pick_up_limit_is_close : false,

    number_pick_down_limit_number : "",
    number_pick_down_limit_buy : "",
    number_pick_down_limit_percent : "",
    number_pick_down_limit_flip : false,
    number_pick_down_limit_tod : false,
    number_pick_down_limit_is_all : false,
    number_pick_down_limit_is_close : false,


    tree_summary: 0,
    tree_tod_summary: 0,
    two_summary: 0,
    two_down_summary: 0,
    two_tod_summary: 0,
    four_tod_summary: 0,
    five_tod_summary: 0,
    number_up_summary: 0,
    number_down_summary: 0,
    number_pickup_summary: 0,
    number_pickdown_summary: 0,


    tree_up_data: [],
    tree_up_data_group: [],
    two_up_data: [],
    two_up_data_group: [],
    two_down_data: [],
    two_down_data_group: [],
    two_tod_data: [],
    two_tod_data_group: [],
    four_tod_data: [],
    four_tod_data_group: [],
    five_tod_data: [],
    five_tod_data_group: [],
    number_up_data: [],
    number_up_data_group: [],
    number_down_data: [],
    number_down_data_group: [],
    number_pick_up_data: [],
    number_pick_up_data_group: [],
    number_pick_down_data: [],
    number_pick_down_data_group: [],
    three_up_limit_data: [],
    two_up_limit_data: [],
    two_down_limit_data: [],
    two_tod_limit_data: [],
    four_tod_limit_data: [],
    five_tod_limit_data: [],
    number_up_limit_data: [],
    number_down_limit_data: [],
    number_pick_up_limit_data: [],
    number_pick_down_limit_data: [],


    lotto: [],
    users: {},
    employees: {},
    employee: {},
    machine_id: 0,
    orders: 0,
    title: "",
    employee_id: "",
    sum_percent:0,
    sum_total:0,
    limit_page:30,
    page:0,

    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลแผนก',
        disabled: true,
        href: '/Department',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปชิ้นงาน',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'title'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],
    is_loading:false,
    remaining:{},
    current_data:{},
    current_data_all:{},
    delete_select:{},
    number_close:[],
    is_close:false,
  }),

  computed: {
    DeleteSelectAll: {
      get: function () {
        return this.current_data? this.delete_select.length == this.current_data.length : false;
      },
      set: function (value) {
        console.log(this.current_data)
        var selected = [];

        if (value) {
          this.current_data.forEach(function (result) {
            //console.log(index)
            selected.push(result.id);
          });
        }

        this.delete_select = selected;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

  },
  watch: {},

  async created() {
    this.CheckLogin()
    await this.getLotto()
    await this.getLottoNumber()
    await this.GetEmployeeData()
  },

  methods: {
    RemoveSelect(){

      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteSelect()
        }
      })

    },

    Checked(){

      this.delete_select = [];

      console.log("Checked")
      console.log(this.tree_up)
      console.log("Checked")


      if(this.tree_up == true ){

        this.current_data = this.tree_up_data_group[this.page];
        this.current_data_all = this.tree_up_data;
      }

      if(this.two_up == true ){
        this.current_data = this.two_up_data_group[this.page];
        this.current_data_all = this.two_up_data;
      }

      if(this.two_down == true ){
        this.current_data = this.two_down_data_group[this.page];
        this.current_data_all = this.two_down_data;
      }

      if(this.two_tod == true ){
        this.current_data = this.two_tod_data_group[this.page];
        this.current_data_all = this.two_tod_data;
      }

      if(this.four_tod == true ){
        this.current_data = this.four_tod_data_group[this.page];
        this.current_data_all = this.four_tod_data;
      }

      if(this.five_tod == true ){
        this.current_data = this.five_tod_data_group[this.page];
        this.current_data_all = this.five_tod_data;
      }

      if(this.number_up == true ){
        this.current_data = this.number_up_data_group[this.page];
        this.current_data_all = this.number_up_data;
      }

      if(this.number_down == true ){
        this.current_data = this.number_down_data_group[this.page];
        this.current_data_all = this.number_down_data;
      }

      if(this.number_pick_up == true ){
        this.current_data = this.number_pick_up_data_group[this.page];
        this.current_data_all = this.number_pick_up_data;
      }

      if(this.number_pick_down == true ){
        this.current_data = this.number_pick_down_data_group[this.page];
        this.current_data_all = this.number_pick_down_data;
      }


      console.log(this.current_data)
      console.log(this.current_data_all)



    },
    async getLottoNumberSelect(){
      await this.getLottoNumber()

    },
    CheckLenght(ip,length) {
      const value = this.$refs;
      console.log(value, ip,length)
      if (String(value).length <= length) {
        ip = value
      }
      this.$forceUpdate()
    },
    CountDown(){

      setInterval(this.SetDateTime,1000)


    },
    GroupData(){

      Array.prototype.chunk = function(size) {
        let result = [];

        while(this.length) {
          result.push(this.splice(0, size));
        }

        return result;
      }

      //alert(this.limit_page)

      console.log("============GroupData============")




      const tree_up_data = this.tree_up_data;
      const two_up_data = this.two_up_data;
      const two_down_data = this.two_down_data;
      const two_tod_data = this.two_tod_data;
      const four_tod_data = this.four_tod_data;
      const five_tod_data = this.five_tod_data;
      const number_up_data = this.number_up_data;
      const number_down_data = this.number_down_data;
      const number_pick_up_data = this.number_pick_up_data;
      const number_pick_down_data = this.number_pick_down_data;


      console.log(tree_up_data)
      console.log(two_up_data)
      console.log(two_down_data)
      console.log(two_tod_data)
      console.log(four_tod_data)
      console.log(five_tod_data)
      console.log(number_up_data)
      console.log(number_down_data)
      console.log(number_pick_up_data)
      console.log(number_pick_down_data)

      this.tree_up_data_group = tree_up_data.chunk(this.limit_page);
      this.two_up_data_group = two_up_data.chunk(this.limit_page);
      this.two_down_data_group = two_down_data.chunk(this.limit_page);
      this.two_tod_data_group = two_tod_data.chunk(this.limit_page);
      this.four_tod_data_group = four_tod_data.chunk(this.limit_page);
      this.five_tod_data_group = five_tod_data.chunk(this.limit_page);
      this.number_up_data_group = number_up_data.chunk(this.limit_page);
      this.number_down_data_group = number_down_data.chunk(this.limit_page);
      this.number_pick_up_data_group = number_pick_up_data.chunk(this.limit_page);
      this.number_pick_down_data_group = number_pick_down_data.chunk(this.limit_page);


      this.page = 0;


      console.log("============GroupData============")
      this.Checked()
    },

    SetDateTime(){

      let date_end = this.lotto.date_end;
      let time_end = this.lotto.time_end;

      let remaining = moment.duration(Date.parse(date_end+" "+time_end) - new Date());

      this.day_ = remaining.days();
      this.hour = remaining.hours();
      this.minute = remaining.minutes();
      this.secound = remaining.seconds();
     // console.log("day"+remaining.days())
    },
    ResetData() {

      this.is_loading = false;

      this.tree_up_group = false
      this.tree_up_number = ""
      this.tree_up_price = ""
      this.tree_up_price_divide = ""


      this.two_up_number = ""
      this.two_up_price = ""
      this.two_up_price_divide = ""


      this.two_down_number = ""
      this.two_down_price = ""
      this.two_down_price_divide = ""

      this.two_tod_number = ""
      this.two_tod_price = ""
      this.two_tod_price_divide = ""


      this.four_tod_number = ""
      this.four_tod_price = ""
      this.four_tod_price_divide = ""


      this.five_tod_number = ""
      this.five_tod_price = ""
      this.five_tod_price_divide = ""


      this.number_up_number = ""
      this.number_up_price = ""
      this.number_up_price_divide = ""


      this.number_down_number = ""
      this.number_down_price = ""
      this.number_down_price_divide = ""


      this.number_pick_up_number = ""
      this.number_pick_up_hundred = ""
      this.number_pick_up_tens = ""
      this.number_pick_up_unit = ""


      this.number_pick_down_number = ""
      this.number_pick_down_tens = ""
      this.number_pick_down_unit = ""



    },
    ResetBtn() {
      this.page = 0;
      this.tree_up = false;
      this.two_up = false;
      this.two_down = false;
      this.two_tod = false;
      this.four_tod = false;
      this.five_tod = false;
      this.number_up = false;
      this.number_down = false;
      this.number_pick_up = false;
      this.number_pick_down = false;

    },
    CheckThreeUpLimit(){
      if(this.three_limit_number.length === 3 && this.three_limit_buy !== ''  && this.three_limit_percent !== ''){
        return false;
      }else{
        return true;
      }

    },
    CheckTwoUpLimit(){
      if(this.two_up_limit_number.length === 2 && this.two_up_limit_buy !== ''  && this.two_up_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckTwoDownLimit(){
      if(this.two_down_limit_number.length === 2 && this.two_down_limit_buy !== ''  && this.two_down_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckTwoTodLimit(){
      if(this.two_tod_limit_number.length === 2 && this.two_tod_limit_buy !== ''  && this.two_tod_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckFourTodLimit(){
      if(this.four_tod_limit_number.length === 4 && this.four_tod_limit_buy !== ''  && this.four_tod_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckFiveTodLimit(){
      if(this.five_tod_limit_number.length === 5 && this.five_tod_limit_buy !== ''  && this.five_tod_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckNumberUpLimit(){
      if(this.number_up_limit_number.length === 1 && this.number_up_limit_buy !== ''  && this.number_up_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckNumberDownLimit(){
      if(this.number_down_limit_number.length === 1 && this.number_down_limit_buy !== ''  && this.number_down_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckNumberPickUpLimit(){
      if(this.number_pick_up_limit_number.length === 1 && this.number_pick_up_limit_buy !== ''  && this.number_pick_up_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckNumberPickDownLimit(){
      if(this.number_pick_down_limit_number.length === 1 && this.number_pick_down_limit_buy !== ''  && this.number_pick_down_limit_percent !== ''){
        return false;
      }else{
        return true;
      }
    },
    CheckThreeUp() {
      if (this.tree_up_number.length === 3 && this.tree_up_number > -1 && (this.tree_up_price > 0 || this.tree_up_price_divide)) {
        return false;
      } else {
        return true;
      }
    },
    CheckThreeUpGroup() {
      if ((this.tree_up_number_group.length === 3 && this.tree_up_number_group > -1 || this.tree_up_number_group.length === 4 && this.tree_up_number_group > -1  ) && (this.tree_up_price_group > 0)) {
          return false
      } else {
        return true;
      }
    },
    CheckTwoUp() {

      if (this.two_up_number.length === 2 && this.two_up_price > 0  && this.two_up_number > -1  ) {
        return false
      } else {
        return true;
      }

    },
    CheckTwoDown() {

      if (this.two_down_number.length === 2 && this.two_down_price > 0  && this.two_down_number > -1  ) {
        return false
      } else {
        return true;
      }

    },
    CheckTwoTod() {

      if (this.two_tod_number.length === 2 && this.two_tod_price > 0  && this.two_tod_number > -1  ) {
        return false
      } else {
        return true;
      }

    },
    CheckFourTod() {

      if (this.four_tod_number.length === 4 && this.four_tod_price > 0 && this.four_tod_number > -1 ) {
        return false
      } else {
        return true;
      }

    },
    CheckFiveTod() {

      if (this.five_tod_number.length === 5 && this.five_tod_price > 0  && this.five_tod_number > -1  ) {
        return false
      } else {
        return true;
      }

    },
    CheckNumberUp() {

      if (this.number_up_number.length === 1 && this.number_up_price > 0  && this.number_up_number > -1 ) {
        return false
      } else {
        return true;
      }

    },
    CheckNumberDown() {

      if (this.number_down_number.length === 1 && this.number_down_price > 0  && this.number_down_number > -1 ) {
        return false
      } else {
        return true;
      }

    },
    CheckNumberPickUp() {

      if (this.number_pick_up_number.length === 1 && this.number_pick_up_number > -1 &&   (this.number_pick_up_hundred > 0 || this.number_pick_up_tens > 0 || this.number_pick_up_unit > 0)) {
        return false
      } else {
        return true;
      }

    },
    CheckNumberPickDown() {

      if (this.number_pick_down_number.length === 1 && this.number_pick_down_number > -1 && (this.number_pick_down_tens > 0 || this.number_pick_down_unit > 0)) {
        return false
      } else {
        return true;
      }

    },
    async AddTreeUp() {



      let Swal = this.$swal

      await this.getLottoNumber();

      this.is_loading = true;

      if (this.tree_up_group === true) {

        if (this.tree_up_number.length === 3) {

          let number_array = Array.from(String(this.tree_up_number), Number)
          let number_result = number_suffle_three(number_array);
          console.log(number_result)


          console.log(number_result)



          console.log(number_result)

          let formData = new FormData();

          formData.append("numbers", number_result);
          formData.append("price", this.tree_up_price);
          formData.append("price_divide", this.tree_up_price_divide);
          formData.append("type", "tree_up");
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("employee_id", this.employee_id);

          await PostLottoNumber(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });



        }

        if (this.tree_up_number.length === 4) {
          let number_array = Array.from(String(this.tree_up_number.substring(-1)), Number)
          let number_result = number_suffle_three(number_array);


          let formData = new FormData();

          formData.append("numbers", number_result);
          formData.append("price", this.tree_up_price);
          formData.append("price_divide", this.tree_up_price_divide);
          formData.append("type", "tree_up");
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("employee_id", this.employee_id);

          await PostLottoNumber(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });


          number_array = Array.from(String(this.tree_up_number.substring(1)), Number)
          number_result = number_suffle_three(number_array);


        }

      } else {

        if (this.tree_up_price_divide > 0 && this.tree_up_price_divide !== "") {


          let number_array = Array.from(String(this.tree_up_number), Number)
          let number_result = number_suffle_three(number_array);
          number_result.shift()

          let formData = new FormData();

          formData.append("numbers", this.tree_up_number);
          if(this.tree_up_price){
          formData.append("price", this.tree_up_price);
          }
          formData.append("price_divide", this.tree_up_price_divide);
          formData.append("type", "tree_up");
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("employee_id", this.employee_id);

          let is_close = false;
          const find = this.three_up_limit_data.find(result => result.numbers === this.tree_up_number);

          console.log("===================CHECKLIMIT===================")
          console.log(find)
          console.log("===================CHECKLIMIT===================")

          if(find !== undefined){

            if(find.is_close == "true"){
              is_close = true;
            }
            formData.append("is_limit","true");
          }else{

            formData.append("is_limit","false");

          }

          if(!is_close){
            await PostLottoNumber(formData, (response) => {
              console.log(response)
              const {status, data, statusText} = ResponseData(response)

              switch (status) {
                case 200 :
                  console.log(data)
                  this.tree_up_data = data;
                  break;


                case 422 :

                  for (const [key, value] of Object.entries(data)) {
                    console.log(`${key}: ${value}`);

                    this.$toast.open({
                      message: `${value}`,
                      type: "warning",
                      duration: 10000,
                      dismissible: true
                    })

                  }

                  break;


                default:
                  Swal({
                    icon: "error",
                    title: statusText,
                    text: data.message,
                  })
                  break;
              }

            });
          }else{



            this.number_close.push(find.numbers)
            this.is_close = true;

          }



        } else {





          let formData = new FormData();

          formData.append("numbers", this.tree_up_number);
          if(this.tree_up_price){
          formData.append("price", this.tree_up_price);
          }
          formData.append("price_divide", this.tree_up_price_divide);
          formData.append("type", "tree_up");
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("employee_id", this.employee_id);

          let number_limit = [];
          let is_close = false;
          const find = this.three_up_limit_data.find(result => result.numbers === this.tree_up_number);
          if(find){

            if(find.is_close == "true"){
              is_close = true;
            }

            number_limit.push(this.tree_up_number)

            formData.append("is_limit","true");

          }else{
            formData.append("is_limit","false");
          }

            formData.append("number_limit",number_limit);
          if(!is_close){
            await PostLottoNumber(formData, (response) => {
              console.log(response)
              const {status, data, statusText} = ResponseData(response)

              switch (status) {
                case 200 :
                  console.log(data)
                  this.tree_up_data = data;
                  break;


                case 422 :

                  for (const [key, value] of Object.entries(data)) {
                    console.log(`${key}: ${value}`);

                    this.$toast.open({
                      message: `${value}`,
                      type: "warning",
                      duration: 10000,
                      dismissible: true
                    })

                  }

                  break;


                default:
                  Swal({
                    icon: "error",
                    title: statusText,
                    text: data.message,
                  })
                  break;
              }

            });
          }else{

            this.$toast.open({
              message: "ปิดรับเลข"+find.numbers,
              type: "warning",
              duration: 10000,
              dismissible: true
            })

          }


        }
      }



      this.$refs["tree_ip_1"].focus();
      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTreeGroup() {
      let Swal = this.$swal

      this.is_loading = true;

      //alert(this.tree_up_number_group.length)

        if (this.tree_up_number_group.length === 3) {

          //let number_array = Array.from(String(this.tree_up_number_group), Number)
          let number_result = number_suffle_three(this.tree_up_number_group);
          console.log(number_result)

          const three_up_limit_data = this.three_up_limit_data;
          //let toast = this.$toast;

          let number_limit = [];
          let number_new = [];
          let number_close = [];

          number_result.forEach(function (results,index){

            console.log(results)
            const find = three_up_limit_data.find(result => result.numbers === results);

            if(find !== undefined){

              if(find.is_close == "true"){


                number_close.push(find.numbers)

                number_result.splice(index, 1);
              }
            }else {
              number_limit.push(number_result[index])
              number_new.push(number_result[index])
            }
          });


          if(number_close.length !== 0){
            this.number_close = number_close;
            this.is_close = true;
          }

          console.log(number_new.length)

          if(number_new.length === 0){

            return await this.getLottoNumber()
          }



          let formData = new FormData();

          formData.append("numbers", number_new);
          formData.append("price", this.tree_up_price_group);
          formData.append("type", "tree_up");
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("employee_id", this.employee_id);
          formData.append("number_limit", number_limit);

          await PostLottoNumber(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });


        }

        if (this.tree_up_number_group.length === 4) {
          let number_array = Array.from(String(this.tree_up_number_group.substring(-1)), Number)
          let number_array1 = Array.from(String(this.tree_up_number_group.substring(1)), Number)
          let number_result = number_suffle_three(number_array);
          let number_result1 = number_suffle_three(number_array1);
          let number_results = number_result.concat(number_result1);

          let uniqueChars = [...new Set(number_results)];

          console.log("================GROUP====================")
          console.log(uniqueChars)
          console.log(number_results)
          console.log("================GROUP====================")

          number_results = uniqueChars;

          //return;

          const three_up_limit_data = this.three_up_limit_data;
          //let toast = this.$toast;

          let number_limit = [];
          let number_new = [];
          let number_close = [];
          number_results.forEach(function (results,index){

            console.log(results)
            const find = three_up_limit_data.find(result => result.numbers === results);

            if(find !== undefined){

              if(find.is_close == "true"){



                number_close.push(find.numbers)

                number_results.splice(index, 1);
              }


            }else {
              number_limit.push(number_results[index])
              number_new.push(number_results[index])

            }
          });

          if(number_close.length !== 0){
            this.number_close = number_close;
            this.is_close = true;
          }



          console.log(number_new.length)


          if(number_new.length === 0){

            return await this.getLottoNumber()
          }


          let formData = new FormData();

          formData.append("numbers", number_results);
          formData.append("price", this.tree_up_price_group);
          formData.append("type", "tree_up");
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("employee_id", this.employee_id);
          formData.append("number_limit", number_limit);

          await PostLottoNumber(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });


          number_array = Array.from(String(this.tree_up_number.substring(1)), Number)
          number_result = number_suffle_three(number_array);


        }

      this.tree_up_number_group = "";
      this.tree_up_price_group = "";
      this.NextInput('tree_ip_1g');

      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTreeUpLimit(is_close) {
      let Swal = this.$swal

      this.is_loading = true;

      if (this.three_limit_flip === true) {

        if (this.three_limit_number.length === 3) {

          let number_array = Array.from(String(this.three_limit_number), Number)
          let number_result = number_suffle_three(number_array);
          console.log(number_result)


          let formData = new FormData();

          formData.append("numbers", number_result);
          formData.append("buy", this.three_limit_buy);
          formData.append("percents", this.three_limit_percent);
          formData.append("is_close", is_close);

          if(this.three_limit_tod){
          formData.append("type", "tree_tod");
          }else{
          formData.append("type", "tree_up");
          }
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          if(this.employee_id){
          formData.append("employee_id", this.employee_id);
          }

          await PostLottoNumberLimit(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });

          number_result.forEach(async (result) => {

            let formData = new FormData();

            formData.append("numbers", result);
            formData.append("price", this.tree_up_price);
            formData.append("price_divide", this.tree_up_price_divide);
            formData.append("type", "tree_up");

            await PostLottoNumber(this.lotto.merchant_id, formData, (response) => {
              console.log(response)
              const {status, data, statusText} = ResponseData(response)

              switch (status) {
                case 200 :
                  console.log(data)

                  break;


                default:
                  Swal({
                    icon: "error",
                    title: statusText,
                    text: data.message,
                  })
                  break;
              }

            });


          })

        }

      } else {


          let formData = new FormData();

          formData.append("numbers", this.three_limit_number);
          formData.append("buy", this.three_limit_buy);
          formData.append("percents", this.three_limit_percent);
          formData.append("is_close", is_close);
          if(this.three_limit_tod){
            formData.append("type", "tree_tod");
          }else{
            formData.append("type", "tree_up");
          }
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          if(this.employee_id){
            formData.append("employee_id", this.employee_id);
          }


          await PostLottoNumberLimit(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)
                //this.tree_up_data = data;
                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });


      }



      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTwoUp() {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.two_up_number);
      formData.append("price", this.two_up_price);
      formData.append("type", "two_up");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.two_up_limit_data.find(result => result.numbers === this.two_up_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.tree_up_number)

        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)

          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              this.tree_up_data = data;
              break;


            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else {
        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })
      }



      if (this.two_up_price_divide > 0) {
        let number_array = Array.from(String(this.two_up_number), Number)


        let formData = new FormData();

        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("price", this.two_up_price_divide);
        formData.append("type", "two_up");
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("employee_id", this.employee_id);

        let number_limit = [];
        let is_close = false;
        const find = this.two_up_limit_data.find(result => result.numbers == number_array[1] + "" + number_array[0]);

        if(find !== undefined){

          if(find.is_close == "true"){
            is_close = true;
          }

          number_limit.push(this.tree_up_number)
          formData.append("is_limit","true");

        }else{
          formData.append("is_limit","false");
        }

        if(!is_close){
          await PostLottoNumber(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)
                //this.tree_up_data = data;
                break;

              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });
        }else {
          this.$toast.open({
            message: "ปิดรับเลข"+find.numbers,
            type: "warning",
            duration: 10000,
            dismissible: true
          })
        }



      }
      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTwoUpLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.two_up_limit_number);
      formData.append("buy", this.two_up_limit_buy);
      formData.append("percents", this.two_up_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "two_up");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      if (this.two_up_limit_flip === true) {
        let number_array = Array.from(String(this.two_up_limit_number), Number)


        let formData = new FormData();

        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("is_close", is_close);
        formData.append("type", "two_up");
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        if(this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }

        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }
      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTwoDown() {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.two_down_number);
      formData.append("price", this.two_down_price);
      formData.append("type", "two_down");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.two_down_limit_data.find(result => result.numbers === this.two_down_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.tree_up_number)
        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }



      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)

          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              this.tree_up_data = data;
              break;


            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{

        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })

      }



      if (this.two_down_price_divide > 0) {
        let number_array = Array.from(String(this.two_down_number), Number)


        let formData = new FormData();

        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("price", this.two_down_price_divide);
        formData.append("type", "two_down");
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("employee_id", this.employee_id);

        let number_limit = [];
        let is_close = false;
        const find = this.two_down_limit_data.find(result => result.numbers === this.two_down_number);

        if(find !== undefined){

          if(find.is_close == "true"){
            is_close = true;
          }

          number_limit.push(this.tree_up_number)
          formData.append("is_limit","true");

        }else{
          formData.append("is_limit","false");
        }

        if(!is_close){
          await PostLottoNumber(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)
                //this.tree_up_data = data;
                break;

              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });
        }else{
          this.$toast.open({
            message: "ปิดรับเลข"+find.numbers,
            type: "warning",
            duration: 10000,
            dismissible: true
          })
        }



      }
      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTwoDownLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.two_down_limit_number);
      formData.append("buy", this.two_down_limit_buy);
      formData.append("percents", this.two_down_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "two_down");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      if (this.two_up_limit_flip === true) {
        let number_array = Array.from(String(this.two_down_limit_number), Number)


        let formData = new FormData();

        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("is_close", is_close);
        formData.append("type", "two_down");
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        if(this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }

        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }
      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTwoTod() {
      this.is_loading = true;
      let Swal = this.$swal
      let formData = new FormData();

      formData.append("numbers", this.two_tod_number);
      formData.append("price", this.two_tod_price);
      formData.append("type", "two_tod");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.two_tod_limit_data.find(result => result.numbers === this.two_down_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.tree_up_number)
        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{
        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })
      }


      this.ResetData();
      await this.getLottoNumber();
    },
    async AddTwoTodLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.two_tod_limit_number);
      formData.append("buy", this.two_tod_limit_buy);
      formData.append("percents", this.two_tod_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "two_tod");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      if (this.two_up_limit_flip === true) {
        let number_array = Array.from(String(this.two_down_limit_number), Number)


        let formData = new FormData();

        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("is_close", is_close);
        formData.append("type", "two_tod");
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        if(this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }

        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }
      this.ResetData();
      await this.getLottoNumber();
    },
    async AddFourTod() {
      this.is_loading = true;

      let Swal = this.$swal
      let formData = new FormData();

      formData.append("numbers", this.four_tod_number);
      formData.append("price", this.four_tod_price);
      formData.append("type", "four_tod");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.four_tod_limit_data.find(result => result.numbers === this.four_tod_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.four_tod_number)
        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{
        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })
      }


      await this.getLottoNumber();
      this.ResetData();
    },
    async AddFourTodLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.four_tod_limit_number);
      formData.append("buy", this.four_tod_limit_buy);
      formData.append("percents", this.four_tod_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "four_tod");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumber();
    },
    async AddFiveTod() {
      this.is_loading = true;
      let Swal = this.$swal
      let formData = new FormData();

      formData.append("numbers", this.five_tod_number);
      formData.append("price", this.five_tod_price);
      formData.append("type", "five_tod");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.five_tod_limit_data.find(result => result.numbers === this.five_tod_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.tree_up_number)

        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close) {
        await PostLottoNumber(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{
        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })
      }

      await this.getLottoNumber();
      this.ResetData();
    },
    async AddFiveTodLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.five_tod_limit_number);
      formData.append("buy", this.five_tod_limit_buy);
      formData.append("percents", this.five_tod_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "five_tod");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumber();
    },
    async AddNumberUp() {

      this.is_loading = true;
      let Swal = this.$swal
      let formData = new FormData();

      formData.append("numbers", this.number_up_number);
      formData.append("price", this.number_up_price);
      formData.append("type", "number_up");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.number_up_limit_data.find(result => result.numbers === this.number_up_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.tree_up_number)

        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{

        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })

      }


      await this.getLottoNumber();


      /* let data = this.number_up_data;

       data.push({
         "number":this.number_up_number,
         "price":this.number_up_price,
       })

       */


      this.ResetData();
    },
    async AddNumberUpLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_up_limit_number);
      formData.append("buy", this.number_up_limit_buy);
      formData.append("percents", this.number_up_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "number_up");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumber();
    },
    async AddNumberDown() {

      this.is_loading = true;
      let Swal = this.$swal
      let formData = new FormData();

      formData.append("numbers", this.number_down_number);
      formData.append("price", this.number_down_price);
      formData.append("type", "number_down");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.number_down_limit_data.find(result => result.numbers === this.number_down_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.number_down_number)

        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{

        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })

      }


      await this.getLottoNumber();
      this.ResetData();
    },
    async AddNumberDownLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_down_limit_number);
      formData.append("buy", this.number_down_limit_buy);
      formData.append("percents", this.number_down_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "number_down");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumber();
    },
    async AddNumberPickUp() {
      this.is_loading = true;
      let Swal = this.$swal
      let formData = new FormData();

      formData.append("numbers", this.number_pick_up_number);
      if (this.number_pick_up_hundred) {
        formData.append("price_hundred", this.number_pick_up_hundred);
      }
      if (this.number_pick_up_tens) {
        formData.append("price_ten", this.number_pick_up_tens);
      }
      if (this.number_pick_up_unit) {
        formData.append("price_unit", this.number_pick_up_unit);
      }
      formData.append("type", "number_pick_up");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.number_down_limit_data.find(result => result.numbers === this.number_down_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.number_down_number)

        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{

        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })

      }


      await this.getLottoNumber();
      this.ResetData();

    },
    async AddNumberPickUpLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_pick_up_limit_number);
      formData.append("buy", this.number_pick_up_limit_buy);
      formData.append("percents", this.number_pick_up_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "number_pick_up");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumber();
    },
    async AddNumberPickDown() {
      this.is_loading = true;
      /*let data = this.number_pick_down_data;

      data.push({
        "number":this.number_pick_down_number,
        "price_ten":this.number_pick_down_tens,
        "price_unit":this.number_pick_down_unit,
      })*/


      let Swal = this.$swal
      let formData = new FormData();

      formData.append("numbers", this.number_pick_down_number);
      if (this.number_pick_down_tens) {
        formData.append("price_ten", this.number_pick_down_tens);
      }
      if (this.number_pick_down_unit) {
        formData.append("price_unit", this.number_pick_down_unit);
      }
      formData.append("type", "number_pick_down");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("employee_id", this.employee_id);

      let number_limit = [];
      let is_close = false;
      const find = this.two_up_limit_data.find(result => result.numbers == this.number_pick_down_number);

      if(find !== undefined){

        if(find.is_close == "true"){
          is_close = true;
        }

        number_limit.push(this.number_pick_down_number)
        formData.append("is_limit","true");

      }else{
        formData.append("is_limit","false");
      }

      if(!is_close){
        await PostLottoNumber(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });
      }else{
        this.$toast.open({
          message: "ปิดรับเลข"+find.numbers,
          type: "warning",
          duration: 10000,
          dismissible: true
        })
      }


      await this.getLottoNumber();

      this.ResetData();
    },
    async AddNumberPickDownLimit(is_close) {
      this.is_loading = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_pick_down_limit_number);
      formData.append("buy", this.number_pick_down_limit_buy);
      formData.append("percents", this.number_pick_down_limit_percent);
      formData.append("is_close", is_close);
      formData.append("type", "number_pick_down");
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      if(this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumber();
    },
    async getLotto() {
      let Swal = this.$swal;
      this.is_loading = true;
      await GetLotto(this.users.merchant_id, (response) => {

        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            this.lotto = data;
            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });
    },
    async GetEmployeeOneData(){

      let Swal = this.$swal;


      await GetOneEmployee(this.employee_id, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)


        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;
            this.employees = data;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    async getLottoNumber() {
      let Swal = this.$swal;
      this.is_loading = true;

      const params = {}

      params.merchant_id = this.lotto.merchant_id
      params.lottery_id = this.lotto.id
      if (this.employee_id !== "") {
        params.employee_id = this.employee_id
      }


      await GetLottoNumber(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;

            this.tree_up_data = data.three_up;
            this.two_up_data = data.two_up;
            this.two_down_data = data.two_down;
            this.two_tod_data = data.two_tod;
            this.four_tod_data = data.four_tod;
            this.five_tod_data = data.five_tod;
            this.number_up_data = data.number_up;
            this.number_down_data = data.number_down;
            this.number_pick_up_data = data.number_pick_up;
            this.number_pick_down_data = data.number_pick_down;
            this.three_up_limit_data = data.three_up_limit;
            this.two_up_limit_data = data.two_up_limit;
            this.two_down_limit_data = data.two_down_limit;
            this.two_tod_limit_data = data.two_tod_limit;
            this.four_tod_limit_data = data.four_tod_limit;
            this.five_tod_limit_data = data.five_tod_limit;
            this.number_up_limit_data = data.number_up_limit;
            this.number_down_limit_data = data.number_down_limit;
            this.number_pick_up_limit_data = data.number_pick_up_limit;
            this.number_pick_down_limit_data = data.number_pick_down_limit;

            this.tree_summary = 0;
            this.tree_tod_summary = 0;
            this.two_summary = 0;
            this.two_down_summary = 0;
            this.two_tod_summary = 0;
            this.four_tod_summary = 0;
            this.five_tod_summary = 0;
            this.number_up_summary = 0;
            this.number_down_summary = 0;
            this.number_pickup_summary = 0;
            this.number_pickdown_summary = 0;

            this.tree_up_data.forEach((result) => {
              this.tree_summary += result.price ? result.price : 0;
              this.tree_tod_summary += result.price_divide ? result.price_divide : 0;
            });

            this.two_up_data.forEach((result) => {
              this.two_summary += result.price ? result.price : 0;
            });

            this.two_down_data.forEach((result) => {
              this.two_down_summary += result.price ? result.price : 0;
            });

            this.two_tod_data.forEach((result) => {
              this.two_tod_summary += result.price ? result.price : 0;
            });

            this.four_tod_data.forEach((result) => {
              this.four_tod_summary += result.price ? result.price : 0;
            });

            this.five_tod_data.forEach((result) => {
              this.five_tod_summary += result.price ? result.price : 0;
            });

            this.number_up_data.forEach((result) => {
              this.number_up_summary += result.price ? result.price : 0;
            });

            this.number_down_data.forEach((result) => {
              this.number_down_summary += result.price ? result.price : 0;
            });

            this.number_pick_up_data.forEach((result) => {
              this.number_pickup_summary += result.price_hundred ? result.price_hundred : 0;
              this.number_pickup_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickup_summary += result.price_unit ? result.price_unit : 0;
            });

            this.number_pick_down_data.forEach((result) => {
              this.number_pickdown_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickdown_summary += result.price_unit ? result.price_unit : 0;
            });

            this.GroupData();


            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });

    },
    async GetEmployeeData(){

      let Swal = this.$swal;

      let params = {};

      params.merchant_id = this.lotto.merchant_id


      await GetEmployee(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        let employee = [];

        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            employee.push({
              "label":"ทั้งหมด",
              "id":0,
            })


            data.forEach(function (result){
              console.log(result)
              employee.push({
                "label":result.name,
                "id":result.id,
              })
            });

            this.employee = employee;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    CheckLogin() {

      const UserData = localStorage.getItem('user');
      if (!UserData && this.$router.currentRoute.path !== "/login") {
        window.location = "/login"
      } else {
        this.users = JSON.parse(UserData);
        if (this.users.type !== 'user') {
          this.employee_id = this.users.id;
          this.employees = this.users;
        }
        console.log(this.users)
      }
    },
    initialize() {

    },
    TwoTodLength(event) {
      const value = event.target.value
      console.log(value, this.two_tod_number)
      if (String(value).length <= 2) {
        this.two_tod_number = value
      }
      this.$forceUpdate()
    },
    TwoDownLength(event) {
      const value = event.target.value
      console.log(value, this.two_down_number)
      if (String(value).length <= 2) {
        this.two_down_number = value
      }
      this.$forceUpdate()
    },
    TwoUpLength(event) {
      const value = event.target.value
      console.log(value, this.two_up_number)
      if (String(value).length <= 2) {
        this.two_up_number = value
      }
      this.$forceUpdate()
    },
    ThreeUpLength(event) {
      const value = event.target.value
      console.log(value, this.tree_up_number)
      if (String(value).length <= 3) {
        this.tree_up_number = value
      }
      this.$forceUpdate()
    },
    ThreeUpGroupLength(event) {
      const value = event.target.value
      console.log(value, this.tree_up_number_group)
      if (String(value).length <= 4) {
        this.tree_up_number_group = value
      }
      this.$forceUpdate()
    },
    FourTodLength(event) {
      const value = event.target.value
      console.log(value, this.four_tod_number)
      if (String(value).length <= 4) {
        this.four_tod_number = value
      }
      this.$forceUpdate()
    },
    FiveTodLength(event) {
      const value = event.target.value
      console.log(value, this.five_tod_number)
      if (String(value).length <= 5) {
        this.five_tod_number = value
      }
      this.$forceUpdate()
    },
    NumberUpTodLength(event) {
      const value = event.target.value
      console.log(value, this.number_up_number)
      if (String(value).length <= 1) {
        this.number_up_number = value
      }
      this.$forceUpdate()
    },
    NumberDownTodLength(event) {
      const value = event.target.value
      console.log(value, this.number_down_number)
      if (String(value).length <= 1) {
        this.number_down_number = value
      }
      this.$forceUpdate()
    },
    NumberPickupLength(event) {
      const value = event.target.value
      console.log(value, this.number_pick_up_number)
      if (String(value).length <= 1) {
        this.number_pick_up_number = value
      }
      this.$forceUpdate()
    },
    NumberPickDownLength(event) {
      const value = event.target.value
      console.log(value, this.number_pick_down_number)
      if (String(value).length <= 1) {
        this.number_pick_down_number = value
      }
      this.$forceUpdate()
    },
    async validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        await this.AddUpdate();
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async deleteItemLimit(id,number,type){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบเฉพาะคนใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id,number,type)
        }
      })

      ;
    },
    async deleteSelect(){
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("ids",this.delete_select)

      await DeleteMultipleNumber(formData,(response)=>{
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;

            this.getLottoNumber()

            this.$toast.open({
              message: 'ลบสำเร็จ',
              type: "success",
              duration: 500,
              dismissible: true
            })


            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });

    },
    async delete(id){
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("_method","DELETE")

      await DeleteOneNumber(id,(response)=>{
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;

            this.getLottoNumber()

            this.$toast.open({
              message: 'ลบสำเร็จ',
              type: "success",
              duration: 500,
              dismissible: true
            })


            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });

    },
    NextInput(ip){
      this.$refs[""+ip+""].focus();
    }
  },

}


</script>

<style scoped>
.card_container {
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}

.card_header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
}

.card_content {
  padding: 1rem;
}

.img_thumb {
  width: 8rem;
  border-radius: 10px;
  margin: 0.2rem;
}

.form_action {
  padding-right: 5rem;
  padding-left: 5rem;
}

.image_preview_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#image_preview {
  width: 20rem;
  text-align: center;
}

.btn_sale {
  background: #e7e7e7;
  padding: 0.5rem;
  padding-inline: 2rem;
  color: #0686E8;
  font-weight: bold;
  border-radius: 10px;
  padding-right: 3rem;
  padding-left: 3rem;
  min-width: 10rem;
  font-size: 1rem;
}

.btn_sale:active {
  background: #0686E8;
  color: #FFFFFF;
}

.btn_sale.active {
  background: #0686E8;
  color: #FFFFFF;
}

.button_list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.sale_container {
  flex-direction: column;
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_cal {
  display: flex;
  gap: 1rem;
  font-size: 2rem;
  align-items: center;
  width: 100%;
  justify-content: center
}

.input-custom {
  border: 1px solid;
  max-width: 6rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  background: white;
}

.input-custom-pick {
  border: 1px solid;
  max-width: 4.8rem;
  font-size: 1.2rem;
  padding: .5rem;
  background: white;
}


.table_custom {
  margin-top: 5rem;
  border-collapse: collapse;
  width: 100%;
}

.head_title {
  font-size: 1.2rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}

.head_title_sub {
  font-size: 1.1rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}

.table_list {
  width: 100%;
  display: flex;
  justify-content: center;
}

.data_table {
  padding: 0.2rem;
  font-size: 1rem;
  text-align: center;
}

.table_custom_summary {
  margin-top: 5rem;
  border-collapse: collapse;
  width: 100%;
}

.head_title_summary {
  font-size: 1.2rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}

.head_title_sub_summary {
  font-size: 1.1rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}

.table_list_summary {
  width: 80%;
  display: flex;
  justify-content: center;
}

.data_table_summary {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

.delete_btn {
  color: red !important;
}

.btn_sale:disabled {
  filter: saturate(0);
}

.input-custom:disabled {
  filter: saturate(0);
}

.loading_image {
  height: 8rem;
  margin-bottom: -5rem;
}
.select-custom{
  border: 1px solid #000000;
  padding: 0.3rem;
  -webkit-appearance: button;
  font-size: 1rem;
}
.filter-list{
  width: 100%;
}
.delete_btn{
  background: none !important;
}

.input-custom::-webkit-outer-spin-button,
.input-custom::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-custom[type=number] {
  -moz-appearance: textfield;
}

.is_limit{
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  color: red;
  cursor: pointer;
}
.list_input{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  gap: 10px;
}
.list_left{
 justify-content: flex-end;
}
.list_right{
  justify-content: flex-start;
}
</style>


import moment from "moment";
import 'vue-toast-notification/dist/theme-sugar.css';


export function dateTime(date){
    moment.locale('th');
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
}

export function number_suffle_three(numbers){
    var l = numbers.length;
    var result = [];
    var str;

    for (let i = 0; i < l; i++) {
        for (let j = 0; j < l; j++) {
            for (let k = 0; k < l; k++) {
                if (i !== j && i !== k && j !== k) { // Ensure all three numbers are different
                    str = "" + numbers[i] + numbers[j] + numbers[k];
                    result.push(str);
                }
            }
        }
    }

    return result;
}
export function number_suffle_four(numbers){
    var l = numbers.length;
    var result = [];
    var str;

    for(let i=0;i<l;i++){
        for(let j=0;j<l;j++){
            for(let k=0;k<l;k++){
                for(let m=0;m<l;m++) {
                    if (
                        numbers[i]!=numbers[j] &&
                        numbers[i]!=numbers[k] &&
                        numbers[i]!=numbers[m] &&
                        numbers[j]!=numbers[k] &&
                        numbers[j]!=numbers[m] &&
                        numbers[k]!=numbers[m]
                    ) {
                        str = "" + numbers[i] + numbers[j] + numbers[k]+ numbers[m];
                        result.push(str);
                    }
                }
            }
        }
    }

    return result;
}
export function number_suffle_five(numbers){
    var l = numbers.length;
    var result = [];
    var str;

    for(let i=0;i<l;i++){
        for(let j=0;j<l;j++){
            for(let k=0;k<l;k++){
                for(let m=0;m<l;m++) {
                    for(let n=0;n<l;n++) {
                        if (
                            numbers[i] != numbers[j] &&
                            numbers[i] != numbers[k] &&
                            numbers[i] != numbers[m] &&
                            numbers[i] != numbers[n] &&
                            numbers[j] != numbers[k] &&
                            numbers[j] != numbers[m] &&
                            numbers[j] != numbers[n] &&
                            numbers[k] != numbers[m] &&
                            numbers[k] != numbers[n] &&
                            numbers[m] != numbers[n]
                        ) {
                            str = "" + numbers[i] + numbers[j] + numbers[k] + numbers[m] + numbers[n];
                            result.push(str);
                        }
                    }
                }
            }
        }
    }

    return result;
}
export function ResponseData(response){

    return { status:response.status,data:response.data,statusText:response.statusText }
}



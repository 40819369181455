import {API, instance} from "../const/api";



export async function StartLotto(formData,Calback){

    await instance.post(API.Lotto,formData)
        .then(function (response) {
            Calback(response)
    })
        .catch(function (response) {
            Calback(response.response)
        });


}

export async function GetLotto(merchant,Calback){

    await instance.get(API.Lotto+"/"+merchant)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function PostLottoNumber(formData,Calback){
    console.log(formData,Calback)

    await instance.post(API.LottoNumber,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });

}

export async function PostLottoNumberLimit(formData,Calback){

    await instance.post(API.LottoNumberLimit,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function GetLottoNumber(params,Calback){

    await instance.get(API.LottoNumber,{params})
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function GetLottoNumberWith(id,params,Calback){

    await instance.get(API.LottoNumberWith+"/"+id,{params})
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function GetLottoNumberProfit(params,Calback){

    await instance.get(API.LottoNumberProfit,{params})
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });


}


export async function PostEmployee(formData,Calback){

    await instance.post(API.Employee,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function UpdateEmployee(id,formData,Calback){

    await instance.post(API.Employee+"/"+id,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function UpdateEmployeePassword(id,formData,Calback){

    await instance.post(API.EmployeePassword+"/"+id,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function DeleteEmployee(formData,Calback){

    await instance.post(API.Employee,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function GetEmployee(params,Calback){

    await instance.get(API.Employee,{params})
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function GetUsers(params,Calback){

    await instance.get(API.Users,{params})
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function GetOneEmployee(id,Calback){

    await instance.get(API.Employee+"/"+id,)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}
export async function DeleteOneNumber(id,Calback){

    await instance.delete(API.LottoNumber+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function DeleteMultipleNumber(ids,Calback){

    await instance.post(API.DeleteLottoNumberMultiple,ids)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function DeleteLimitNumber(formData,Calback){

    await instance.post(API.LottoNumberLimit+"/",formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}



export async function PostCutNumber(formData,Calback){

    await instance.post(API.LottoNumberCut+"/",formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function DeleteCutNumber(id,Calback){

    await instance.delete(API.LottoNumberCut+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function PostAward(formData,Calback){

    await instance.post(API.LottoAward+"/",formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function UpdateLotto(id,formData,Calback){

    await instance.post(API.UpdateLotto+"/"+id,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

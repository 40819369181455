<template>
  <div class="login_container" >
    <div class="form_login" >
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="username"
            :rules="[v => !!v || 'ชื่อผู้ใช้ต้องไม่เป็นค่าว่าง']"
            label="ชื่อผู้ใช้งาน"
            required
        ></v-text-field>

        <v-text-field
            v-model="password"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show_password ? 'text' : 'password'"
            name="input-10-1"
            label="รหัสผ่าน"
            counter
            @click:append="show_password = !show_password"
        ></v-text-field>

        <div align="center" style="margin-top: 2rem" >
          <v-btn
              color="success"
              class="mr-4 btn_login"
              @click="validate"
          >
            Login
          </v-btn>

          <v-btn
              color="error"
              class="mr-4 btn_reset"
              @click="reset"
          >
            Reset
          </v-btn><br><br>
          <font v-if="notfound" class="notfound" >ไม่พบชื่อผู้ใช้งาน</font>
        </div>

      </v-form>



    </div>
  </div>
</template>
<script>
// @ is an alias to /src

//import $ from "jquery";

import { auth} from "../lib/controller_auth";
import {ResponseData} from "../lib/utility";

export default {
  name: 'Home',
  data: () => ({
    password: '',
    username: '',
    valid: true,
    notfound: false,
    show_password: false,
    rules: {
      required: value => !!value || 'กรอกรหัสผ่าน.',
    },
  }),

  methods:{
    validate () {
      this.notfound = false;
      if(this.$refs.form.validate()){
        this.Login();
      }
    },
    async Login(){
      this.notfound = false;

      let formData = new FormData();
      formData.append('username',this.username)
      formData.append('password',this.password)


      await auth(formData, (response) => {
        console.log(response)
        const {status, data} = ResponseData(response)
        let Users;
        switch (status) {
          case 200 :
            console.log(data)
            Users = JSON.stringify(data.data);
            localStorage.setItem('user',Users)
            window.location = "/"
            console.log(status)
            console.log(data)
            break;

          case 404 :
            this.notfound = true;
            break;

          default:


            break;
        }

      });

    },
    reset () {
      this.$refs.form.reset()
    },
    CheckLogin(){
      const UserData = localStorage.getItem('user');
      if(UserData){
        console.log("Islogin")
        window.location = "/"
      }else{
        //console.log("Nologin")
      }
    }
  },
  mounted() {
    this.CheckLogin();
  }
}
</script>
<style>
body{
  background: #48090A !important;
}
.theme--light.v-application{
  background: #48090A !important;
}
.form_login{
  width: 50rem;
  height: auto;
  padding:3rem;
  background: #ffffff;
  border-radius: 0.5rem;
}
.login_container{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #0686E8;
}
.notfound{
  font-size: 1rem;
  color: red;
}
.v-application .success{
  background-color: #09910c !important;
  border-color: #09910c !important;
}
</style>

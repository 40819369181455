<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" v-if="lotto.date_start">
      <div class="card_header">
        <v-row>
          <v-col xl="8" lg="8" md="12" sm="12" xs="12"  >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          </v-col>
          <v-col xl="4" lg="4" md="12" sm="12" xs="12"  >
          </v-col>
          <v-row>
            <img class="loading_image_main" v-if="is_loading === true" src="./../../assets/image/loading.svg">
          </v-row>
        </v-row>
      </div>


      <v-row>

          <div class="page_print" >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
            <table class="table-custom" border="1" >
              <tr>
                <th>ตัวเลข</th>
                <th>ราคา</th>
              </tr>
              <tr v-for="results in tree_up_data_group" v-bind:key="results.id"  >
                <td>บน {{ results.numbers }}</td>
                <td>{{ results.price }}</td>
              </tr>
            </table>
          </div>
      </v-row>


      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in tree_tod_data_group" v-bind:key="results.id"  >
              <td>โต๊ด {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>


      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in two_up_data_group" v-bind:key="results.id"  >
              <td>บน {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>


      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in two_down_data_group" v-bind:key="results.id"  >
              <td>ล่าง {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>



      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in two_tod_data_group" v-bind:key="results.id"  >
              <td>โต๊ด {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>



      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in four_tod_data_group" v-bind:key="results.id"  >
              <td>โต๊ด {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>


      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in five_tod_data_group" v-bind:key="results.id"  >
              <td>โต๊ด {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>


      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in number_up_data_group" v-bind:key="results.id"  >
              <td>วิ่งบน {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>



      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in number_down_data_group" v-bind:key="results.id"  >
              <td>วิ่งล่าง {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>

      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in number_pick_up_data_group" v-bind:key="results.id"  >
              <td>วิ่งบน {{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>



      <v-row>

        <div class="page_print" >
          <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          <table class="table-custom" border="1" >
            <tr class="header_print" >
              <th colspan="2" >เลข 3 ตัว</th>
            </tr>
            <tr>
              <th>ตัวเลข</th>
              <th>ราคา</th>
            </tr>
            <tr v-for="results in number_pick_down_data_group" v-bind:key="results.id"  >
              <td>{{ results.numbers }}</td>
              <td>{{ results.price }}</td>
            </tr>
          </table>
        </div>
      </v-row>



    </div>
    <div class="card_container" v-else>
      <div align="center" class="pt-5 pb-5">
        <h1>ไม่พบข้อมูลงวดกรุณาเริ่มงวดใหม่</h1>
      </div>
    </div>
  </v-breadcrumbs>
</template>

<script>
import {
  GetEmployee,
  GetLotto,
  GetLottoNumber, GetLottoNumberWith,
  GetOneEmployee,
} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from "jquery";
import {number_suffle_five, number_suffle_four, number_suffle_three, ResponseData} from "../../lib/utility";


export default {
  name: "Users",
  data: () => ({
    baseUrl: baseUrl,
    valid: true,
    edit: false,
    id: false,

    day_:"",
    hour:"",
    minute:"",
    secound:"",



    tree_up_data: [],
    tree_up_data_group: [],
    tree_tod_data: [],
    tree_tod_data_group: [],
    two_up_data: [],
    two_up_data_group: [],
    two_down_data: [],
    two_down_data_group: [],
    two_tod_data: [],
    two_tod_data_group: [],
    four_tod_data: [],
    four_tod_data_group: [],
    five_tod_data: [],
    five_tod_data_group: [],
    number_up_data: [],
    number_up_data_group: [],
    number_down_data: [],
    number_down_data_group: [],
    number_pick_up_data: [],
    number_pick_up_data_group: [],
    number_pick_down_data: [],
    number_pick_down_data_group: [],
    three_up_limit_data: [],
    two_up_limit_data: [],
    two_down_limit_data: [],
    two_tod_limit_data: [],
    four_tod_limit_data: [],
    five_tod_limit_data: [],
    number_up_limit_data: [],
    number_down_limit_data: [],
    number_pick_up_limit_data: [],
    number_pick_down_limit_data: [],
    lotto: [],
    sum_price: [],
    users: {},
    employees: {},
    employee: {},
    machine_id: 0,
    orders: 0,
    title: "",
    employee_id: "",
    sum_percent:0,
    sum_total:0,

    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลแผนก',
        disabled: true,
        href: '/Department',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปชิ้นงาน',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'title'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],
    is_loading:false,
    remaining:{},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {},

  async created() {
    this.CheckLogin()
    await this.getLotto()
    await this.getLottoNumber()
    await this.GetEmployeeData()
  },

  methods: {

    async getLotto() {
      let Swal = this.$swal;
      this.is_loading = true;
      await GetLotto(this.users.merchant_id, (response) => {

        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            this.lotto = data;
            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });
    },
    async GetEmployeeOneData(){

      let Swal = this.$swal;


      await GetOneEmployee(this.employee_id, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)


        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;
            this.employees = data;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    async getLottoNumber() {

      let Swal = this.$swal;
      this.is_loading = true;

      const params = {}

      params.merchant_id = this.lotto.merchant_id
      params.lottery_id = this.lotto.id

      if (this.employee_id !== "") {
        params.employee_id = this.employee_id
      }


      if(!this.$route.params.id){
        await GetLottoNumber(params, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)
          console.log(status)
          console.log(data)
          console.log(statusText)


          switch (status) {
            case 200 :
              console.log(data)


              console.log("===========cut_tree_up===========")
              console.log(data.cut_tree_up)

              this.tree_up_data_group = data.cut_tree_up;
              this.tree_tod_data_group = data.cut_tree_tod;
              this.two_up_data_group = data.cut_two_up;
              this.two_down_data_group = data.cut_two_down;
              this.two_tod_data_group = data.cut_two_tod;
              this.four_tod_data_group = data.cut_four_tod;
              this.five_tod_data_group = data.cut_five_tod;
              this.number_up_data_group = data.cut_number_up;
              this.number_down_data_group = data.cut_number_down;
              this.number_pick_up_data_group = data.cut_number_pick_up;
              this.number_pick_down_data_group = data.cut_number_pick_down;



              this.is_loading = false;

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              this.is_loading = false;
              break;
          }

        });
      }else{
        await GetLottoNumberWith(this.$route.params.id,params, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)
          console.log(status)
          console.log(data)
          console.log(statusText)


          switch (status) {
            case 200 :
              console.log(data)


              console.log("===========cut_tree_up===========")
              console.log(data.cut_tree_up)

              this.tree_up_data_group = data.cut_tree_up;
              this.tree_tod_data_group = data.cut_tree_tod;
              this.two_up_data_group = data.cut_two_up;
              this.two_down_data_group = data.cut_two_down;
              this.two_tod_data_group = data.cut_two_tod;
              this.four_tod_data_group = data.cut_four_tod;
              this.five_tod_data_group = data.cut_five_tod;
              this.number_up_data_group = data.cut_number_up;
              this.number_down_data_group = data.cut_number_down;
              this.number_pick_up_data_group = data.cut_number_pick_up;
              this.number_pick_down_data_group = data.cut_number_pick_down;
              this.is_loading = false;

              setTimeout(()=>{
                window.print()
              },2000)

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              this.is_loading = false;
              break;
          }

        });
      }



    },
    async GetEmployeeData(){

      let Swal = this.$swal;

      let params = {};

      params.merchant_id = this.lotto.merchant_id


      await GetEmployee(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        let employee = [];

        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            employee.push({
              "label":"ทั้งหมด",
              "id":0,
            })


            data.forEach(function (result){
              console.log(result)
              employee.push({
                "label":result.name,
                "id":result.id,
              })
            });

            this.employee = employee;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    CheckLogin() {

      const UserData = localStorage.getItem('user');
      if (!UserData && this.$router.currentRoute.path !== "/login") {
        window.location = "/login"
      } else {
        this.users = JSON.parse(UserData);
        if (this.users.type !== 'user') {
          this.employee_id = this.users.id;
          this.employees = this.users;
        }
        console.log(this.users)
      }
    },
    initialize() {

    },
    async validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        await this.AddUpdate();
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    sumPrice(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price;
      });

      return total;
    },
    sumPriceDivide(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_divide;
      });

      return total;
    },
    sumPriceHundred(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_hundred;
      });

      return total;
    },
    sumPriceTen(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_ten;
      });

      return total;
    },
    sumPriceUnit(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_unit;
      });

      return total;
    },
    CheckThree(data){
      console.log("==========CheckThree=========")

      let three_tod_numbers = [];

      data.forEach((result)=>{


        three_tod_numbers.push({
          "numbers":result.numbers,
          "price":result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let three_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find
        //let find_tod_suffle

        if(three_numbers_sort.length !== 0){
          //let number_array = Array.from(String(result.numbers), Number)
          find = three_numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if(find){
          find.price += result.price;
        }
        else{
          three_numbers_sort.push({
            "numbers":result.numbers,
            "price":result.price,
          })
        }


      });

      console.log(three_numbers_sort);
      console.log("==========CheckThree=========")

      return three_numbers_sort;
    },
    CheckThreeTod(data){
      console.log("==========CheckThreeTod=========")

      let three_tod_numbers = [];

      data.forEach((result)=>{


        three_tod_numbers.push({
          "numbers":result.numbers,
          "price_divide":result.price_divide,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let three_tod_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find_tod
        let find_tod_suffle

        if(three_tod_numbers_sort.length !== 0){
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_three(number_array);

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        console.log(find_tod)

        if(find_tod){
          find_tod.price_divide += result.price_divide;
        }else if(find_tod_suffle)
        {
          find_tod_suffle.price_divide += result.price_divide;
        }
        else{
          three_tod_numbers_sort.push({
            "numbers":result.numbers,
            "price_divide":result.price_divide,
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckThreeTod=========")
      return three_tod_numbers_sort;
    },
    CheckTwo(data){
      console.log("==========CheckTwo=========")

      let two_numbers = [];

      data.forEach((result)=>{


        two_numbers.push({
          "numbers":result.numbers,
          "price":result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = two_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let two_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find
        //let find_tod_suffle

        if(two_numbers_sort.length !== 0){
          //let number_array = Array.from(String(result.numbers), Number)
          find = two_numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if(find){
          find.price += result.price;
        }
        else{
          two_numbers_sort.push({
            "numbers":result.numbers,
            "price":result.price,
          })
        }


      });

      console.log(two_numbers_sort);
      console.log("==========CheckTwo=========")

      return two_numbers_sort;
    },
    CheckTwoTod(data){
      console.log("==========CheckTwo=========")

      let two_numbers = [];

      data.forEach((result)=>{


        two_numbers.push({
          "numbers":result.numbers,
          "price":result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = two_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let two_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find
        let find_tod_suffle

        if(two_numbers_sort.length !== 0){
          let number_array = Array.from(String(result.numbers), Number)
          find = two_numbers_sort.find(tod => tod.numbers === result.numbers);
          let number_result = [number_array[0]+""+number_array[1],number_array[1]+""+number_array[0]]
          find_tod_suffle = two_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if(find){
          find.price += result.price;
        }else if(find_tod_suffle)
        {
          find_tod_suffle.price += result.price;
        }
        else{
          two_numbers_sort.push({
            "numbers":result.numbers,
            "price":result.price,
          })
        }


      });

      console.log(two_numbers_sort);
      console.log("==========CheckTwo=========")

      return two_numbers_sort;
    },
    CheckFourTod(data){
      console.log("==========CheckFourTod=========")

      let three_tod_numbers = [];

      data.forEach((result)=>{


        three_tod_numbers.push({
          "numbers":result.numbers,
          "price":result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let three_tod_numbers_sort = [];

      console.log("=======sort_number=======");
      console.log(sort_number);
      console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find_tod
        let find_tod_suffle

        if(three_tod_numbers_sort.length !== 0){
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_four(number_array);

          console.log("========suffle========")
          console.log(number_result)
          console.log("========suffle========")

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        console.log(find_tod)

        if(find_tod){
          find_tod.price += result.price;
        }else if(find_tod_suffle)
        {
          find_tod_suffle.price += result.price;
        }
        else{
          three_tod_numbers_sort.push({
            "numbers":result.numbers,
            "price":result.price,
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckFourTod=========")
      return three_tod_numbers_sort;
    },
    CheckFiveTod(data){
      console.log("==========CheckFiveTod=========")

      let three_tod_numbers = [];

      data.forEach((result)=>{


        three_tod_numbers.push({
          "numbers":result.numbers,
          "price":result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let three_tod_numbers_sort = [];

      console.log("=======sort_number=======");
      console.log(sort_number);
      console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find_tod
        let find_tod_suffle

        if(three_tod_numbers_sort.length !== 0){
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_five(number_array);

          console.log("========suffle========")
          console.log(number_result)
          console.log("========suffle========")

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        console.log(find_tod)

        if(find_tod){
          find_tod.price += result.price;
        }else if(find_tod_suffle)
        {
          find_tod_suffle.price += result.price;
        }
        else{
          three_tod_numbers_sort.push({
            "numbers":result.numbers,
            "price":result.price,
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckFiveTod=========")
      return three_tod_numbers_sort;
    },
    CheckNumber(data){
      console.log("==========CheckNumberUp=========")

      let numbers = [];

      data.forEach((result)=>{


        numbers.push({
          "numbers":result.numbers,
          "price":result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = numbers.sort(function(a, b){return a.numbers-b.numbers})

      let numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find
        //let find_tod_suffle

        if(numbers_sort.length !== 0){
          //let number_array = Array.from(String(result.numbers), Number)
          find = numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if(find){
          find.price += result.price;
        }
        else{
          numbers_sort.push({
            "numbers":result.numbers,
            "price":result.price,
          })
        }


      });

      console.log(numbers_sort);
      console.log("==========CheckNumberUp=========")

      return numbers_sort;
    },
    CheckNumberPickUp(data){
      console.log("==========CheckNumberUp=========")

      let numbers = [];

      data.forEach((result)=>{


        numbers.push({
          "numbers":result.numbers,
          "price_hundred":result.price_hundred,
          "price_ten":result.price_ten,
          "price_unit":result.price_unit,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = numbers.sort(function(a, b){return a.numbers-b.numbers})

      let numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find
        //let find_tod_suffle

        if(numbers_sort.length !== 0){
          //let number_array = Array.from(String(result.numbers), Number)
          find = numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if(find){
          find.price_hundred += result.price_hundred;
          find.price_ten += result.price_ten;
          find.price_unit += result.price_unit;
        }
        else{
          numbers_sort.push({
            "numbers":result.numbers,
            "price_hundred":result.price_hundred,
            "price_ten":result.price_ten,
            "price_unit":result.price_unit,
          })
        }


      });

      console.log(numbers_sort);
      console.log("==========CheckNumberUp=========")

      return numbers_sort;
    },
    CheckNumberPickDown(data){
      console.log("==========CheckNumberUp=========")

      let numbers = [];

      data.forEach((result)=>{


        numbers.push({
          "numbers":result.numbers,
          "price_ten":result.price_ten,
          "price_unit":result.price_unit,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = numbers.sort(function(a, b){return a.numbers-b.numbers})

      let numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find
        //let find_tod_suffle

        if(numbers_sort.length !== 0){
          //let number_array = Array.from(String(result.numbers), Number)
          find = numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if(find){
          find.price_ten += result.price_ten;
          find.price_unit += result.price_unit;
        }
        else{
          numbers_sort.push({
            "numbers":result.numbers,
            "price_ten":result.price_ten,
            "price_unit":result.price_unit,
          })
        }


      });

      console.log(numbers_sort);
      console.log("==========CheckNumberUp=========")

      return numbers_sort;
    },
  },

}


</script>

<style scoped>
@media print {
  .menu_custom_print{
    display: none;
  }
}

@media screen {

  .card_container {
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
  }

  .card_header {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
  }

  .card_content {
    padding: 1rem;
  }

  .img_thumb {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
  }

  .form_action {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .image_preview_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #image_preview {
    width: 20rem;
    text-align: center;
  }

  .btn_sale {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
  }

  .btn_sale:active {
    background: #0686E8;
    color: #FFFFFF;
  }

  .btn_sale.active {
    background: #0686E8;
    color: #FFFFFF;
  }

  .button_list {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sale_container {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form_cal {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
  }

  .input-custom {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
  }

  .table_custom {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
  }

  .head_title {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .head_title_sub {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .table_list {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .data_table {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .table_custom_summary {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
  }

  .head_title_summary {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .head_title_sub_summary {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .table_list_summary {
    width: 80%;
    display: flex;
    justify-content: center;
  }

  .data_table_summary {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
  }

  .delete_btn {
    color: red !important;
  }

  .btn_sale:disabled {
    filter: saturate(0);
  }

  .input-custom:disabled {
    filter: saturate(0);
  }

  .loading_image {
    height: 8rem;
    margin-bottom: -5rem;
  }
  .header_top_report{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
  }
  .table-custom{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
    margin: 2rem;
  }
  .table-custom tr th{
    padding: 0.5rem;
    padding-inline: 2rem;
  }
  .table-custom tr td{
    padding: 0.5rem;
    text-align: center;
  }
  .table-custom tr td.sum_text{
    text-align: left;
  }
  .report_table{
    padding: 0.5rem;
  }
  .loading_image_main{
    margin-block: -5rem;
  }
}
</style>

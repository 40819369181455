<template>
  <v-app>
    <div class="container" v-if="auth">
      <div class="menu_container" >
        <nav>
          <div class="wrapper">
            <div class="logo"><a href="#">Lotto</a></div>
            <input type="radio" name="slider" id="menu-btn">
            <input type="radio" name="slider" id="close-btn">
            <ul class="nav-links">
              <label for="close-btn" class="btn close-btn"><i class="fas fa-times"></i></label>


              <li>
                <a href="#" class="desktop-item">ระบบ</a>
                <input type="checkbox" id="showDrop1">
                <label for="showDrop1" class="mobile-item">ระบบ</label>
                <ul class="drop-menu">
                  <li v-if="user.type === 'user'" ><a v-on:click="dialog = true" href="#">เริ่มงวดใหม่</a></li>
                  <li v-on:click="Logout()" ><a href="#">ออกจากระบบ</a></li>
                </ul>
              </li>

              <li>
                <a  v-on:click="GotoSale()" >รายการขาย</a>
              </li>

              <li v-if="user.type === 'user'"  ><a v-on:click="cut = true" href="#" >ทำรายการตัดส่ง</a></li>


              <li>
                <a href="#" class="desktop-item">รายการสรุป</a>
                <input type="checkbox" id="showDrop2">
                <label for="showDrop2" class="mobile-item">รายการสรุป</label>
                <ul class="drop-menu">
                  <li v-if="user.type === 'user'" >
                    <router-link >
                    <a v-on:click="lotto_summary = true" href="#">ใสผลสลาก</a>
                    </router-link>
                  </li>
                  <li> <router-link to="/report">
                    <a href="#">รายงานรวม</a>
                  </router-link>
                  </li>
                  <li>
                    <router-link to="/report_summary">
                    <a href="#">รายการสรุปรวม</a>
                    </router-link>
                  </li>
                  <li><a href="#">
                    <router-link to="/report_profit">
                    รายการสรุปรวมกำไร
                    </router-link>
                  </a>
                  </li>
                </ul>
              </li>


              <li v-if="user.type === 'user'" >
                <a href="#" class="desktop-item">ข้อมูลพื้นฐาน</a>
                <input type="checkbox" id="showDrop3">
                <label for="showDrop3" class="mobile-item">ข้อมูลพื้นฐาน</label>
                <ul class="drop-menu">
                  <li><a href="#">
                    <router-link to="/employee">
                    ข้อมูลผู้ใช้งาน
                    </router-link>
                  </a>
                  </li>
                  <li v-on:click="limit = true" ><a href="#">
                    <router-link to="/employee">
                    เลขอั้น
                    </router-link>
                  </a></li>
                </ul>
              </li>






            </ul>
            <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label>
          </div>
        </nav>
      </div>


      <div class="menu_custon menu_custom_print" style="display: none" >
        <ul>
          <li>
            <a>ระบบ
              <v-icon aria-hidden="false">
                mdi-apps
              </v-icon>
            </a>
            <ul class="drop_down">
              <li v-if="user.type === 'user'">
                <a v-on:click="dialog = true">
                  <v-icon aria-hidden="false">
                    mdi-arrow-right
                  </v-icon>
                  เริ่มงวดใหม่</a>
              </li>
              <li>
                <a v-on:click="Logout()">
                  <v-icon aria-hidden="false">
                    mdi-arrow-right
                  </v-icon>
                  ออกจากระบบ</a>
              </li>
            </ul>
          </li>

          <li>
            <a>รายการขาย
              <v-icon aria-hidden="false">
                mdi-note-text-outline
              </v-icon>
            </a>
            <ul class="drop_down">
              <li>
                <router-link to="/sale">
                  <a>
                    <v-icon aria-hidden="false">
                      mdi-arrow-right
                    </v-icon>
                    ทำรายการขาย</a>
                </router-link>
              </li>
            </ul>
          </li>


          <li v-if="user.type === 'user'">
            <a>ทำรายการตัดส่ง
              <v-icon aria-hidden="false">
                mdi-note-text-outline
              </v-icon>
            </a>
            <ul class="drop_down">
              <li v-if="user.type === 'user'">
                <a v-on:click="cut = true">
                  <v-icon aria-hidden="false">
                    mdi-arrow-right
                  </v-icon>
                  ทำรายการตัดส่ง</a>
              </li>
            </ul>
          </li>

          <li>
            <a>รายการสรุป
              <v-icon aria-hidden="false">
                mdi-chart-areaspline
              </v-icon>
            </a>
            <ul class="drop_down">
              <li v-if="user.type === 'user'">
                <a v-on:click="lotto_summary = true">
                  <v-icon aria-hidden="false">
                    mdi-arrow-right
                  </v-icon>
                  ใสผลสลาก</a>
              </li>
              <li v-if="user.type === 'user'">
                <router-link to="/report">
                  <a>
                    <v-icon aria-hidden="false">
                      mdi-arrow-right
                    </v-icon>
                    รายงานรวม</a>
                </router-link>
              </li>
              <li>
                <router-link to="/report_summary">
                  <a>
                    <v-icon aria-hidden="false">
                      mdi-arrow-right
                    </v-icon>
                    รายการสรุปรวม</a>
                </router-link>
              </li>
              <li>
                <router-link to="/report_profit">
                  <a>
                    <v-icon aria-hidden="false">
                      mdi-arrow-right
                    </v-icon>
                    รายการสรุปรวมกำไร</a>
                </router-link>
              </li>
            </ul>
          </li>


          <li v-if="user.type === 'user'">
            <a>ข้อมูลพื้นฐาน
              <v-icon aria-hidden="false">
                mdi-cog-outline
              </v-icon>
            </a>
            <ul class="drop_down">
              <li>
                <router-link to="/employee">
                  <a>
                    <v-icon aria-hidden="false">
                      mdi-arrow-right
                    </v-icon>
                    ข้อมูลผู้ใช้งาน</a>
                </router-link>
              </li>
              <li>
                <a>
                  <v-icon aria-hidden="false">
                    mdi-arrow-right
                  </v-icon>
                  สมุดบันทึก
                </a>
              </li>
              <li>
                <a v-on:click="limit = true">
                  <v-icon aria-hidden="false">
                    mdi-arrow-right
                  </v-icon>
                  เลขอั้น
                </a>
              </li>

            </ul>
          </li>

        </ul>
      </div>
    </div>


    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>

    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >

      <v-card>
        <v-card-title>
          <span v-if="lotto.length !== 0" class="text-h5">งวดปัจจุบัน</span>
          <span v-else class="text-h5">เริ่มงวดใหม่</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                v-if="lotto.length === 0"
                ref="form"
                lazy-validation
            >
              <font class="date_time_font">วันเวลาเริ่มต้น {{ this.date_start }} เวลา {{ this.time_start }}</font><br>
              <div class="date_time">

                <v-date-picker
                    v-model="date_start"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
                <div class="time_container">
                  <input class="time_ip"
                         :value="start_hour"
                         @input=CheckStartHour
                         maxlength="2" placeholder="HH">:
                  <input class="time_ip"
                         :value="start_minute"
                         @input=CheckStartMinute
                         maxlength="2" placeholder="SS">
                </div>
              </div>

              <font class="date_time_font">วันเวลาสิ้นสุด {{ this.date_end }} เวลา {{ this.time_end }}</font><br>
              <div class="date_time">

                <v-date-picker
                    v-model="date_end"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                </v-date-picker>


                <div class="time_container">
                  <input class="time_ip"
                         :value="end_hour"
                         @input=CheckEndHour
                         maxlength="2" placeholder="HH">:
                  <input class="time_ip"
                         :value="end_minute"
                         @input=CheckEndMinute
                         maxlength="2" placeholder="SS">
                </div>

              </div>
            </v-form>

            <div v-if="lotto.length !== 0">
              <font class="date_time_font">วันเวลาเริ่มต้น {{ this.date_start }} &nbsp;&nbsp; เวลา {{
                  this.time_start
                }}</font><br>
              <font class="date_time_font">วันเวลาสิ้นสุด {{ this.date_end }} &nbsp;&nbsp; เวลา {{
                  this.time_end
                }}</font><br>
              <hr>
              <v-switch class="custom_switch"
                        v-model="is_open"
                        inset
                        @change=IsOpen
                        label="อนุญาตให้ดูข้อมูล"
              ></v-switch>

              <v-switch class="custom_switch"
                        v-model="is_edit"
                        inset
                        @change=IsEdit
                        label="อนุญาตกรอกข้อมูล"
              ></v-switch>

            </div>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              v-if="lotto.length !== 0"
              class="close_lotto"
              color="blue darken-1"
              text
              @click="CloseLotto"
          >
            ปิดงวด
          </v-btn>
          <v-btn
              class="close_btn"
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            ปิด
          </v-btn>
          <v-btn
              v-if="date_start
              && time_start
              && date_end
              && time_end
              && start_hour
              && start_minute
              && end_hour
              && end_minute
              && lotto.length === 0

"
              color="blue darken-1 open_lotto"
              text
              @click="StartLottory"
          >
            เริ่มงวดใหม่
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="limit"
        persistent
        class="custom-dailog"
    >

      <v-card class="dialog-limit">
        <v-card-title>
          <span class="text-h5">ข้อมูลเลขอั้น</span> <img v-if="is_loading_main === true" class="loading_image_main"
                                                          src="./assets/image/loading.svg">
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="row column-reverse"   >
              <v-col xl="8" lg="8" md="12" sm="12" xs="12" cols="12"  class="table_container">
                <table class="table_custom_limit">
                  <tr>
                    <th>ลบ</th>
                    <th>เลข</th>
                    <th>ราคา</th>
                    <th>ปิดรับ</th>
                    <th>ลูกค้า</th>
                  </tr>
                  <tr v-for="(result) in lotto_limit" v-bind:key="result.id"
                      :class="{ is_close_row : (result.is_close === 'true' && result.is_all === 'true') }">
                    <td v-if="result.is_all === 'true' ">
                      <v-checkbox
                          class="checkbox_main"
                          v-model="ids_number"
                          :value="result.numbers"
                      ></v-checkbox>
                    </td>
                    <td v-else="">
                      <v-checkbox
                          class="checkbox_main"
                          v-model="ids"
                          :value="result.id"
                      ></v-checkbox>
                    </td>
                    <td>{{ result.numbers }}</td>
                    <td>{{ result.pay }}</td>
                    <td>{{ result.is_close === 'true' ? 'ปิดรับ' : '-' }}</td>
                    <td>{{ result.is_all === 'true' ? 'ทั้งหมด' : '-' }}</td>
                  </tr>
                </table>
              </v-col>
              <v-col xl="4" lg="4" md="12" sm="12" xs="12" cols="12"  >
                <v-select
                    v-if="users.type === 'user' && all_employee === false"
                    v-model="employees_app_id"
                    :items="employee_app"
                    item-text="label"
                    item-value="id"
                    label="ข้อมูลพนักงาน"
                    v-on:change="getLottoNumbers(),GetEmployeeOneData(),ChangeNumberPercent()"
                ></v-select>
                <v-checkbox
                    v-model="all_employee"
                    label="พนักงานทั้งหมด"
                    v-on:change="getLottoNumbers()"
                ></v-checkbox>
                <v-select
                    v-if="users.type === 'user'"
                    v-model="number_id"
                    :items="number_list_app"
                    item-text="label"
                    item-value="id"
                    label="ข้อมูลเลข"
                    v-on:change="ChangeNumberPercent()"
                ></v-select>
                <v-row class="row-form">
                  <v-col xl="6">
                    <v-text-field
                        class="v-text-field-limit"
                        v-model="number_limit"
                        placeholder="ตัวเลข"
                        type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col xl="6">
                    <v-checkbox
                        v-model="flip_limit"
                        label="กลับ"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="row-form">
                  <v-col xl="6" class="col-custom">
                    <v-text-field
                        class="v-text-field-limit"
                        :value="price_pay"
                        v-on:keyup="price_percent = (price_pay_emp ? parseInt((price_pay/price_pay_emp)*100) :100)"
                        placeholder="ราคาจ่าย"
                        @keyup="CheckPayLimit"
                        type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col xl="6" class="col-custom">
                    <v-text-field
                        class="v-text-field-limit"
                        v-model="price_percent"
                        placeholder="% จ่าย"
                        type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <button class="btn_limit" v-if="is_loading_main === false" v-on:click="AddLimit('true')"
                        :disabled="CheckLimit()">ปิดรับ
                </button>
                <button class="btn_limit" v-if="is_loading_main === false" v-on:click="AddLimit('false')"
                        :disabled="CheckLimit()">ทำรายการอั้น
                </button>
                <button class="btn_limit delete_btn" v-if="is_loading_main === false" v-on:click="DeleteLimitCF()"
                        :disabled="CheckDelete()">ยกเลิกการอั้น
                </button>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="close_btn"
              color="blue darken-1"
              text
              @click="limit = false"
          >
            ปิดหน้าต่าง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="cut"
        persistent
    >

      <v-card class="dialog-limit">
        <v-card-title>
          <span class="text-h5">รายการตัดส่ง</span> <img v-if="is_loading_main === true" class="loading_image_main"
                                                         src="./assets/image/loading.svg">
        </v-card-title>
        <v-card-text>
          <v-container>

            <hr>
            <br>

            <v-row class="row">

              <v-col xl="8" lg="8" md="12" sm="12" cols="12" class="table_container ">

                <v-row class="pa-2 pt-0 pb-0 row_custom">
                  <v-col xl="6" lg="6" md="12" sm="12" xs="12">
                    <v-select
                        v-model="users_id"
                        :items="users_app"
                        item-text="label"
                        item-value="id"
                        label="ข้อมูลเจ้ามือ"
                    ></v-select>
                  </v-col>
                  <v-col xl="6" lg="6" md="12" sm="12">
                    <v-select
                        v-model="number_id"
                        :items="number_list_app"
                        item-text="label"
                        item-value="id"
                        label="ข้อมูลเลข"
                        v-on:change="getLottoNumberCut()"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row class="pa-2 pt-0 row_custom">
                  <v-col xl="4" lg="4" md="12" sm="12">
                    <div class="list_label">
                      <font>เก็บตัวละ:</font>
                      <v-text-field
                          class="v-text-field-limit"
                          v-model="give_number"
                          placeholder="เก็บ"
                          type="number"
                      ></v-text-field>
                      <font v-if="is_send">ส่งตัวละ:</font>
                      <b v-if="is_send">{{ this.send_number }}</b>
                    </div>
                    <div class="list_label">
                      <font>ยอดขาย:</font>
                      <b>{{ this.sales }}</b>
                    </div>
                  </v-col>
                  <v-col xl="4" lg="4" md="12" sm="12">
                    <div class="list_label">
                      <font>ยอดตัด:</font>
                      <b>{{ summary_cut }}</b>
                    </div>
                    <div class="list_label">
                      <font>ส่งแล้ว:</font>
                      <b>{{ this.sales_send }}</b>
                    </div>
                  </v-col>
                  <v-col xl="4" lg="4" md="12" sm="12">
                    <div class="list_label">
                      <font>ยอดรอส่ง:</font>
                      <b>{{ this.sales_wait_send }}</b>
                    </div>
                    <div class="list_label">
                      <font>คงเหลือ:</font>
                      <b>{{ parseInt(this.sales - this.sales_wait_send) }}</b>
                    </div>
                  </v-col>
                </v-row>
                <table class="table_custom_limit">
                  <tr>
                    <th>เลข</th>
                    <th>ราคา</th>
                  </tr>
                  <tr v-for="(result) in lotto_cut" v-bind:key="result.id">
                    <td>{{ result.numbers }}</td>
                    <td>{{ result.price }}</td>
                  </tr>
                </table>
              </v-col>
              <v-col xl="4" lg="4" md="12" sm="12" cols="12"  >
                <v-row>
                  <table class="table_custom_limit">
                    <tr>
                      <th colspan="4">รายการส่ง</th>
                    </tr>
                    <tr>
                      <th>ส่ง</th>
                      <th>ลบ</th>
                      <th>พิมพ์</th>
                      <th>จำนวนเงิน</th>
                    </tr>
                    <tr v-for="(result) in list_cut" v-bind:key="result.id">
                      <td>
                        {{ result.users && result.users.first_name }}
                      </td>
                      <td align="center">
                        <button
                            @click="DeleteCut(result.id)"
                        >
                          <v-icon class="delete_btn_cut" aria-hidden="false">
                            mdi-delete
                          </v-icon>
                        </button>
                      </td>
                      <td align="center" valign="center">
                        <button
                            @click="Print(result.id)"
                        >
                          <v-icon class="print-icon" aria-hidden="false">
                            mdi-printer
                          </v-icon>
                        </button>
                      </td>
                      <td>{{ result.price }}</td>
                    </tr>
                  </table>

                  <div class="table-custom-container">
                    <table class="table_custom_limit">
                      <tr>
                        <th colspan="3">รายการรอส่ง</th>
                      </tr>
                      <tr>
                        <th>
                          <v-checkbox v-if="lotto_cut_wait.length !== 0"
                                      class="checkbox_main"
                                      v-model="selectAll"
                          ></v-checkbox>
                        </th>
                        <th>เลข</th>
                        <th>ราคา</th>
                      </tr>
                      <tr v-for="(result,index) in lotto_cut_wait" v-bind:key="result.id">
                        <td align="center">
                          <v-checkbox
                              class="checkbox_main"
                              v-model="index_cut"
                              :value="index"
                          ></v-checkbox>
                        </td>
                        <td>{{ result.numbers }}</td>
                        <td>{{ result.price }}</td>
                      </tr>
                    </table>
                  </div>

                </v-row>
                <v-row class="center_list">
                  <button class="btn_del_cut" v-if="is_loading_main === false && this.index_cut.length !== 0 "
                          v-on:click="RemoveCutSelect()">ลบที่เลือก
                  </button>
                </v-row>
                <v-row>
                  <div class="button_list">
                    <button class="btn_limit"
                            v-if="is_loading_main === false && this.lotto_cut_wait.length === 0 && this.lotto_cut !== 0 "
                            v-on:click="CutWait()">บันทึกรอส่ง
                    </button>
                    <button class="btn_del_cut_full"
                            v-if="is_loading_main === false && this.lotto_cut_wait.length !== 0"
                            v-on:click="ClearCutWait()">ลบข้อมูลรอส่ง
                    </button>
                    <button class="btn_limit" v-if="is_loading_main === false && this.lotto_cut_wait.length !== 0"
                            v-on:click="SubmitSendWait()">บันทึกส่ง
                    </button>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="close_btn"
              color="blue darken-1"
              text
              @click="cut = false"
          >
            ปิดหน้าต่าง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="lotto_summary"
        persistent
        style="width: 50vw"
    >

      <v-card>
        <v-card-title>
          <span v-if="!lotto.award" class="text-h5">กรอกรางวัล</span>
          <span v-else class="text-h5">ข้อมูลรางวัล</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="!lotto.award">
            <v-form
                ref="form2"
                lazy-validation
            >
              <v-row class="form-custom">
                <input type="number" placeholder="รางวัลที่ 1" v-model="award_number1" class="input-custom"/>
                <input type="number" placeholder="เลขท้าย 2 ตัวล่าง" v-model="award_number2" class="input-custom"/>
              </v-row>
              <v-row class="form-custom">
                <input type="number" placeholder="เลขท้าย 3 ตัว" v-model="award_number3_back_1" class="input-custom"/>
                <input type="number" placeholder="เลขท้าย 3 ตัว" v-model="award_number3_back_2" class="input-custom"/>
                <input type="number" placeholder="เลขท้าย 3 ตัว" v-model="award_number3_back_3" class="input-custom"/>
                <input type="number" placeholder="เลขท้าย 3 ตัว" v-model="award_number3_back_4" class="input-custom"/>
              </v-row>
              <v-row class="form-custom">
                <input type="number" placeholder="เลขหน้า 3 ตัว" v-model="award_number3_front_1" class="input-custom"/>
                <input type="number" placeholder="เลขหน้า 3 ตัว" v-model="award_number3_front_2" class="input-custom"/>
              </v-row>

              <v-row>
                <div align="center" class="container-button-list">
                  <button type="button" v-if="this.lotto && is_loading_main === false" v-on:click="submitAward()"
                          class="btn_submit">ตกลง
                  </button>
                  <font v-else class="error-text">ไม่พบข้อมูลงวด</font>
                </div>
              </v-row>
            </v-form>
          </v-container>
          <v-container class="container-list-award" v-else>
            <div class="list_award">
              <font class="label">รางวัลที่ 1</font>
              <font>{{ lotto.award.award_1 }}</font>
            </div>
            <div class="list_award">
              <font class="label">3 ตัวบน</font>
              <font>{{ lotto.award.award_three_up }}</font>
            </div>
            <div class="list_award">
              <font class="label">3 ตัวโต๊ด</font>
              <font>{{ lotto.award.award_three_tod }}</font>
            </div>
            <div class="list_award">
              <font class="label">3 ตัวล่าง</font>
              <font>{{ lotto.award.award_three_down }}</font>
            </div>
            <div class="list_award">
              <font class="label">3 ตัวหน้า</font>
              <font>{{ lotto.award.award_three_front }}</font>
            </div>
            <div class="list_award">
              <font class="label">2 ตัวบน</font>
              <font>{{ lotto.award.award_two_up }}</font>
            </div>
            <div class="list_award">
              <font class="label">2 ตัวโต๊ด</font>
              <font>{{ lotto.award.award_two_tod }}</font>
            </div>
            <div class="list_award">
              <font class="label">2 ตัวล่าง</font>
              <font>{{ lotto.award.award_two_down }}</font>
            </div>
            <div class="list_award">
              <font class="label">วิ่งบน</font>
              <font>{{ lotto.award.award_run_up }}</font>
            </div>
            <div class="list_award">
              <font class="label">วิ่งล่าง</font>
              <font>{{ lotto.award.award_run_down }}</font>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="lotto_summary = false"
          >
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-app>


</template>

<script>

import {baseUrl} from "./const/api";
import {
  DeleteCutNumber,
  DeleteLimitNumber,
  GetEmployee,
  GetLotto,
  GetLottoNumber,
  GetOneEmployee, GetUsers, PostAward, PostCutNumber,
  PostLottoNumberLimit,
  StartLotto, UpdateLotto
} from "./lib/controller";
import {number_suffle_five, number_suffle_four, number_suffle_three, ResponseData} from "./lib/utility";


export default {
  name: 'App',
  components: {},
  data() {
    return {
      date_start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date_end: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      time_start: "",
      time_end: "",
      cut_type: 1,
      dialog: false,
      tree_up: true,
      tree_up_group: false,
      tree_up_number: "",
      tree_up_number_group: "",
      tree_up_price: "",
      tree_up_price_group: "",
      tree_up_price_divide: "",
      price_percent: 100,
      price_pay: 100,
      price_pay_emp: 100,
      number_id: "",
      is_loading_main: false,
      two_up: false,
      two_up_number: "",
      two_up_price: "",
      two_up_price_divide: "",

      two_down: false,
      two_down_number: "",
      two_down_price: "",
      two_down_price_divide: "",

      two_tod: false,
      two_tod_number: "",
      two_tod_price: "",
      two_tod_price_divide: "",

      four_tod: false,
      four_tod_number: "",
      four_tod_price: "",
      four_tod_price_divide: "",

      five_tod: false,
      five_tod_number: "",
      five_tod_price: "",
      five_tod_price_divide: "",

      number_up: false,
      number_up_number: "",
      number_up_price: "",
      number_up_price_divide: "",

      number_down: false,
      number_down_number: "",
      number_down_price: "",
      number_down_price_divide: "",

      number_pick_up: false,
      number_pick_up_number: "",
      number_pick_up_hundred: "",
      number_pick_up_tens: "",
      number_pick_up_unit: "",

      number_pick_down: false,
      number_pick_down_number: "",
      number_pick_down_tens: "",
      number_pick_down_unit: "",

      three_limit_number: "",
      three_limit_buy: "",
      three_limit_percent: "",
      three_limit_flip: false,
      three_limit_tod: false,
      three_limit_is_all: false,
      three_limit_is_close: false,


      two_up_limit_number: "",
      two_up_limit_buy: "",
      two_up_limit_percent: "",
      two_up_limit_flip: false,
      two_up_limit_tod: false,
      two_up_limit_is_all: false,
      two_up_limit_is_close: false,


      two_down_limit_number: "",
      two_down_limit_buy: "",
      two_down_limit_percent: "",
      two_down_limit_flip: false,
      two_down_limit_tod: false,
      two_down_limit_is_all: false,
      two_down_limit_is_close: false,


      two_tod_limit_number: "",
      two_tod_limit_buy: "",
      two_tod_limit_percent: "",
      two_tod_limit_flip: false,
      two_tod_limit_tod: false,
      two_tod_limit_is_all: false,
      two_tod_limit_is_close: false,


      four_tod_limit_number: "",
      four_tod_limit_buy: "",
      four_tod_limit_percent: "",
      four_tod_limit_flip: false,
      four_tod_limit_tod: false,
      four_tod_limit_is_all: false,
      four_tod_limit_is_close: false,

      five_tod_limit_number: "",
      five_tod_limit_buy: "",
      five_tod_limit_percent: "",
      five_tod_limit_flip: false,
      five_tod_limit_tod: false,
      five_tod_limit_is_all: false,
      five_tod_limit_is_close: false,


      number_up_limit_number: "",
      number_up_limit_buy: "",
      number_up_limit_percent: "",
      number_up_limit_flip: false,
      number_up_limit_tod: false,
      number_up_limit_is_all: false,
      number_up_limit_is_close: false,


      number_down_limit_number: "",
      number_down_limit_buy: "",
      number_down_limit_percent: "",
      number_down_limit_flip: false,
      number_down_limit_tod: false,
      number_down_limit_is_all: false,
      number_down_limit_is_close: false,


      number_pick_up_limit_number: "",
      number_pick_up_limit_buy: "",
      number_pick_up_limit_percent: "",
      number_pick_up_limit_flip: false,
      number_pick_up_limit_tod: false,
      number_pick_up_limit_is_all: false,
      number_pick_up_limit_is_close: false,

      number_pick_down_limit_number: "",
      number_pick_down_limit_buy: "",
      number_pick_down_limit_percent: "",
      number_pick_down_limit_flip: false,
      number_pick_down_limit_tod: false,
      number_pick_down_limit_is_all: false,
      number_pick_down_limit_is_close: false,


      tree_summary: 0,
      tree_tod_summary: 0,
      two_summary: 0,
      two_down_summary: 0,
      two_tod_summary: 0,
      four_tod_summary: 0,
      five_tod_summary: 0,
      number_up_summary: 0,
      number_down_summary: 0,
      number_pickup_summary: 0,
      number_pickdown_summary: 0,


      tree_up_data: [],
      tree_up_data_group: [],
      two_up_data: [],
      two_up_data_group: [],
      two_down_data: [],
      two_down_data_group: [],
      two_tod_data: [],
      two_tod_data_group: [],
      four_tod_data: [],
      four_tod_data_group: [],
      five_tod_data: [],
      five_tod_data_group: [],
      number_up_data: [],
      number_up_data_group: [],
      number_down_data: [],
      number_down_data_group: [],
      number_pick_up_data: [],
      number_pick_up_data_group: [],
      number_pick_down_data: [],
      number_pick_down_data_group: [],
      three_up_limit_data: [],
      three_up_tod_limit_data: [],
      two_up_limit_data: [],
      two_down_limit_data: [],
      two_tod_limit_data: [],
      four_tod_limit_data: [],
      five_tod_limit_data: [],
      number_up_limit_data: [],
      number_down_limit_data: [],
      number_pick_up_limit_data: [],
      number_pick_down_limit_data: [],

      lotto: [],
      lotto_limit: [],
      ids: [],
      ids_number: [],
      index_cut: [],
      lotto_cut: [],
      lotto_cut_wait: [],

      all_employee: false,
      flip_limit: false,
      number_limit: "",
      users: {},
      employee_app: {},
      users_app: {},
      users_id: {},
      number_list_app: [
        {
          "label": "3 ตัวบน",
          "id": "tree_up",
        },
        {
          "label": "3 ตัวโต๊ด",
          "id": "tree_tod",
        },
        {
          "label": "2 ตัวบน",
          "id": "two_up",
        },
        {
          "label": "2 ตัวล่าง",
          "id": "two_down",
        },
        {
          "label": "2 ตัวโต๊ด",
          "id": "two_tod",
        },
        {
          "label": "4 ตัวโต๊ด",
          "id": "four_tod",
        },
        {
          "label": "5 ตัวโต๊ด",
          "id": "five_tod",
        },
        {
          "label": "วิ่งบน",
          "id": "number_up",
        },
        {
          "label": "วิ่งล่าง",
          "id": "number_down",
        },
        {
          "label": "วิ่งปักบน",
          "id": "number_pick_up",
        },
        {
          "label": "วิ่งปักล่าง",
          "id": "number_pick_down",
        },
      ],
      employees_app_id: "",
      employees_app_one: {},
      limit: false,
      cut: false,
      is_send: false,
      give_number: 0,
      send_number: 0,
      sales: 0,
      sales_cut: 0,
      sales_more_than: 0,
      send_cut: 0,
      sales_send: 0,
      sales_wait_send: 0,
      balance: 0,
      number_no_sales: 0,
      sales_no_more_than: 0,
      baseUrl: baseUrl,
      auth: localStorage.getItem('user') ? true : false,
      user: JSON.parse(localStorage.getItem('user')),
      items: [
        {title: 'Home', icon: 'mdi-home-city'},
        {title: 'My Account', icon: 'mdi-account'},
        {title: 'Users', icon: 'mdi-account-group-outline'},
      ],
      mini: true,
      lotto_summary: false,
      list_cut: [],
      award_number1: "",
      award_number2: "",
      award_number3_back_1: "",
      award_number3_back_2: "",
      award_number3_back_3: "",
      award_number3_back_4: "",
      award_number3_front_1: "",
      award_number3_front_2: "",
      award_1: "",
      award_three_up: "",
      award_three_tod_1: "",
      award_three_tod_2: "",
      award_three_tod_3: "",
      award_three_tod_5: "",
      award_three_tod_6: "",
      award_three_down_1: "",
      award_three_down_2: "",
      award_three_down_3: "",
      award_three_down_4: "",
      award_three_front_1: "",
      award_three_front_2: "",
      award_3_back_2: "",
      award_3_back_3: "",
      award_3_back_4: "",
      summary_cut: "",
      summary_cut_wait: "",
      start_hour: "",
      end_hour: "",
      start_minute: "",
      end_minute: "",
      is_open: "",
      is_edit: "",
    }
  },
  methods: {
    GotoSale(){
      this.$router.push('/sale')
    },
    Logout() {
      localStorage.removeItem('user');
      window.location = "/login"
    },
    CheckLogin() {
      console.log(this.$router.currentRoute.path)
      const UserData = localStorage.getItem('user');
      if (!UserData && this.$router.currentRoute.path !== "/login") {
        window.location = "/login"
      } else {
        this.users = JSON.parse(UserData);
        if (this.users.type !== 'user') {
          this.employees_app_id = this.users.id;
          this.employees_app_one = this.users;
        }
      }
    },
    async StartLottory() {
      //alert("StartLottory")

      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("date_start", this.date_start)
      formData.append("time_start", this.time_start)
      formData.append("date_end", this.date_end)
      formData.append("time_end", this.time_end)
      formData.append("merchant_id", this.user.merchant_id)
      formData.append("users_id", this.user.id)

      await StartLotto(formData, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :

            this.$toast.open({
              message: 'เริ่มงวดใหม่สำเร็จ',
              type: "success",
              duration: 2000,
              dismissible: true
            })

            setTimeout(() => {
              window.location = "/sale"
            }, 2000)

            break;
          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;
          case 409:

            this.$toast.open({
              message: data.msg,
              type: "warning",
              duration: 10000,
              dismissible: true
            })

            break;
          case 500 :


            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })

            break;
        }

      });


      /* console.log(results)

       if(error){
         Swal({
           icon: "error",
           title: results.message,
         })
       }

       if(status === 200){
         Swal({
           icon: 'success',
           title: 'Success',
         })
       }else{
         Swal({
           icon: results.icon,
           title: results.message,
         })
       }*/

    },
    CheckLimit() {
      console.log("============CheckLimit===========")
      console.log(this.number_id)
      console.log("============CheckLimit===========")
      switch (this.number_id) {
        case "tree_up":
          if ((this.number_limit.length == 3 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "tree_tod":
          if ((this.number_limit.length == 3 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "two_up":
          if ((this.number_limit.length == 2 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "two_down":
          if ((this.number_limit.length == 2 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "two_tod":
          if ((this.number_limit.length == 2 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "four_tod":
          if ((this.number_limit.length == 4 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "five_tod":
          if ((this.number_limit.length == 5 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "number_up":
          if ((this.number_limit.length == 1 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "number_down":
          if ((this.number_limit.length == 1 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "number_pick_up":
          if ((this.number_limit.length == 1 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        case "number_pick_down":
          if ((this.number_limit.length == 1 && this.price_pay != '' && this.price_percent != '')) {
            return false;
          } else {
            return true;
          }
        default:
          return true;
      }


    },
    CheckDelete() {
      if (this.ids.length || this.ids_number.length) {
        return false
      } else {
        return true
      }
    },
    DeleteLimitCF() {

      this.$swal({
        title: 'ยกเลิกเลขอั้น ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.DeleteLimit()
        }
      });
    },
    async DeleteLimit() {
      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("_method", "DELETE")
      formData.append("merchant_id", this.lotto.merchant_id)
      formData.append("lottery_id", this.lotto.id)


      if (this.ids.length) {
        formData.append("ids", this.ids.toString())
      }

      if (this.ids_number.length) {
        formData.append("ids_number", this.ids_number.toString())
      }


      await DeleteLimitNumber(formData, (response) => {
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;

            this.$toast.open({
              message: 'ลบสำเร็จ',
              type: "success",
              duration: 500,
              dismissible: true
            })

            this.getLottoNumbers()


            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });

    },
    async AddLimit(close) {

      if (this.all_employee === true) {
        this.employee_id = "";
      }

      switch (this.number_id) {
        case "tree_up":
          await this.AddTreeUpLimit(close);
          this.ChangeNumberPercent();
          break;
        case "tree_tod":
          await this.AddTreeUpLimitTod(close);
          this.ChangeNumberPercent();
          break;
        case "two_up":
          await this.AddTwoUpLimit(close);
          this.ChangeNumberPercent();
          break;
        case "two_down":
          await this.AddTwoDownLimit(close);
          this.ChangeNumberPercent();
          break;
        case "two_tod":
          await this.AddTwoTodLimit(close);
          this.ChangeNumberPercent();
          break;
        case "four_tod":
          await this.AddFourTodLimit(close);
          this.ChangeNumberPercent();
          break;
        case "five_tod":
          await this.AddFiveTodLimit(close);
          this.ChangeNumberPercent();
          break;
        case "number_up":
          await this.AddNumberUpLimit(close);
          this.ChangeNumberPercent();
          break;
        case "number_down":
          await this.AddNumberDownLimit(close);
          this.ChangeNumberPercent();
          break;
        case "number_pick_up":
          await this.AddNumberPickUpLimit(close);
          this.ChangeNumberPercent();
          break;
        case "number_pick_down":
          await this.AddNumberPickDownLimit(close);
          this.ChangeNumberPercent();
          break;
        default:
          return true;
      }
    },
    async getLottoNumbers() {
      let Swal = this.$swal;
      this.is_loading_main = true;

      const params = {}

      params.merchant_id = this.lotto.merchant_id
      params.lottery_id = this.lotto.id
      if (this.employees_app_id !== "" && this.all_employee === false) {
        params.employee_id = this.employees_app_id
      }

      await GetLottoNumber(params, (response) => {
        console.log(response)
        this.is_loading_main = false;
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;
            this.tree_up_data = data.three_up;
            this.two_up_data = data.two_up;
            this.two_down_data = data.two_down;
            this.two_tod_data = data.two_tod;
            this.four_tod_data = data.four_tod;
            this.five_tod_data = data.five_tod;
            this.number_up_data = data.number_up;
            this.number_down_data = data.number_down;
            this.number_pick_up_data = data.number_pick_up;
            this.number_pick_down_data = data.number_pick_down;

            this.three_up_limit_data = data.three_up_limit;
            this.three_up_tod_limit_data = data.three_tod_limit;
            this.two_up_limit_data = data.two_up_limit;
            this.two_down_limit_data = data.two_down_limit;
            this.two_tod_limit_data = data.two_tod_limit;
            this.four_tod_limit_data = data.four_tod_limit;
            this.five_tod_limit_data = data.five_tod_limit;
            this.number_up_limit_data = data.number_up_limit;
            this.number_down_limit_data = data.number_down_limit;
            this.number_pick_up_limit_data = data.number_pick_up_limit;
            this.number_pick_down_limit_data = data.number_pick_down_limit;

            this.tree_summary = 0;
            this.tree_tod_summary = 0;
            this.two_summary = 0;
            this.two_down_summary = 0;
            this.two_tod_summary = 0;
            this.four_tod_summary = 0;
            this.five_tod_summary = 0;
            this.number_up_summary = 0;
            this.number_down_summary = 0;
            this.number_pickup_summary = 0;
            this.number_pickdown_summary = 0;


            switch (this.number_id) {
              case "tree_up":
                this.lotto_limit = this.three_up_limit_data;
                this.$forceUpdate()
                break;
              case "tree_tod":
                this.lotto_limit = this.three_up_tod_limit_data;
                this.$forceUpdate()
                break;
              case "two_up":
                this.lotto_limit = this.two_up_limit_data;
                this.$forceUpdate()
                break;
              case "two_down":
                this.lotto_limit = this.two_down_limit_data;
                this.$forceUpdate()
                break;
              case "two_tod":
                this.lotto_limit = this.two_tod_limit_data;
                this.$forceUpdate()
                break;
              case "four_tod":
                this.lotto_limit = this.four_tod_limit_data;
                this.$forceUpdate()
                break;
              case "five_tod":
                this.lotto_limit = this.five_tod_limit_data;
                this.$forceUpdate()
                break;
              case "number_up":
                this.lotto_limit = this.number_up_limit_data;
                this.$forceUpdate()
                break;
              case "number_down":
                this.lotto_limit = this.number_down_limit_data;
                this.$forceUpdate()
                break;
              case "number_pick_up":
                this.lotto_limit = this.number_pick_up_limit_data;
                this.$forceUpdate()
                break;
              case "number_pick_down":
                this.lotto_limit = this.number_pick_down_limit_data;
                this.$forceUpdate()
                break;
              default:
                return true;
            }

            this.tree_up_data.forEach((result) => {
              this.tree_summary += result.price ? result.price : 0;
              this.tree_tod_summary += result.price_divide ? result.price_divide : 0;
            });

            this.two_up_data.forEach((result) => {
              this.two_summary += result.price ? result.price : 0;
            });

            this.two_down_data.forEach((result) => {
              this.two_down_summary += result.price ? result.price : 0;
            });

            this.two_tod_data.forEach((result) => {
              this.two_tod_summary += result.price ? result.price : 0;
            });

            this.four_tod_data.forEach((result) => {
              this.four_tod_summary += result.price ? result.price : 0;
            });

            this.five_tod_data.forEach((result) => {
              this.five_tod_summary += result.price ? result.price : 0;
            });

            this.number_up_data.forEach((result) => {
              this.number_up_summary += result.price ? result.price : 0;
            });

            this.number_down_data.forEach((result) => {
              this.number_down_summary += result.price ? result.price : 0;
            });

            this.number_pick_up_data.forEach((result) => {
              this.number_pickup_summary += result.price_hundred ? result.price_hundred : 0;
              this.number_pickup_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickup_summary += result.price_unit ? result.price_unit : 0;
            });

            this.number_pick_down_data.forEach((result) => {
              this.number_pickdown_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickdown_summary += result.price_unit ? result.price_unit : 0;
            });

            this.GroupData();
            this.ChangeNumberPercent();
            this.is_loading_main = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading_main = false;
            break;
        }

      });

    },

    async getLottoNumberCut() {
      let Swal = this.$swal;
      this.is_loading_main = true;

      const params = {}

      params.merchant_id = this.lotto.merchant_id
      params.lottery_id = this.lotto.id
      if (this.employees_app_id !== "" && this.all_employee === false) {
        params.employee_id = this.employees_app_id
      }

      await GetLottoNumber(params, (response) => {
        console.log(response)
        this.is_loading_main = false;
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;
            this.list_cut = [];
            this.lotto_cut_wait = [];
            this.tree_up_data = this.CheckThree(data.three_up);
            this.tree_tod_data = this.CheckThreeTod(data.three_tod);
            this.two_up_data = this.CheckTwo(data.two_up);
            this.two_down_data = this.CheckTwo(data.two_down);
            this.two_tod_data = this.CheckTwoTod(data.two_tod);
            this.four_tod_data = this.CheckFourTod(data.four_tod);
            this.five_tod_data = this.CheckFiveTod(data.five_tod);
            this.number_up_data = this.CheckNumber(data.number_up);
            this.number_down_data = this.CheckNumber(data.number_down);
            this.number_pick_up_data = this.CheckNumberPickUp(data.number_pick_up);
            this.number_pick_down_data = this.CheckNumberPickDown(data.number_pick_down);

            this.three_up_limit_data = data.three_up_limit;
            this.three_up_tod_limit_data = data.three_tod_limit;
            this.two_up_limit_data = data.two_up_limit;
            this.two_down_limit_data = data.two_down_limit;
            this.two_tod_limit_data = data.two_tod_limit;
            this.four_tod_limit_data = data.four_tod_limit;
            this.five_tod_limit_data = data.five_tod_limit;
            this.number_up_limit_data = data.number_up_limit;
            this.number_down_limit_data = data.number_down_limit;
            this.number_pick_up_limit_data = data.number_pick_up_limit;
            this.number_pick_down_limit_data = data.number_pick_down_limit;

            this.tree_summary = 0;
            this.tree_tod_summary = 0;
            this.two_summary = 0;
            this.two_down_summary = 0;
            this.two_tod_summary = 0;
            this.four_tod_summary = 0;
            this.five_tod_summary = 0;
            this.number_up_summary = 0;
            this.number_down_summary = 0;
            this.number_pickup_summary = 0;
            this.number_pickdown_summary = 0;

            //alert(this.number_id)


            this.tree_up_data.forEach((result) => {
              this.tree_summary += result.price ? result.price : 0;
              this.tree_tod_summary += result.price_divide ? result.price_divide : 0;
            });

            this.two_up_data.forEach((result) => {
              this.two_summary += result.price ? result.price : 0;
            });

            this.two_down_data.forEach((result) => {
              this.two_down_summary += result.price ? result.price : 0;
            });

            this.two_tod_data.forEach((result) => {
              this.two_tod_summary += result.price ? result.price : 0;
            });

            this.four_tod_data.forEach((result) => {
              this.four_tod_summary += result.price ? result.price : 0;
            });

            this.five_tod_data.forEach((result) => {
              this.five_tod_summary += result.price ? result.price : 0;
            });

            this.number_up_data.forEach((result) => {
              this.number_up_summary += result.price ? result.price : 0;
            });

            this.number_down_data.forEach((result) => {
              this.number_down_summary += result.price ? result.price : 0;
            });

            this.number_pick_up_data.forEach((result) => {
              this.number_pickup_summary += result.price_hundred ? result.price_hundred : 0;
              this.number_pickup_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickup_summary += result.price_unit ? result.price_unit : 0;
            });

            this.number_pick_down_data.forEach((result) => {
              this.number_pickdown_summary += result.price_ten ? result.price_ten : 0;
              this.number_pickdown_summary += result.price_unit ? result.price_unit : 0;
            });


            switch (this.number_id) {
              case "tree_up":


                console.log("============length=============")
                console.log(data.cut_tree_up.length)


                if (data.cut_tree_up.length !== 0) {

                  this.lotto_cut = this.CheckNumberCut(this.tree_up_data, data.cut_tree_up);

                } else {
                  this.lotto_cut = this.tree_up_data;

                }

                this.sales = this.tree_summary;
                this.sales_more_than = this.tree_up_data.length;
                this.sales_no_more_than = (1000 - this.tree_up_data.length);
                this.number_no_sales = (1000 - this.tree_up_data.length);
                this.list_cut = data.cut_list_three_up;
                this.$forceUpdate()
                break;
              case "tree_tod":
                this.lotto_cut = this.tree_tod_data;
                this.sales = this.tree_tod_summary;
                this.list_cut = data.cut_list_three_tod;
                this.$forceUpdate()
                break;
              case "two_up":
                this.lotto_cut = this.two_up_data;
                this.sales = this.two_summary;
                this.list_cut = data.cut_list_two_up;
                this.$forceUpdate()
                break;
              case "two_down":
                this.lotto_cut = this.two_down_data;
                this.sales = this.two_down_summary;
                this.list_cut = data.cut_list_two_down;
                this.$forceUpdate()
                break;
              case "two_tod":
                this.lotto_cut = this.two_tod_data;
                this.sales = this.two_tod_summary;
                this.list_cut = data.cut_list_two_tod;
                this.$forceUpdate()
                break;
              case "four_tod":
                this.lotto_cut = this.four_tod_data;
                this.sales = this.four_tod_summary;
                this.list_cut = data.cut_list_four_tod;
                this.$forceUpdate()
                break;
              case "five_tod":
                this.lotto_cut = this.five_tod_data;
                this.sales = this.five_tod_summary;
                this.list_cut = data.cut_list_five_tod;
                this.$forceUpdate()
                break;
              case "number_up":
                this.lotto_cut = this.number_up_data;
                this.sales = this.number_up_summary;
                this.list_cut = data.cut_list_five_tod;
                this.$forceUpdate()
                break;
              case "number_down":
                this.lotto_cut = this.number_down_data;
                this.sales = this.number_down_summary;
                this.list_cut = data.cut_list_number_down;
                this.$forceUpdate()
                break;
              case "number_pick_up":
                this.lotto_cut = this.number_pick_up_data;
                this.sales = this.number_pickup_summary;
                this.list_cut = data.cut_list_number_up;
                this.$forceUpdate()
                break;
              case "number_pick_down":
                this.lotto_cut = this.number_pick_down_data;
                this.sales = this.number_pickdown_summary;
                this.list_cut = data.cut_number_pick_down;
                this.$forceUpdate()
                break;
              default:
                return true;
            }

            //this.GroupData();
            //this.ChangeNumberPercent();
            //this.is_loading_main = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading_main = false;
            break;
        }

      });

    },
    async GetEmployeeData() {

      let Swal = this.$swal;

      let params = {};

      params.merchant_id = this.lotto.merchant_id


      await GetEmployee(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        let employee = [];

        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            data.forEach(function (result) {
              console.log(result)
              employee.push({
                "label": result.name,
                "id": result.id,
              })

              /*if(index === 0){
                this.employees_app_id = result.id
              }*/

            });

            this.employee_app = employee;

            data.forEach(function (index, result) {
              console.log(result)
              if (index === 0) {
                this.employees_app_id = result.id
              }

            });

            this.is_loading_main = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading_main = false;
            break;
        }

      });


    },
    async GetUserData() {

      let Swal = this.$swal;

      let params = {};

      params.merchant_id = this.lotto.merchant_id


      await GetUsers(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        let users = [];

        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            data.forEach(function (result) {
              console.log(result)
              users.push({
                "label": result.first_name,
                "id": result.id,
              })

            });

            this.users_app = users;


            this.is_loading_main = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading_main = false;
            break;
        }

      });


    },
    ChangeNumberPercent() {

      this.price_pay_emp = 100;
      console.log(this.employees_app_one)

      //this.is_loading_main = false;
      //this.price_percent = 100;
      switch (this.number_id) {
        case "tree_up":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.three_up_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.three_up_pay : 100;
          this.lotto_limit = this.three_up_limit_data;
          this.$forceUpdate()
          break;
        case "tree_tod":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.three_tod_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.three_tod_pay : 100;
          this.lotto_limit = this.three_up_tod_limit_data;
          this.$forceUpdate()
          break;
        case "two_up":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.two_up_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.two_up_pay : 100;
          this.lotto_limit = this.two_up_limit_data;
          this.$forceUpdate()
          break;
        case "two_down":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.two_down_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.two_down_pay : 100;
          this.lotto_limit = this.two_down_limit_data;
          this.$forceUpdate()
          break;
        case "two_tod":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.two_tod_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.two_tod_pay : 100;
          this.lotto_limit = this.two_tod_limit_data;
          this.$forceUpdate()
          break;
        case "four_tod":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.four_tod_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.four_tod_pay : 100;
          this.lotto_limit = this.four_tod_limit_data;
          this.$forceUpdate()
          break;
        case "five_tod":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.five_tod_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.five_tod_pay : 100;
          this.lotto_limit = this.five_tod_limit_data;
          this.$forceUpdate()
          break;
        case "number_up":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.number_up_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.number_up_pay : 100;
          this.lotto_limit = this.number_up_limit_data;
          this.$forceUpdate()
          break;
        case "number_down":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.number_down_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.number_down_pay : 100;
          this.lotto_limit = this.number_down_limit_data;
          this.$forceUpdate()
          break;
        case "number_pick_up":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.number_pick_up_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.number_pick_up_pay : 100;
          this.lotto_limit = this.number_pick_up_limit_data;
          this.$forceUpdate()
          break;
        case "number_pick_down":
          this.price_pay = this.employees_app_id !== "" ? this.employees_app_one.number_down_pay : 100;
          this.price_pay_emp = this.employees_app_id !== "" ? this.employees_app_one.number_down_pay : 100;
          this.lotto_limit = this.number_pick_down_limit_data;
          this.$forceUpdate()
          break;
        default:
          return true;
      }

      //this.price_percent = this.employees_app_one ? ((this.price_pay/this.price_pay_emp)*100) :100;
    },
    async GetEmployeeOneData() {

      let Swal = this.$swal;


      await GetOneEmployee(this.employees_app_id, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)


        console.log(status)
        console.log(data)
        console.log(statusText)

        this.employee_id = this.employees_app_id;

        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;
            this.employees_app_one = data;
            this.is_loading_main = false;
            switch (this.number_id) {
              case "tree_up":
                this.price_pay = data.three_up_pay;
                //this.price_percent = data.three_up;
                break;
              case "tree_tod":
                this.price_pay = data.three_tod_pay;
                //this.price_percent = data.three_tod;
                break;
              case "two_up":
                this.price_pay = data.two_up_pay;
                //this.price_percent = data.two_up;
                break;
              case "two_down":
                this.price_pay = data.two_down_pay;
                //this.price_percent = data.two_down;
                break;
              case "two_tod":
                this.price_pay = data.two_tod_pay;
                //this.price_percent = data.two_tod;
                break;
              case "four_tod":
                this.price_pay = data.four_tod_pay;
                //this.price_percent = data.four_tod;
                break;
              case "five_tod":
                this.price_pay = data.five_tod_pay;
                //this.price_percent = data.five_tod;
                break;
              case "number_up":
                this.price_pay = data.number_up_pay;
                //this.price_percent = data.number_up;
                break;
              case "number_down":
                this.price_pay = data.number_down_pay;
                //this.price_percent = data.number_down;
                break;
              case "number_pick_up":
                this.price_pay = data.number_pick_up_pay;
                //this.price_percent = data.number_pick_up;
                break;
              case "number_pick_down":
                this.price_pay = data.number_down_pay;
                //this.price_percent = data.number_down;
                break;
              default:
                return true;
            }


            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading_main = false;
            break;
        }

      });


    },
    async Print(id) {
      window.open('/reportcut/' + id, '_blank')
    },
    async DeleteCut(id) {

      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          let Swal = this.$swal;

          await DeleteCutNumber(id, (response) => {
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)
                //this.lotto = data;

                this.$toast.open({
                  message: 'ลบสำเร็จ',
                  type: "success",
                  duration: 500,
                  dismissible: true
                })

                this.getLottoNumbers()


                this.is_loading = false;
                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                this.is_loading = false;
                break;
            }

          });

          await this.getLottoNumberCut();
          console.log(id)
        }
      })
    },
    async AddTreeUpLimit(is_close) {
      let Swal = this.$swal

      this.is_loading_main = true;

      if (this.flip_limit === true) {

        if (this.number_limit.length === 3) {

          let number_array = Array.from(String(this.number_limit), Number)
          let number_result = number_suffle_three(number_array);
          console.log(number_result)


          let formData = new FormData();

          formData.append("numbers", number_result);
          formData.append("pay", this.price_pay);
          formData.append("percents", this.price_percent);
          formData.append("is_close", is_close);
          formData.append("type", this.number_id);
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("is_all", (this.all_employee === true).toString());
          if (this.employee_id) {
            formData.append("employee_id", this.employee_id);
          }

          await PostLottoNumberLimit(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });


        }

      } else {


        let formData = new FormData();

        formData.append("numbers", this.number_limit);
        formData.append("pay", this.price_pay);
        formData.append("percents", this.price_percent);
        formData.append("is_close", is_close);
        formData.append("type", this.number_id);
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("is_all", (this.all_employee === true).toString());
        formData.append("merchant_id", this.lotto.merchant_id);
        if (this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }


        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;


            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }

      this.ResetData();
      await this.getLottoNumbers();
    },
    ResetData() {

    },
    async AddTreeUpLimitTod(is_close) {
      let Swal = this.$swal

      this.is_loading_main = true;

      if (this.flip_limit === true) {

        if (this.number_limit.length === 3) {

          let number_array = Array.from(String(this.number_limit), Number)
          let number_result = number_suffle_three(number_array);
          console.log(number_result)


          let formData = new FormData();

          formData.append("numbers", number_result);
          formData.append("pay", this.price_pay);
          formData.append("percents", this.price_percent);
          formData.append("is_close", is_close);
          formData.append("type", this.number_id);
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("is_all", (this.all_employee === true).toString());
          if (this.employee_id) {
            formData.append("employee_id", this.employee_id);
          }

          await PostLottoNumberLimit(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });

        }

      } else {


        let formData = new FormData();

        formData.append("numbers", this.number_limit);
        formData.append("pay", this.price_pay);
        formData.append("percents", this.price_percent);
        formData.append("is_close", is_close);
        formData.append("type", this.number_id);
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("is_all", (this.all_employee === true).toString());
        formData.append("merchant_id", this.lotto.merchant_id);
        if (this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }


        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;


            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }

      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddTwoUpLimit(is_close) {
      this.is_loading_main = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_limit);
      formData.append("pay", this.price_pay);
      formData.append("percents", this.price_percent);
      formData.append("is_close", is_close);
      formData.append("type", this.number_id);
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("is_all", (this.all_employee === true).toString());
      formData.append("merchant_id", this.lotto.merchant_id);
      if (this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      if (this.flip_limit === true) {
        let number_array = Array.from(String(this.number_limit), Number)


        let formData = new FormData();

        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("pay", this.price_pay);
        formData.append("percents", this.price_percent);
        formData.append("is_close", is_close);
        formData.append("type", this.number_id);
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("is_all", (this.all_employee === true).toString());
        formData.append("merchant_id", this.lotto.merchant_id);
        if (this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }

        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }
      this.ResetData();
      await this.getLottoNumbers();
    },
    CheckNumberCut(number, number_cut) {


      let data_number = [];
      let data = [];

      number.forEach(function (result) {
        data_number.push({
          "count": result.count,
          "numbers": result.numbers,
          "price": result.price,
        })
      })

      console.log("======CheckNumberCut=====")
      console.log(data_number)
      console.log(number_cut)

      data_number.forEach(function (result) {
        const find = number_cut.filter(cut => cut.numbers === result.numbers)
        console.log("======Find=====")
        console.log(find)

        if (find !== undefined) {
          let cut_price = 0
          find.forEach(item => {
            cut_price += item.cut_price;
          });

          console.log("======CutPrice=====")
          console.log(cut_price)

          data.push({
            "count": 0,
            "numbers": result.numbers,
            "price": (result.price - cut_price),
          })
        }

      })


      console.log(data)
      return data;
    },
    async AddTwoDownLimit(is_close) {
      this.is_loading_main = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_limit);
      formData.append("pay", this.price_pay);
      formData.append("percents", this.price_percent);
      formData.append("is_close", is_close);
      formData.append("type", this.number_id);
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("is_all", (this.all_employee === true).toString());
      formData.append("merchant_id", this.lotto.merchant_id);
      if (this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      if (this.flip_limit === true) {
        let number_array = Array.from(String(this.number_limit), Number)


        let formData = new FormData();

        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("pay", this.price_pay);
        formData.append("percents", this.price_percent);
        formData.append("is_close", is_close);
        formData.append("type", this.number_id);
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("is_all", (this.all_employee === true).toString());
        formData.append("merchant_id", this.lotto.merchant_id);
        if (this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }

        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }
      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddTwoTodLimit(is_close) {
      this.is_loading_main = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_limit);
      formData.append("pay", this.price_pay);
      formData.append("percents", this.price_percent);
      formData.append("is_close", is_close);
      formData.append("type", this.number_id);
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("is_all", (this.all_employee === true).toString());
      formData.append("merchant_id", this.lotto.merchant_id);
      if (this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      if (this.two_up_limit_flip === true) {
        let number_array = Array.from(String(this.number_limit), Number)
        let formData = new FormData();
        formData.append("numbers", number_array[1] + "" + number_array[0]);
        formData.append("pay", this.price_pay);
        formData.append("percents", this.price_percent);
        formData.append("is_close", is_close);
        formData.append("type", this.number_id);
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("is_all", (this.all_employee === true).toString());
        formData.append("merchant_id", this.lotto.merchant_id);
        if (this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }

        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;

            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }
      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddFourTodLimit(is_close) {
      let Swal = this.$swal

      this.is_loading_main = true;

      if (this.flip_limit === true) {

        if (this.number_limit.length === 4) {

          let number_array = Array.from(String(this.number_limit), Number)
          let number_result = number_suffle_four(number_array);
          console.log(number_result)


          let formData = new FormData();

          formData.append("numbers", number_result);
          formData.append("pay", this.price_pay);
          formData.append("percents", this.price_percent);
          formData.append("is_close", is_close);
          formData.append("type", this.number_id);
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("is_all", (this.all_employee === true).toString());
          if (this.employee_id) {
            formData.append("employee_id", this.employee_id);
          }

          await PostLottoNumberLimit(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });

        }

      } else {


        let formData = new FormData();

        formData.append("numbers", this.number_limit);
        formData.append("pay", this.price_pay);
        formData.append("percents", this.price_percent);
        formData.append("is_close", is_close);
        formData.append("type", this.number_id);
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("is_all", (this.all_employee === true).toString());
        formData.append("merchant_id", this.lotto.merchant_id);
        if (this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }


        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;


            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }

      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddFiveTodLimit(is_close) {
      let Swal = this.$swal

      this.is_loading_main = true;

      if (this.flip_limit === true) {

        if (this.number_limit.length === 5) {

          let number_array = Array.from(String(this.number_limit), Number)
          let number_result = number_suffle_five(number_array);
          console.log(number_result)


          let formData = new FormData();

          formData.append("numbers", number_result);
          formData.append("pay", this.price_pay);
          formData.append("percents", this.price_percent);
          formData.append("is_close", is_close);
          formData.append("type", this.number_id);
          formData.append("merchant_id", this.lotto.merchant_id);
          formData.append("lottery_id", this.lotto.id);
          formData.append("is_all", (this.all_employee === true).toString());
          if (this.employee_id) {
            formData.append("employee_id", this.employee_id);
          }

          await PostLottoNumberLimit(formData, (response) => {
            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :
                console.log(data)

                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }

          });

        }

      } else {


        let formData = new FormData();

        formData.append("numbers", this.number_limit);
        formData.append("pay", this.price_pay);
        formData.append("percents", this.price_percent);
        formData.append("is_close", is_close);
        formData.append("type", this.number_id);
        formData.append("merchant_id", this.lotto.merchant_id);
        formData.append("lottery_id", this.lotto.id);
        formData.append("is_all", (this.all_employee === true).toString());
        formData.append("merchant_id", this.lotto.merchant_id);
        if (this.employee_id) {
          formData.append("employee_id", this.employee_id);
        }


        await PostLottoNumberLimit(formData, (response) => {
          console.log(response)
          const {status, data, statusText} = ResponseData(response)

          switch (status) {
            case 200 :
              console.log(data)
              //this.tree_up_data = data;
              break;


            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

                this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

              break;


            default:
              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })
              break;
          }

        });


      }

      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddNumberUpLimit(is_close) {
      this.is_loading_main = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_limit);
      formData.append("pay", this.price_pay);
      formData.append("percents", this.price_percent);
      formData.append("is_close", is_close);
      formData.append("type", this.number_id);
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("is_all", (this.all_employee === true).toString());
      formData.append("merchant_id", this.lotto.merchant_id);
      if (this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddNumberDownLimit(is_close) {
      this.is_loading_main = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_limit);
      formData.append("pay", this.price_pay);
      formData.append("percents", this.price_percent);
      formData.append("is_close", is_close);
      formData.append("type", this.number_id);
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("is_all", (this.all_employee === true).toString());
      formData.append("merchant_id", this.lotto.merchant_id);
      if (this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddNumberPickUpLimit(is_close) {
      this.is_loading_main = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_limit);
      formData.append("pay", this.price_pay);
      formData.append("percents", this.price_percent);
      formData.append("is_close", is_close);
      formData.append("type", this.number_id);
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("is_all", (this.all_employee === true).toString());
      if (this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumbers();
    },
    async AddNumberPickDownLimit(is_close) {
      this.is_loading_main = true;
      let Swal = this.$swal

      // let data = this.two_up_data;


      let formData = new FormData();

      formData.append("numbers", this.number_limit);
      formData.append("pay", this.price_pay);
      formData.append("percents", this.price_percent);
      formData.append("is_close", is_close);
      formData.append("type", this.number_id);
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("is_all", (this.all_employee === true).toString());
      if (this.employee_id) {
        formData.append("employee_id", this.employee_id);
      }

      await PostLottoNumberLimit(formData, (response) => {
        console.log(response)

        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.tree_up_data = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


      this.ResetData();
      await this.getLottoNumbers();
    },
    CheckPayLimit() {
      const value = event.target.value
      console.log("==========CheckPayLimit===============")
      console.log(value)

      this.price_pay = value;
      this.$forceUpdate()
      console.log(parseInt(this.price_pay) > parseInt(this.price_pay_emp))

      if (parseInt(this.price_pay) > parseInt(this.price_pay_emp) && this.price_pay_emp) {
        alert("จำนวนจ่ายเกินจากตั้งค่า")
        this.price_pay = this.price_pay_emp;
        this.$forceUpdate()
      }

      this.$forceUpdate()
    },

    async getLotto() {
      let Swal = this.$swal;
      this.is_loading_main = true;


      await GetLotto(this.users.merchant_id, async (response) => {


        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)

        this.lotto = []

        switch (status) {
          case 200 :

            console.log("=============GETLOTTO============")
            console.log(this.lotto)

            this.is_loading_main = false;
            if (data.date_start) {
              this.lotto = data;

              this.date_start = data.date_start;
              this.date_end = data.date_end;
              this.time_start = data.time_start;
              this.time_end = data.time_end;
              if (data.is_open == 'false') {
                this.is_open = false;
              } else {
                this.is_open = true;
              }

              if (data.is_edit == 'false') {
                this.is_edit = false;
              } else {
                this.is_edit = true;
              }


              //this.is_edit = data.is_edit;


              await this.getLottoNumbers();
            }
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });
    },

    CheckThree(data) {
      console.log("==========CheckThree=========")

      let three_tod_numbers = [];

      data.forEach((result) => {


        three_tod_numbers.push({
          "numbers": result.numbers,
          "price": result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let three_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find
        //let find_tod_suffle

        if (three_numbers_sort.length !== 0) {
          //let number_array = Array.from(String(result.numbers), Number)
          find = three_numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if (find) {
          find.price += result.price;
          find.count = find.count++;
        } else {
          three_numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price,
            "count": 0,
          })
        }


      });

      console.log(three_numbers_sort);
      console.log("==========CheckThree=========")

      return three_numbers_sort;
    },
    CheckThreeTod(data) {
      console.log("==========CheckThreeTod=========")

      let three_tod_numbers = [];

      data.forEach((result) => {


        three_tod_numbers.push({
          "numbers": result.numbers,
          "price_divide": result.price_divide,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let three_tod_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find_tod
        let find_tod_suffle

        if (three_tod_numbers_sort.length !== 0) {
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_three(number_array);

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        console.log(find_tod)

        find_tod_suffle = "";

        if (find_tod) {
          find_tod.price_divide += result.price_divide;
          find_tod.count = find_tod.count++;
        } else if (find_tod_suffle) {
          find_tod_suffle.price_divide += result.price_divide;
        } else {
          three_tod_numbers_sort.push({
            "numbers": result.numbers,
            "price_divide": result.price_divide,
            "count": 0
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckThreeTod=========")
      return three_tod_numbers_sort;
    },
    CheckTwo(data) {
      console.log("==========CheckTwo=========")

      let two_numbers = [];

      data.forEach((result) => {


        two_numbers.push({
          "numbers": result.numbers,
          "price": result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = two_numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let two_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find
        //let find_tod_suffle

        if (two_numbers_sort.length !== 0) {
          //let number_array = Array.from(String(result.numbers), Number)
          find = two_numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if (find) {
          find.price += result.price;
          find.count = find.count++;
        } else {
          two_numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price,
            "count": 0,
          })
        }


      });

      console.log(two_numbers_sort);
      console.log("==========CheckTwo=========")

      return two_numbers_sort;
    },
    CheckTwoTod(data) {
      console.log("==========CheckTwo=========")

      let two_numbers = [];

      data.forEach((result) => {


        two_numbers.push({
          "numbers": result.numbers,
          "price": result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = two_numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let two_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find
        let find_tod_suffle

        if (two_numbers_sort.length !== 0) {
          let number_array = Array.from(String(result.numbers), Number)
          find = two_numbers_sort.find(tod => tod.numbers === result.numbers);
          let number_result = [number_array[0] + "" + number_array[1], number_array[1] + "" + number_array[0]]
          find_tod_suffle = two_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        find_tod_suffle = "";

        if (find) {
          find.price += result.price;
          find.count = find.count++;
        } else if (find_tod_suffle) {
          find_tod_suffle.price += result.price;
        } else {
          two_numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price,
            "count": 0,
          })
        }


      });

      console.log(two_numbers_sort);
      console.log("==========CheckTwo=========")

      return two_numbers_sort;
    },
    CheckFourTod(data) {
      console.log("==========CheckFourTod=========")

      let three_tod_numbers = [];

      data.forEach((result) => {


        three_tod_numbers.push({
          "numbers": result.numbers,
          "price": result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let three_tod_numbers_sort = [];

      console.log("=======sort_number=======");
      console.log(sort_number);
      console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find_tod
        let find_tod_suffle

        if (three_tod_numbers_sort.length !== 0) {
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_four(number_array);

          console.log("========suffle========")
          console.log(number_result)
          console.log("========suffle========")

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        find_tod_suffle = ""

        console.log(find_tod)

        if (find_tod) {
          find_tod.price += result.price;
          find_tod.count = find_tod.count++;
        } else if (find_tod_suffle) {
          find_tod_suffle.price += result.price;
        } else {
          three_tod_numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price,
            "count": 0,
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckFourTod=========")
      return three_tod_numbers_sort;
    },
    CheckFiveTod(data) {
      console.log("==========CheckFiveTod=========")

      let three_tod_numbers = [];

      data.forEach((result) => {


        three_tod_numbers.push({
          "numbers": result.numbers,
          "price": result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let three_tod_numbers_sort = [];

      console.log("=======sort_number=======");
      console.log(sort_number);
      console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find_tod
        let find_tod_suffle

        if (three_tod_numbers_sort.length !== 0) {
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_five(number_array);

          console.log("========suffle========")
          console.log(number_result)
          console.log("========suffle========")

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        find_tod_suffle = ""

        console.log(find_tod)

        if (find_tod) {
          find_tod.price += result.price;
          find_tod.count = find_tod.count++;
        } else if (find_tod_suffle) {
          find_tod_suffle.price += result.price;
        } else {
          three_tod_numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price,
            "count": 0,
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckFiveTod=========")
      return three_tod_numbers_sort;
    },
    CheckNumber(data) {
      console.log("==========CheckNumberUp=========")

      let numbers = [];

      data.forEach((result) => {


        numbers.push({
          "numbers": result.numbers,
          "price": result.price,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find
        //let find_tod_suffle

        if (numbers_sort.length !== 0) {
          //let number_array = Array.from(String(result.numbers), Number)
          find = numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if (find) {
          find.price += result.price;
          find.count = find.count++;
        } else {
          numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price,
            "count": 0,
          })
        }


      });

      console.log(numbers_sort);
      console.log("==========CheckNumberUp=========")

      return numbers_sort;
    },
    CheckNumberPickUp(data) {
      console.log("==========CheckNumberUp=========")

      let numbers = [];

      data.forEach((result) => {


        numbers.push({
          "numbers": result.numbers,
          "price": result.price_hundred,
          "price_hundred": result.price_hundred,
          "price_ten": result.price_ten,
          "price_unit": result.price_unit,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find
        //let find_tod_suffle

        if (numbers_sort.length !== 0) {
          //let number_array = Array.from(String(result.numbers), Number)
          find = numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if (find) {
          find.price_hundred += result.price_hundred;
          find.price += result.price_hundred;
          find.price_ten += result.price_ten;
          find.price_unit += result.price_unit;
          find.count = find.count++;
        } else {
          numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price_hundred,
            "count": 0,
            "price_hundred": result.price_hundred,
            "price_ten": result.price_ten,
            "price_unit": result.price_unit,
          })
        }


      });

      console.log(numbers_sort);
      console.log("==========CheckNumberUp=========")

      return numbers_sort;
    },
    CheckNumberPickDown(data) {
      console.log("==========CheckNumberUp=========")

      let numbers = [];

      data.forEach((result) => {


        numbers.push({
          "numbers": result.numbers,
          "price": result.price_ten,
          "price_ten": result.price_ten,
          "price_unit": result.price_unit,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = numbers.sort(function (a, b) {
        return a.numbers - b.numbers
      })

      let numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result) => {

        console.log(result)

        let find
        //let find_tod_suffle

        if (numbers_sort.length !== 0) {
          //let number_array = Array.from(String(result.numbers), Number)
          find = numbers_sort.find(tod => tod.numbers === result.numbers);
          //find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        //console.log(find_tod)

        if (find) {
          find.price += result.price_ten;
          find.count = find.count++;
          find.price_ten += result.price_ten;
          find.price_unit += result.price_unit;
        } else {
          numbers_sort.push({
            "numbers": result.numbers,
            "price": result.price_ten,
            "count": 0,
            "price_ten": result.price_ten,
            "price_unit": result.price_unit,
          })
        }


      });

      console.log(numbers_sort);
      console.log("==========CheckNumberUp=========")

      return numbers_sort;
    },
    CutWait() {

      let sales_wait_send = 0;
      const data = [];
      const give_number = this.give_number;
      const number_id = this.number_id;
      this.summary_cut_wait = 0;


      this.lotto_cut.forEach((result) => {
        console.log(this.give_number + " - " + result.price)

        if (this.give_number < parseInt(result.price)) {

          data.push({
            numbers: result.numbers,
            price: parseInt(result.price - this.give_number),
            type: number_id,
          })


          sales_wait_send += parseInt(result.price - give_number);

        }


      });


      this.sales_wait_send = sales_wait_send;
      this.summary_cut = sales_wait_send;

      this.lotto_cut_wait = data;

    },
    ClearCutWait() {
      this.lotto_cut_wait = [];
    },
    RemoveCutSelect() {

      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.lotto_cut_wait = this.Splics(this.index_cut, this.lotto_cut_wait);
          this.index_cut = [];
          let sales_wait_send = 0;


          this.lotto_cut_wait.forEach(function (result) {
            sales_wait_send += parseInt(result.price);
          });

          this.sales_wait_send = sales_wait_send;

        }
      })

    },
    Splics(cut_index, wait_number) {


      console.log(cut_index)

      let data = [];
      wait_number.forEach(function (result, index) {

        let find = cut_index.find((results) => index === results)
        if (find === undefined) {
          data.push(result);
        }
        console.log(find)
        console.log(result)
      });

      return data;
    },
    SplicsGive(cut_index, wait_number) {

      let find = [];

      cut_index.forEach(function (result) {
        find.push(wait_number.find((results, index) => index === result))

      });


      return find;
    },
    async SubmitSend() {
      const number_give = this.SplicsGive(this.index_cut, this.lotto_cut_wait);

      let Swal = this.$swal

      let formData = new FormData();

      formData.append("numbers", JSON.stringify(number_give));
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("price", this.sales_wait_send);
      formData.append("type", this.number_id);
      formData.append("cut_price", this.give_number);


      await PostCutNumber(formData, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)

            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }


      });

      await this.getLottoNumberCut();

    },
    async SubmitSendWait() {

      //const number_give = this.SplicsGive(this.index_cut,this.lotto_cut_wait);

      let Swal = this.$swal

      let formData = new FormData();

      formData.append("numbers", JSON.stringify(this.lotto_cut_wait));
      formData.append("merchant_id", this.lotto.merchant_id);
      formData.append("lottery_id", this.lotto.id);
      formData.append("users_id", this.users_id);
      formData.append("price", this.sales_wait_send);
      formData.append("type", this.number_id);
      formData.append("cut_price", this.give_number);


      await PostCutNumber(formData, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            //this.$router.push({ path: '/reportcut/'+data})
            window.open('/reportcut/' + data, '_blank')

            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }


      });

      await this.getLottoNumberCut();

    },
    async submitAward() {


      let Swal = this.$swal

      const award_1 = this.award_number1;
      let award_number1_array = Array.from(String(this.award_number1), Number)
      const award_three_up = award_number1_array[3] + "" + award_number1_array[4] + "" + award_number1_array[5];
      const award_three_tod = (number_suffle_three(award_three_up)).toString();
      const award_three_down = [this.award_number3_back_1, this.award_number3_back_2, this.award_number3_back_3, this.award_number3_back_4].toString();
      const award_three_front = [this.award_number3_front_1, this.award_number3_front_2].toString();
      const award_two_up = award_number1_array[4] + "" + award_number1_array[5];
      const award_three_array = Array.from(String(award_three_up, Number));
      const award_two_tod = [award_three_array[0] + "" + award_three_array[1], award_three_array[0] + "" + award_three_array[2], award_three_array[1] + "" + award_three_array[0], award_three_array[1] + "" + award_three_array[2], award_three_array[2] + "" + award_three_array[0], award_three_array[2] + "" + award_three_array[1]].toString();
      const award_two_down = this.award_number2;
      const award_run_up = [award_number1_array[0], award_number1_array[1], award_number1_array[2]].toString();
      let award_number2_array = Array.from(String(this.award_number2), Number)
      const award_run_down = [award_number2_array[0], award_number2_array[1]].toString();


      console.log(award_1)
      console.log(award_three_up)
      console.log(award_three_tod)
      console.log(award_three_down)
      console.log(award_three_front)
      console.log(award_two_up)
      console.log(award_two_tod)
      console.log(award_two_down)
      console.log(award_run_up)
      console.log(award_run_down)

      let formData = new FormData();

      formData.append("award_1", award_1);
      formData.append("award_three_up", award_three_up);
      formData.append("award_three_tod", award_three_tod);
      formData.append("award_three_down", award_three_down);
      formData.append("award_three_front", award_three_front);
      formData.append("award_two_up", award_two_up);
      formData.append("award_two_tod", award_two_tod);
      formData.append("award_two_down", award_two_down);
      formData.append("award_run_up", award_run_up);
      formData.append("award_run_down", award_run_down);
      formData.append("lottery_id", this.lotto.id);
      formData.append("merchant_id", this.lotto.merchant_id);


      await PostAward(formData, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)


            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });


    },

    CheckStartHour(event) {
      const value = event.target.value
      console.log(value)
      if (isNaN(value)) {
        this.start_hour = "-"
        console.log("if")
      } else {
        console.log("else")
        if (value >= 0 && value < 24) {
          this.start_hour = value
          this.time_start = String(this.start_hour).padStart(2, '0') + ":" + String(this.start_minute).padStart(2, '0');
        }

      }

      this.$forceUpdate()
    },
    CheckStartMinute() {

      const value = event.target.value
      console.log(value)
      if (isNaN(value)) {
        this.start_minute = "-"
        console.log("if")
      } else {
        console.log("else")
        if (value >= 0 && value <= 60) {
          this.start_minute = value
          this.time_start = String(this.start_hour).padStart(2, '0') + ":" + String(this.start_minute).padStart(2, '0');
        }

      }

      this.$forceUpdate()

    },
    CheckEndHour() {
      const value = event.target.value
      console.log(value)
      if (isNaN(value)) {
        this.end_hour = "-"
        console.log("if")
      } else {
        console.log("else")
        if (value >= 0 && value < 24) {
          this.end_hour = value
          this.time_end = String(this.end_hour).padStart(2, '0') + ":" + String(this.end_minute).padStart(2, '0');
        }

      }

      this.$forceUpdate()
    },
    CheckEndMinute() {
      const value = event.target.value
      console.log(value)
      if (isNaN(value)) {
        this.end_minute = "-"
        console.log("if")
      } else {
        console.log("else")
        if (value >= 0 && value < 24) {
          this.end_minute = value
          this.time_end = String(this.end_hour).padStart(2, '0') + ":" + String(this.end_minute).padStart(2, '0');
        }

      }

      this.$forceUpdate()
    },
    async IsOpen() {

      let Swal = this.$swal;
      const formData = new FormData();
      formData.append("id", this.lotto.id)
      formData.append("is_open", this.is_open)
      formData.append("_method", "PUT")

      await UpdateLotto(this.lotto.id, formData, (response) => {

        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            this.lotto = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }


      });


    },
    async IsEdit() {

      let Swal = this.$swal;
      const formData = new FormData();
      formData.append("is_edit", this.is_edit)
      formData.append("_method", "PUT")

      await UpdateLotto(this.lotto.id, formData, (response) => {

        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        switch (status) {
          case 200 :
            console.log(data)
            this.lotto = data;
            break;


          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }


      });


    },
    async CloseLotto() {

      let Swal = this.$swal;


      Swal({
        title: 'แจ้งเตือน',
        text: 'คุณต้องการปิดงวดใช่หรือไม่ ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ดำเนินการต่อ',
        cancelButtonText: 'ไม่ ยกเลิก'
      }).then(async (result) => {
        if (result.value) {

          const formData = new FormData();
          formData.append("finish", 'true')
          formData.append("_method", "PUT")

          await UpdateLotto(this.lotto.id, formData, async (response) => {

            console.log(response)
            const {status, data, statusText} = ResponseData(response)

            switch (status) {
              case 200 :


                await this.getLotto()
                this.dialog = false;
                window.location.reload();
                break;


              case 422 :

                for (const [key, value] of Object.entries(data)) {
                  console.log(`${key}: ${value}`);

                  this.$toast.open({
                    message: `${value}`,
                    type: "warning",
                    duration: 10000,
                    dismissible: true
                  })

                }

                break;


              default:
                Swal({
                  icon: "error",
                  title: statusText,
                  text: data.message,
                })
                break;
            }


          });

        }


      })

    },
  },
  computed: {
    selectAll: {
      get: function () {
        return this.lotto_cut_wait ? this.index_cut.length == this.lotto_cut_wait.length : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.lotto_cut_wait.forEach(function (result, index) {
            console.log(index)
            selected.push(index);
          });
        }

        this.index_cut = selected;
      }
    }
  },
  async mounted() {
    this.CheckLogin()
    await this.GetEmployeeData()
    await this.GetUserData()
    await this.getLotto()
    //await this.getLottoNumbers()
    this.is_loading_main = false;
  },
  created() {
    //console.log(process.env)
  },

};
</script>
<style>
.footer_custom {
  justify-content: center;
}

.v-toolbar__content {
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}

.date_time {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.date_time_font {
  color: #000000;
  font-size: 1.2rem;
}

.table_custom_limit {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #8f8f8f;
  font-size: 1.2rem;
  margin-top: 1.5rem;
}

.table_custom_limit th {
  border: 1px solid #EBEDF3;
  font-weight: 600 !important;
  padding-block: 5px;
  background: #0686E8;
  color: #EBEDF3;
}

.table_custom_limit td {
  border: 1px solid #8f8f8f;
  font-weight: 500 !important;
  padding-left: 0.3rem;
}



.v-input--selection-controls__input {
  top: 1px;
  border: 1px solid #000000;
}

.v-text-field-limit {
  padding-top: 0 !important;
  margin-top: 0 !important;
  width: 100%;
  margin-left: 1rem;
  font-size: 1.2rem;
}

.row-form {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.col-custom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.btn_limit {
  width: 100%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #0686E8;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}

.btn_limit:disabled {
  opacity: 0.4;
}

.delete_btn {
  background: red;
}

.delete_btn_cut {
  background: none;
  color: red !important;
}

.close_btn {
  background: red;
  padding-block: 20px !important;
  padding-inline: 20px !important;
}

.close_lotto {
  background: #03c707;
  padding-block: 20px !important;
  padding-inline: 20px !important;
  color: white !important;
}

.close_lotto span {
  color: #EBEDF3 !important;
  font-weight: bold;
}

.open_lotto {
  background: #0306c7;
  padding-block: 20px !important;
  padding-inline: 20px !important;
  color: white !important;
}

.open_lotto span {
  color: #EBEDF3 !important;
  font-weight: bold;
}

.close_btn span {
  color: #EBEDF3 !important;
  font-weight: bold;
}

.loading_image_main {
  margin-block: -10rem;
}

.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}

.v-input__slot {
  display: flex !important;
  justify-content: center;
  position: relative !important;
  top: 7px;
}

.is_close_row {
  background: #ff2222;
}

.is_close_row td {
  color: #ffffff;
  border: solid 1px #ffffff;
}

.table_container {
  height: 40rem;
  overflow-y: scroll;
}

.list_label {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
}

.head_font {
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

.button_list {
  display: flex;
  width: 100%;
  gap: 3px;
  margin-top: 1rem;
}

.btn_del_cut {
  width: 40%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #e80606;
  margin: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 5px;
}

.btn_del_cut_full {
  width: 100%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #e80606;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}

.btn_del_cut_all {
  width: 50%;
  padding: 10px;
  padding-block: 10px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #e80606;
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 5px;
}

.btn_send {
  width: 40%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #0686E8;
  margin: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 5px;
}

.center_list {
  justify-content: center;
}

.input-custom {
  border: 1px solid #c7c7c7;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin: 0.2rem;
}

.form-custom {
  display: flex;
  justify-content: center
}

.container-button-list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.btn_submit {
  font-size: 1.5rem;
  color: #EBEDF3 !important;
  background: #0686E8;
  padding: 0.5rem;
  padding-inline: 3rem;
}

.error-text {
  color: #ff0000;
  font-size: 1.2rem;
}

.list_award {
  display: flex;
  font-size: 1.4rem;
  gap: 20px;
  font-weight: bold;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.list_award .label {
  font-weight: 400;
}

.list_award font:first-child {
  width: 10rem;
  text-align: left;
}

.list_award font:last-child {
  width: 20rem;
  text-align: left;
}

.container-list-award {
  width: 30rem !important;
}

.list_type_cut {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.cut_type_btn {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  margin-inline: 0.2rem;
  background: #0686E8;
  border-radius: 10px;
  color: #EBEDF3;
  background: #0686E8;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}

.ip_cut {
  width: 5rem;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.74);
  font-weight: normal;
}

.row_custom {
  margin-block: -2px !important;
}

.print-icon {
  color: #0686E8 !important;
  font-size: 3rem;
  padding: 1rem;
  padding-bottom: 0;
}

.table-custom-container {
  height: 30rem;
  width: 100%;
  overflow-y: scroll;
}

.time_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.time_ip {
  width: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.64);
  font-size: 1.2rem;
  text-align: center;
}

.time_ip:focus-visible {
  width: 50%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.64);
  border-top: none !important;
  font-size: 1.2rem;
  text-align: center;
}

.custom_switch {
  border: none !important;
}

.theme--light.v-label {
  color: black !important;
}

.custom_switch .v-input__control .v-input__slot .v-input--selection-controls__input {
  border: none !important;
}
</style>

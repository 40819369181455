<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>

    <div class="card_container" v-if="lotto.date_start">
      <div class="card_header">
        <v-row>
          <v-col xl="8" lg="8" md="12" sm="12" xs="12"  >
            <div class="header_print" >รายงานการขายงวดประจำวันที่ {{ lotto.date_start }} ถึง {{ lotto.date_end }}</div>
          </v-col>
          <v-col xl="4" lg="4" md="12" sm="12" xs="12"  >
            <v-select
                v-if="users.type === 'user'"
                v-model="employee_id"
                :items="employee"
                item-text="label"
                item-value="id"
                label="ข้อมูลพนักงาน"
                v-on:change="getLottoNumber(),GetEmployeeOneData()"
            ></v-select>
          </v-col>
          <v-row>
            <img class="loading_image_main" v-if="is_loading === true" src="./../../assets/image/loading.svg">
          </v-row>
        </v-row>
      </div>

      <v-row>
        <div class="print-icon-contrainer" align="right" >
          <v-icon v-on:click="OnPrint()" class="print-icon" aria-hidden="false">
            mdi-printer
          </v-icon>
        </div>
      </v-row>

      <v-row>
        <v-col>
          <h2 class="header_top_report" align="center" >เลข 3 ตัว</h2>
        </v-col>
      </v-row>
      <v-row>

          <div class="page_print" >
            <div class="list-container-profit-report profit-text-head" >
              <div>กำไร</div>
              <div>{{ this.all_won != 0 ? (this.total - this.all_won) : this.total }}</div>
              <div>บาท</div>
            </div>
            <div class="list-container-profit-report font_size_1 mt-2" >
              <div>
                ยอดขายรวม<br>
                {{ this.all_total }}
              </div>
              <div>
                % ขายรวม<br>
                {{ this.all_total - this.total }}
              </div>
              <div>
                คงเหลือ<br>
                {{  this.total }}
              </div>
              <div>
                ยอดถูกรวม<br>
                {{ this.all_won }}
              </div>
              <div>
                ยอดสุทธิ<br>
                {{ this.all_won != 0 ? (this.total - this.all_won) : this.total }}
              </div>
            </div>
            <hr>
            <div class="list-container-profit-report font_size_1 mt-2" >
              <div>
                ยอดส่งรวม<br>
                {{ this.cut_total }}
              </div>
              <div>
                % ส่งรวม<br>
                -
              </div>
              <div>
                คงเหลือ<br>
                {{  (this.total-this.cut_total) }}
              </div>
              <div>
                ยอดถูกรวม<br>
                {{ this.all_won-this.cut_total }}
              </div>
              <div>
                ยอดสุทธิ<br>
                0
              </div>
            </div>
            <table class="print-table" border="1" >
              <tr>
                <th align="left" colspan="6" >รายการสรุปยอดลูกค้า</th>
              </tr>
              <tr class="row-table" >
                <th>ชื่อ</th>
                <th>ยอดขาย-ส่ง</th>
                <th>เปอร์เซ็น</th>
                <th>เหลือ</th>
                <th>ยอดถูก</th>
                <th>ยอดสุทธิ</th>
              </tr>
              <tr v-for="(result) in all_data" v-bind:key="result.id" >
                <td>{{ result.employee.name }}</td>
                <td>{{ result.all_total }}</td>
                <td>{{  (result.all_total-result.total) }}</td>
                <td>{{ result.total }}</td>
                <td>{{ result.all_won }}</td>
                <td>{{ result.all_won != 0 ? (result.total - result.all_won) : result.total }}</td>
              </tr>
              <tr>

              </tr>
            </table>

          </div>
      </v-row>




    </div>
    <div class="card_container" v-else>
      <div align="center" class="pt-5 pb-5">
        <h1>ไม่พบข้อมูลงวดกรุณาเริ่มงวดใหม่</h1>
      </div>
    </div>
  </v-breadcrumbs>
</template>

<script>
import {
  GetEmployee,
  GetLotto, GetLottoNumber,
  GetLottoNumberProfit,
  GetOneEmployee,
} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from "jquery";
import {number_suffle_three, ResponseData} from "../../lib/utility";


export default {
  name: "Users",
  data: () => ({
    baseUrl: baseUrl,
    valid: true,
    edit: false,
    id: false,

    day_:"",
    hour:"",
    minute:"",
    secound:"",



    tree_up_data: [],
    tree_up_data_group: [],
    tree_tod_data: [],
    tree_tod_data_group: [],
    two_up_data: [],
    two_up_data_group: [],
    two_down_data: [],
    two_down_data_group: [],
    two_tod_data: [],
    two_tod_data_group: [],
    four_tod_data: [],
    four_tod_data_group: [],
    five_tod_data: [],
    five_tod_data_group: [],
    number_up_data: [],
    number_up_data_group: [],
    number_down_data: [],
    number_down_data_group: [],
    number_pick_up_data: [],
    number_pick_up_data_group: [],
    number_pick_down_data: [],
    number_pick_down_data_group: [],
    three_up_limit_data: [],
    two_up_limit_data: [],
    two_down_limit_data: [],
    two_tod_limit_data: [],
    four_tod_limit_data: [],
    five_tod_limit_data: [],
    number_up_limit_data: [],
    number_down_limit_data: [],
    number_pick_up_limit_data: [],
    number_pick_down_limit_data: [],
    lotto: [],
    sum_price: [],
    users: {},
    employees: {},
    employee: {},
    machine_id: 0,
    orders: 0,
    title: "",
    employee_id: "",
    sum_percent:0,
    sum_total:0,
    all_data:0,

    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลแผนก',
        disabled: true,
        href: '/Department',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปชิ้นงาน',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'title'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],
    is_loading:false,
    remaining:{},
    all_total:0,
    total:0,
    all_won:0,
    cut_three_tod_total:0,
    cut_three_up_total:0,
    cut_two_up_total:0,
    cut_two_down_total:0,
    cut_two_tod_total:0,
    cut_five_tod_total:0,
    cut_four_tod_total:0,
    cut_number_down_total:0,
    cut_number_pick_down_total:0,
    cut_number_pick_up_total:0,
    cut_number_up_total:0,
    cut_total:0,


  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {},

  async created() {
    this.CheckLogin()
    await this.getLotto()
    await this.getLottoNumber()
    await this.getLottoNumberProfit()
    await this.GetEmployeeData()
  },

  methods: {

    async getLotto() {
      let Swal = this.$swal;
      this.is_loading = true;
      await GetLotto(this.users.merchant_id, (response) => {

        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            this.lotto = data;
            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });
    },
    async GetEmployeeOneData(){

      let Swal = this.$swal;


      await GetOneEmployee(this.employee_id, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)


        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;
            this.employees = data;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    async getLottoNumberProfit() {

      let Swal = this.$swal;
      this.is_loading = true;

      const params = {}

      params.merchant_id = this.lotto.merchant_id
      params.lottery_id = this.lotto.id
      if (this.employee_id !== "") {
        params.employee_id = this.employee_id
      }


      await GetLottoNumberProfit(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)
        console.log(statusText)


        let all_data = [];

        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            console.log("=========lotto===========")
            console.log(this.lotto.award.award_three_up)
            console.log("=========lotto===========")


            data.forEach((result)=>{

              let tree_summary = 0;
              let tree_tod_summary = 0;
              let two_summary = 0;
              let two_down_summary = 0;
              let two_tod_summary = 0;
              let four_tod_summary = 0;
              let five_tod_summary = 0;
              let number_up_summary = 0;
              let number_down_summary = 0;
              let number_pickup_summary = 0;
              let number_pickdown_summary = 0;

              let tree_percent_summary = 0;
              let tree_tod_percent_summary = 0;
              let two_percent_summary = 0;
              let two_down_percent_summary = 0;
              let two_tod_percent_summary = 0;
              let four_tod_percent_summary = 0;
              let five_tod_percent_summary = 0;
              let number_up_percent_summary = 0;
              let number_down_percent_summary = 0;
              let number_pickup_percent_summary = 0;
              let number_pickdown_percent_summary = 0;


              let tree_won = 0;
              let tree_tod_won = 0;
              let two_up_won = 0;
              let two_down_won = 0;
              let two_tod_won = 0;
              let four_tod_won = 0;
              let five_tod_won = 0;
              let number_up_won = 0;
              let number_down_won = 0;
              let number_pickup_won = 0;
              let number_pickdown_won = 0;






              result.summary.three_up.forEach((result) => {

                if(this.lotto.award.award_three_up == result.numbers){
                  tree_won += (result.price*result.employee.three_up_pay)
                }

                tree_summary += result.price ? result.price : 0;
                tree_percent_summary = (tree_summary-(tree_summary*result.employee.three_up)/100);
              });


              result.summary.three_tod.forEach((result) => {

                const three_tod_data = this.lotto.award.award_three_tod.split(",");

                if(three_tod_data.includes(result.numbers)){
                  tree_tod_won += (result.price*result.employee.three_up_pay)
                }

                tree_tod_summary += result.price_divide ? result.price_divide : 0;
                tree_tod_percent_summary = (tree_tod_summary-(tree_tod_summary*result.employee.three_tod)/100);
              });

              result.summary.two_up.forEach((result) => {

                if(this.lotto.award.award_two_up == result.numbers){
                  two_up_won += (result.price*result.employee.two_up_pay)
                }

                two_summary += result.price ? result.price : 0;
                two_percent_summary = (two_summary-(two_summary*result.employee.two_up)/100);
              });

              result.summary.two_down.forEach((result) => {

                if(this.lotto.award.award_two_up == result.numbers){
                  two_down_won += (result.price*result.employee.two_up_pay)
                }

                two_down_summary += result.price ? result.price : 0;
                two_down_percent_summary = (two_down_summary-(two_down_summary*result.employee.two_down)/100);
              });

              result.summary.two_tod.forEach((result) => {

                const two_tod_data = this.lotto.award.award_two_tod.split(",");

                if(two_tod_data.includes(result.numbers)){
                  two_tod_won += (result.price*result.employee.two_tod_pay)
                }

                two_tod_summary += result.price ? result.price : 0;
                two_tod_percent_summary = (two_tod_summary-(two_tod_summary*result.employee.two_tod)/100);
              });

              result.summary.four_tod.forEach((result) => {

                let three_up_data = Array.from(String(this.lotto.award.award_three_up), Number)
                console.log('=========four_tod========')
                console.log(three_up_data[0])
                console.log(three_up_data[1])
                console.log(three_up_data[2])
                console.log('=========four_tod========')

                if( (result.numbers.search(three_up_data[0]) !== -1) && (result.numbers.search(three_up_data[1]) !== -1) && (result.numbers.search(three_up_data[0]) !== -1) ){
                  four_tod_won += (result.price*result.employee.four_tod_pay)
                }



                four_tod_summary += result.price ? result.price : 0;
                four_tod_percent_summary = (four_tod_summary-(four_tod_summary*result.employee.four_tod)/100);
              });

              result.summary.five_tod.forEach((result) => {

                let three_up_data = Array.from(String(this.lotto.award.award_three_up), Number)
                console.log('=========four_tod========')
                console.log(three_up_data[0])
                console.log(three_up_data[1])
                console.log(three_up_data[2])
                console.log('=========four_tod========')

                if( (result.numbers.search(three_up_data[0]) !== -1) && (result.numbers.search(three_up_data[1]) !== -1) && (result.numbers.search(three_up_data[0]) !== -1) ){
                  five_tod_won += (result.price*result.employee.five_tod_pay)
                }

                five_tod_summary += result.price ? result.price : 0;
                five_tod_percent_summary = (five_tod_summary-(five_tod_summary*result.employee.five_tod)/100);
              });

              result.summary.number_up.forEach((result) => {

                const number_up_data = this.lotto.award.award_number_up.split(",");

                if(number_up_data.includes(result.numbers)){
                  number_up_won += (result.price*result.employee.two_tod_pay)
                }

                number_up_summary += result.price ? result.price : 0;
                number_up_percent_summary = (number_up_summary-(number_up_summary*result.employee.number_up)/100);
              });

              result.summary.number_down.forEach((result) => {

                const number_up_data = this.lotto.award.award_number_up.split(",");

                if(number_up_data.includes(result.numbers)){
                  number_up_won += (result.price*result.employee.two_tod_pay)
                }

                number_down_summary += result.price ? result.price : 0;
                number_down_percent_summary = (number_down_summary-(number_down_summary*result.employee.number_down)/100);
              });

              result.summary.number_pick_up.forEach((result) => {
                number_pickup_summary += result.price_hundred ? result.price_hundred : 0;
                number_pickup_summary += result.price_ten ? result.price_ten : 0;
                number_pickup_summary += result.price_unit ? result.price_unit : 0;
                number_pickup_percent_summary = (number_pickup_summary-(number_pickup_summary*result.employee.number_pick_up)/100);
              });

              result.summary.number_pick_down.forEach((result) => {
                number_pickdown_summary += result.price_ten ? result.price_ten : 0;
                number_pickdown_summary += result.price_unit ? result.price_unit : 0;
                number_pickdown_percent_summary = (number_pickup_summary-(number_pickup_summary*result.employee.number_pick_down)/100);
              });

              let percent = (
                  result.employee.three_up+ result.employee.three_tod+ result.employee.two_up+ result.employee.two_down+
                  result.employee.two_tod+ result.employee.four_tod+ result.employee.five_tod+ result.employee.number_up+
                  result.employee.number_down+ result.employee.number_pick_up+ result.employee.number_pick_down)

              let sum_total = ( parseInt(tree_summary +tree_tod_summary +two_summary
                  +two_down_summary +two_tod_summary +four_tod_summary +five_tod_summary
                  +number_up_summary +number_down_summary +number_pickup_summary+number_pickdown_summary));

              let total = (
                  tree_percent_summary+tree_tod_percent_summary+two_percent_summary+two_down_percent_summary
                  +two_tod_percent_summary+four_tod_percent_summary+five_tod_percent_summary+number_up_percent_summary+
                  number_down_percent_summary+number_pickup_percent_summary+number_pickdown_percent_summary)

              let all_won = (tree_won+tree_tod_won+two_up_won+two_down_won+two_tod_won+four_tod_won+five_tod_won+number_up_won+number_down_won+number_pickup_won+number_pickdown_won)

              all_data.push({
                "employee":result.employee,
                "percent":percent,
                "tree_summary":tree_summary,
                "tree_tod_summary":tree_tod_summary,
                "two_summary":two_summary,
                "two_down_summary":two_down_summary,
                "two_tod_summary":two_tod_summary,
                "four_tod_summary":four_tod_summary,
                "five_tod_summary":five_tod_summary,
                "number_up_summary":number_up_summary,
                "number_down_summary":number_down_summary,
                "number_pickup_summary":number_pickup_summary,
                "number_pickdown_summary":number_pickdown_summary,
                "tree_won":tree_won,
                "tree_tod_won":tree_tod_won,
                "two_won":two_up_won,
                "two_down_won":two_down_won,
                "two_tod_won":two_tod_won,
                "four_tod_won":four_tod_won,
                "five_tod_won":five_tod_won,
                "number_up_won":number_up_won,
                "number_down_won":number_down_won,
                "number_pickup_won":number_pickup_won,
                "number_pickdown_won":number_pickdown_won,
                "all_won":all_won,
                "all_total":sum_total,
                "total":total,
              })

              this.all_total += sum_total;
              this.total += total;
              this.all_won += all_won;


            });

            this.all_data = all_data;
            console.log("all_data")
            console.log(all_data)
            this.is_loading = false;

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },

    async getLottoNumber() {

      let Swal = this.$swal;
      this.is_loading = true;

      const params = {}

      params.merchant_id = this.lotto.merchant_id
      params.lottery_id = this.lotto.id
      if (this.employee_id !== "") {
        params.employee_id = this.employee_id
      }


      await GetLottoNumber(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)
        console.log(status)
        console.log(data)


       // let all_data = [];

        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            data.cut_list_three_up.forEach((result)=>{
              this.cut_three_up_total += result.price
            });

            data.cut_list_three_tod.forEach((result)=>{
              this.cut_three_tod_total += result.price
            });

            data.cut_list_two_up.forEach((result)=>{
              this.cut_two_up_total += result.price
            });

            data.cut_list_two_down.forEach((result)=>{
              this.cut_two_down_total += result.price
            });

            data.cut_list_two_tod.forEach((result)=>{
              this.cut_two_tod_total += result.price
            });

            data.cut_list_four_tod.forEach((result)=>{
              this.cut_four_tod_total += result.price
            });

            data.cut_list_five_tod.forEach((result)=>{
              this.cut_five_tod_total += result.price
            });

            data.cut_list_number_up.forEach((result)=>{
              this.cut_number_up_total += result.price
            });

            data.cut_list_number_down.forEach((result)=>{
              this.cut_number_down_total += result.price
            });

            data.cut_list_number_pick_up.forEach((result)=>{
              this.cut_number_pick_up_total += result.price
            });

            data.cut_list_number_pick_down.forEach((result)=>{
              this.cut_number_pick_down_total += result.price
            });



            this.cut_total = (
                this.cut_three_tod_total+
                this.cut_three_up_total+
                this.cut_two_up_total+
                this.cut_two_down_total+
                this.cut_two_tod_total+
                this.cut_five_tod_total+
                this.cut_four_tod_total+
                this.cut_number_down_total+
                this.cut_number_pick_down_total+
                this.cut_number_pick_up_total+
                this.cut_number_up_total
            );
            this.is_loading = false;

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },

    GroupData(){

      Array.prototype.chunk = function(size) {
        let result = [];

        while(this.length) {
          result.push(this.splice(0, size));
        }

        return result;
      }

      console.log("============GroupData============")
      this.tree_up_data_group = this.tree_up_data.chunk(30);
      this.tree_tod_data_group = this.tree_tod_data.chunk(30);
      this.two_up_data_group = this.two_up_data.chunk(30);
      this.two_down_data_group = this.two_down_data.chunk(30);
      this.two_tod_data_group = this.two_tod_data.chunk(30);
      this.four_tod_data_group = this.four_tod_data.chunk(30);
      this.five_tod_data_group = this.five_tod_data.chunk(30);
      this.number_up_data_group = this.number_up_data.chunk(30);
      this.number_down_data_group = this.number_down_data.chunk(30);
      this.number_pick_up_data_group = this.number_pick_up_data.chunk(30);
      this.number_pick_down_data_group = this.number_pick_down_data.chunk(30);




      console.log(this.tree_up_data_group)
      console.log(this.two_up_data_group)
      console.log("============GroupData============")
    },

    async GetEmployeeData(){

      let Swal = this.$swal;

      let params = {};

      params.merchant_id = this.lotto.merchant_id


      await GetEmployee(params, (response) => {
        console.log(response)
        const {status, data, statusText} = ResponseData(response)

        let employee = [];

        console.log(status)
        console.log(data)
        console.log(statusText)


        switch (status) {
          case 200 :
            console.log(data)
            //this.lotto = data;


            employee.push({
              "label":"ทั้งหมด",
              "id":0,
            })


            data.forEach(function (result){
              console.log(result)
              employee.push({
                "label":result.name,
                "id":result.id,
              })
            });

            this.employee = employee;

            this.is_loading = false;
            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            this.is_loading = false;
            break;
        }

      });



    },
    CheckLogin() {

      const UserData = localStorage.getItem('user');
      if (!UserData && this.$router.currentRoute.path !== "/login") {
        window.location = "/login"
      } else {
        this.users = JSON.parse(UserData);
        if (this.users.type !== 'user') {
          this.employee_id = this.users.id;
          this.employees = this.users;
        }
        console.log(this.users)
      }
    },
    initialize() {

    },
    async validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        await this.AddUpdate();
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    sumPrice(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price;
      });

      return total;
    },
    sumPriceDivide(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_divide;
      });

      return total;
    },
    sumPriceHundred(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_hundred;
      });

      return total;
    },
    sumPriceTen(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_ten;
      });

      return total;
    },
    sumPriceUnit(data){
      let total = 0;

      data.forEach((result)=>{
        total += result.price_unit;
      });

      return total;
    },
    CheckThreeTod(data){
      console.log("==========CheckThreeTod=========")

      let three_tod_numbers = [];

      data.three_tod.forEach((result)=>{


        three_tod_numbers.push({
          "numbers":result.numbers,
          "price_divide":result.price_divide,
        })

      });

      //console.log(three_tod_numbers.length);

      let sort_number = three_tod_numbers.sort(function(a, b){return a.numbers-b.numbers})

      let three_tod_numbers_sort = [];

      //console.log(sort_number);
      //console.log(sort_number.length);

      sort_number.forEach((result)=>{

        console.log(result)

        let find_tod
        let find_tod_suffle

        if(three_tod_numbers_sort.length !== 0){
          let number_array = Array.from(String(result.numbers), Number)
          let number_result = number_suffle_three(number_array);

          find_tod = three_tod_numbers_sort.find(tod => tod.numbers === result.numbers);
          find_tod_suffle = three_tod_numbers_sort.find(tod => number_result.includes(tod.numbers));
        }

        console.log(find_tod)

        if(find_tod){
          find_tod.price_divide += result.price_divide;
        }else if(find_tod_suffle)
        {
          find_tod_suffle.price_divide += result.price_divide;
        }
        else{
          three_tod_numbers_sort.push({
            "numbers":result.numbers,
            "price_divide":result.price_divide,
          })
        }


      });

      console.log(three_tod_numbers_sort);
      console.log("==========CheckThreeTod=========")
    },
    OnPrint(){
      window.print()
    },
  },


}


</script>

<style scoped>


@media print {
  .menu_custom_print{
    display: none;
  }
  .print-icon-contrainer{
    display: none;
  }
  .ist-container-profit-report{
    width: 100%;
  }
  .list-container-profit-report{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: auto;

  }

  .profit-text-head{
    background: #80d9fc;
    border: 1px solid #000000;
    font-size: 2rem;
  }
  .font_size_1{
    font-size: 1.3rem;
  }
  .list-container-profit-report div{
    text-align: center;
  }
  .print-table{
    width: 100%;
    font-size: 1rem;
    font-weight: 100;
    border-collapse: collapse;
  }
  .print-table th{
    font-weight: 300;
    padding: 0.2rem;
  }
  .print-table td{
    font-weight: 300;
    text-align: center;
  }
}

@media screen {
  .print-table{
    width: 95%;
    font-size: 1rem;
    border-collapse: collapse;
  }
  .print-table th{
    font-weight: 300;
    padding: 0.2rem;
  }
  .print-table td{
    font-weight: 200;
    text-align: center;
  }
  .page_print{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 2rem;
  }
  .font_size_1{
    font-size: 1.3rem;
  }
  .list-container-profit-report{
    padding: 1rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    height: auto;
  }
  .list-container-profit-report div{
    text-align: center;
  }
  .profit-text-head{
    background: #80d9fc;
    border: 1px solid #000000;
    font-size: 2rem;
  }

  .card_container {
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
  }

  .card_header {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
  }

  .card_content {
    padding: 1rem;
  }

  .img_thumb {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
  }

  .form_action {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .image_preview_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #image_preview {
    width: 20rem;
    text-align: center;
  }

  .btn_sale {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
  }

  .btn_sale:active {
    background: #0686E8;
    color: #FFFFFF;
  }

  .btn_sale.active {
    background: #0686E8;
    color: #FFFFFF;
  }

  .button_list {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sale_container {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form_cal {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
  }

  .input-custom {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
  }

  .table_custom {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
  }

  .head_title {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .head_title_sub {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .table_list {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .data_table {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .table_custom_summary {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
  }

  .head_title_summary {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .head_title_sub_summary {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
  }

  .table_list_summary {
    width: 80%;
    display: flex;
    justify-content: center;
  }

  .data_table_summary {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
  }

  .delete_btn {
    color: red !important;
  }

  .btn_sale:disabled {
    filter: saturate(0);
  }

  .input-custom:disabled {
    filter: saturate(0);
  }

  .loading_image {
    height: 8rem;
    margin-bottom: -5rem;
  }
  .header_top_report{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
  }
  .table-custom{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  .table-custom tr th{
    padding: 0.5rem;
    padding-inline: 2rem;
  }
  .table-custom tr td{
    padding: 0.5rem;
    text-align: center;
  }
  .table-custom tr td.sum_text{
    text-align: left;
  }
  .report_table{
    padding: 0.5rem;
  }
  .loading_image_main{
    margin-block: -5rem;
  }
  .print-icon-contrainer{
    width: 100%;
    padding:1rem;
  }
  .print-icon{
    color: #0686E8 !important;
    font-size: 3rem;
    padding: 1rem;
    padding-bottom: 0;
  }

}

</style>

<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>เพิ่มข้อมูลผู้ใช้งาน</div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="name"
              :rules="[v => !!v || 'กรุณากรอกขื่อ']"
              label="ชื่อ"
              required
          ></v-text-field>

          <v-text-field
              v-model="username"
              :counter="10"
              :rules="[v => !!v || 'กรุณากรอก Username']"
              label="UserName"
              required
          ></v-text-field>


          <font class="title-percent" > ตั้งค่า </font>

          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="three_up"
                  :rules="[v => !!v || 'กรุณากรอก % 3 ตัวตรง']"
                  label=" % 3 ตัวตรง"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="three_up_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย 3 ตัวตรง']"
                  label="ราคาจ่าย 3 ตัวตรง"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="three_tod"
                  :rules="[v => !!v || 'กรุณากรอก % 3 ตัวโต๊ด']"
                  label=" % 3 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="three_tod_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย 3 ตัวโต๊ด']"
                  label="ราคาจ่าย 3 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="two_up"
                  :rules="[v => !!v || 'กรุณากรอก % 2 ตัวบน']"
                  label=" % 2 ตัวบน"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="two_up_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย 2 ตัวบน']"
                  label="ราคาจ่าย 2 ตัวบน"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="two_down"
                  :rules="[v => !!v || 'กรุณากรอก %  2 ตัวล่าง']"
                  label=" %  2 ตัวล่าง"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="two_down_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย  2 ตัวล่าง']"
                  label="ราคาจ่าย  2 ตัวล่าง"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="two_tod"
                  :rules="[v => !!v || 'กรุณากรอก % 2 ตัวโต๊ด']"
                  label=" % 2 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="two_tod_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย 2 ตัวโต๊ด']"
                  label="ราคาจ่าย 2 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="four_tod"
                  :rules="[v => !!v || 'กรุณากรอก % 4 ตัวโต๊ด']"
                  label=" % 4 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="four_tod_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย 4 ตัวโต๊ด']"
                  label="ราคาจ่าย 4 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="five_tod"
                  :rules="[v => !!v || 'กรุณากรอก % 5 ตัวโต๊ด']"
                  label=" % 5 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="five_tod_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย 5 ตัวโต๊ด']"
                  label="ราคาจ่าย 5 ตัวโต๊ด"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_up"
                  :rules="[v => !!v || 'กรุณากรอก % วิ่งบน']"
                  label=" % วิ่งบน"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_up_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย วิ่งบน']"
                  label="ราคาจ่าย วิ่งบน"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_down"
                  :rules="[v => !!v || 'กรุณากรอก % วิ่งล่าง']"
                  label=" % วิ่งล่าง"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_down_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย วิ่งล่าง']"
                  label="ราคาจ่าย วิ่งล่าง"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_pick_up"
                  :rules="[v => !!v || 'กรุณากรอก % วิ่งปักบน']"
                  label=" % วิ่งปักบน"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_pick_up_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย วิ่งปักบน']"
                  label="ราคาจ่าย วิ่งปักบน"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_pick_down"
                  :rules="[v => !!v || 'กรุณากรอก % วิ่งปักล่าง']"
                  label=" % วิ่งปักล่าง"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" >
              <v-text-field
                  v-model="number_pick_down_pay"
                  :rules="[v => !!v || 'กรุณากรอกราคาจ่าย วิ่งปักล่าง']"
                  label="ราคาจ่าย วิ่งปักล่าง"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xl="6" >
              <v-text-field
                  v-model="pay_half_price"
                  :rules="[v => !!v || 'กรุณากรอกจ่ายครึ่งราคา']"
                  label="จ่ายครึ่งราคา"
                  required
              ></v-text-field>
            </v-col>
          </v-row>



          <br>



          <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="รหัสผ่าน"
              counter
              @click:append="show1 = !show1"
          ></v-text-field>


          <v-text-field
              v-model="password_confirm"
              :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[v => !!v || 'กรุณากรอกยืนยันรหัสผ่าน', v => v === this.password || 'รหัสผ่านไม่ตรงกัน']"
              :type="show4 ? 'text' : 'password'"
              name="input-10-2"
              label="ยืนยันรหัสผ่าน"
              :error="this.password !== this.password_confirm"
              @click:append="show4 = !show4"
          ></v-text-field>





          <div align="center" style="margin-top: 2rem" >
            <v-btn
                color="success"
                class="mr-4"
                @click="validate"
            >
              เพิ่มข้อมูล
            </v-btn>

            <v-btn
                color="error"
                class="mr-4"
                @click="reset"
            >
              ล้างค่า
            </v-btn>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {PostEmployee} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    username: '',
    password: '',
    password_confirm: '',
    name: '',
    users:{},
    three_up: '',
    three_tod: '',
    two_up: '',
    two_down: '',
    two_tod: '',
    four_tod: '',
    five_tod: '',
    number_up: '',
    number_down: '',
    number_pick_up: '',
    number_pick_down: '',
    three_up_pay: '',
    three_tod_pay: '',
    two_up_pay: '',
    two_down_pay: '',
    two_tod_pay: '',
    four_tod_pay: '',
    five_tod_pay: '',
    number_up_pay: '',
    number_down_pay: '',
    number_pick_up_pay: '',
    number_pick_down_pay: '',
    pay_half_price: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],

    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลผู้ใช้',
        disabled: false,
        href: '/employee',
      },
      {
        text: 'เพิ่มข้อมูลผู้ใช้',
        disabled: true,
        href: '/',
      },
    ],
    dialog: false,
    dialogDelete: false,
    desserts: [],
    editedIndex: -1,
    resultData:[],
  }),

  computed: {

  },
  watch: {

  },

  async created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.CheckLogin()
    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.Submit();
      }
    },
    reset () {
      this.$refs.form.reset()

    },
    async Submit(){

      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("name",this.name)
      formData.append("username",this.username)
      formData.append("three_up",this.three_up)
      formData.append("three_tod",this.three_tod)
      formData.append("two_up",this.two_up)
      formData.append("two_down",this.two_down)
      formData.append("two_tod",this.two_tod)
      formData.append("four_tod",this.four_tod)
      formData.append("five_tod",this.five_tod)
      formData.append("number_up",this.number_up)
      formData.append("number_down",this.number_down)
      formData.append("number_pick_up",this.number_pick_up)
      formData.append("number_pick_down",this.number_pick_down)
      formData.append("three_up_pay",this.three_up_pay)
      formData.append("three_tod_pay",this.three_tod_pay)
      formData.append("two_up_pay",this.two_up_pay)
      formData.append("two_down_pay",this.two_down_pay)
      formData.append("two_tod_pay",this.two_tod_pay)
      formData.append("four_tod_pay",this.four_tod_pay)
      formData.append("five_tod_pay",this.five_tod_pay)
      formData.append("number_up_pay",this.number_up_pay)
      formData.append("number_down_pay",this.number_down_pay)
      formData.append("number_pick_up_pay",this.number_pick_up_pay)
      formData.append("number_pick_down_pay",this.number_pick_down_pay)
      formData.append("pay_half_price",this.pay_half_price)
      formData.append("password",this.password)
      formData.append("merchant_id",this.users.merchant_id)


      await PostEmployee(formData,(response)=>{

        console.log(response)
        const { status ,data ,statusText  } = ResponseData(response)

        switch (status) {
          case 200 :

            Swal({
              icon: "success",
              title: "success",
              text:  "เพิ่มข้อมูลสำเร็จ",
            }).then(()=>{
              this.$router.push({ path: '/employee/' })
            })


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;


          default:
            Swal({
              icon: "error",
              title: statusText,
              text: data.message,
            })
            break;
        }

      });

    },

    CheckLogin(){

      const UserData = localStorage.getItem('user');
      if(!UserData && this.$router.currentRoute.path !== "/login"){
        window.location = "/login"
      }else{
        this.users = JSON.parse(UserData);
      }
    },
  },
}


</script>

<style scoped>

.title-percent{
  color: #666363;
  font-size: 16px;
}

.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
</style>

var render, staticRenderFns
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=d20dfc1a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d20dfc1a",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=div&class=card_container&v-if=lotto.date_start"
if (typeof block0 === 'function') block0(component)
import block1 from "./index.vue?vue&type=custom&index=1&blockType=div&class=card_container&v-else=true"
if (typeof block1 === 'function') block1(component)

export default component.exports
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Sale from '../views/Sale/index'
import Report from '../views/Report/index'
import ReportPrint from '../views/ReportPrint/index'
import ReportCut from '../views/ReportCut'
import ReportSummary from '../views/ReportSummary/index'
import ReportProfit from '../views/ReportProfit'
import ReportProfitAll from '../views/ReportProfitAll'
import Employee from '../views/Employee/index.vue'
import EmployeeCreate from '../views/Employee/create.vue'
import EmployeeEdit from '../views/Employee/edit.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'หน้าหลัก',
    component: Home
  },
  {
    path: '/sale',
    name: 'รายการขาย',
    component: Sale
  },
  {
    path: '/report',
    name: 'รายงาน',
    component: Report
  },
  {
    path: '/reportcut/',
    name: 'รายงาน',
    component: ReportCut
  },
  {
    path: '/reportcut/:id',
    name: 'รายงาน',
    component: ReportCut
  },
  {
    path: '/report_print',
    name: 'พิมพ์รายงาน',
    component: ReportPrint
  },
  {
    path: '/report_summary',
    name: 'รายงานรวม',
    component: ReportSummary
  },
  {
    path: '/report_profit',
    name: 'รายงานกำไรได้เสีย',
    component: ReportProfit
  },
  {
    path: '/report_profit_all',
    name: 'รายงานกำไรได้เสียรวม',
    component: ReportProfitAll
  },
  {
    path: '/employee',
    name: 'ข้อมูลพนักงาน',
    component: Employee
  },
  {
    path: '/employee/:id',
    name: 'แก้ไขข้อมูลพนักงาน',
    component: EmployeeEdit
  },
  {
    path: '/employeeCreate',
    name: 'เพิ่มข้อมูลพนักงาน',
    component: EmployeeCreate
  },
  {
    path: '/login',
    name: 'ล็อคอิน',
    component: Login
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,form,next)=>{
  document.title = to.name;
  next();
});

export default router

import {API,instance} from "../const/api";

export async function auth(formData,Calback){
    await instance.post(API.Auth,formData)   .then(function (response) {
        Calback(response)
    })
        .catch(function (response) {
            Calback(response.response)
        });
}

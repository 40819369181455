
<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>ข้อมูลผู้ใช้</div>
        <div class="date-picker-container" >
          <v-btn
              class="mx-2"
              fab
              dark
              color="indigo"
              to="/employeeCreate"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>

      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :href="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <div class="search_container" >
        <div class="search-form" >
          <v-text-field v-model="search" label="ค้นหา" class="search_ip"  ></v-text-field>
        </div>
      </div>

      <v-data-table
          :headers="headers"
          :items="resultData"
          :search="search"
          hide-actions
          sort-by="calories"
          class="elevation-1"
      >
        <template v-slot:top>
        </template>
        <template v-slot:item.three_up="{ item }"  >
          <div class="list_data" >
            <font>{{ item.three_up }}</font>
            <font>{{ item.three_up_pay }}</font>
          </div>
        </template>
        <template v-slot:item.three_tod="{ item }"  >
          <div class="list_data" >
            <font>{{ item.three_tod }}</font>
            <font>{{ item.three_tod_pay }}</font>
          </div>
        </template>
        <template v-slot:item.two_up="{ item }"  >
          <div class="list_data" >
            <font>{{ item.two_up }}</font>
            <font>{{ item.two_up_pay }}</font>
          </div>
        </template>
        <template v-slot:item.two_down="{ item }"  >
          <div class="list_data" >
            <font>{{ item.two_down }}</font>
            <font>{{ item.two_down_pay }}</font>
          </div>
        </template>
        <template v-slot:item.two_tod="{ item }"  >
          <div class="list_data" >
            <font>{{ item.two_tod }}</font>
            <font>{{ item.two_tod_pay }}</font>
          </div>
        </template>
        <template v-slot:item.four_tod="{ item }"  >
          <div class="list_data" >
            <font>{{ item.four_tod }}</font>
            <font>{{ item.four_tod_pay }}</font>
          </div>
        </template>
        <template v-slot:item.five_tod="{ item }"  >
          <div class="list_data" >
            <font>{{ item.five_tod }}</font>
            <font>{{ item.five_tod_pay }}</font>
          </div>
        </template>
        <template v-slot:item.number_up="{ item }"  >
          <div class="list_data" >
            <font>{{ item.number_up }}</font>
            <font>{{ item.number_up_pay }}</font>
          </div>
        </template>
        <template v-slot:item.number_down="{ item }"  >
          <div class="list_data" >
            <font>{{ item.number_down }}</font>
            <font>{{ item.number_down_pay }}</font>
          </div>
        </template>
        <template v-slot:item.number_pick_up="{ item }"  >
          <div class="list_data" >
            <font>{{ item.number_pick_up }}</font>
            <font>{{ item.number_pick_up_pay }}</font>
          </div>
        </template>
        <template v-slot:item.number_pick_down="{ item }"  >
          <div class="list_data" >
            <font>{{ item.number_pick_down }}</font>
            <font>{{ item.number_pick_down_pay }}</font>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2 icon-edit"
              @click="editItem(item.id)"
          >
            mdi-pencil
          </v-icon>

          <v-icon
              small
              class="mr-2 icon-delete"
              @click="deletes(item.id)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="initialize"
          >
            Reset
          </v-btn>
        </template>

      </v-data-table>


    </div>
  </v-breadcrumbs>




</template>



<script>
import {DeleteEmployee, GetEmployee} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import {ResponseData} from "../../lib/utility";


export default {
  name: "Users",
  data: () => ({
    baseUrl:baseUrl,
    name:"",
    stock:0,
    product_id:"",
    codes:"",
    machine_ids:"",
    valid: true,
    search: "",
    dialog: false,
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลผู้ใช้',
        disabled: true,
        href: '/Product',
      },
    ],

    dialogDelete: false,
    showModal: true,
    headers: [
      { text: 'ชื่อ', value: 'name' },
      { text: 'แก้ไข', value: 'actions', sortable: false },
      { text: 'ลด %,จ่าย 3 ตัวตรง', value: 'three_up' },
      { text: 'ลด %,จ่าย 3 ตัวโต๊ด', value: 'three_tod' },
      { text: 'ลด %,จ่าย 2 ตัวบน', value: 'two_up' },
      { text: 'ลด %,จ่าย 2 ตัวล่าง', value: 'two_down' },
      { text: 'ลด %,จ่าย 2 ตัวโต๊ด', value: 'two_tod' },
      { text: 'ลด %,จ่าย 4 ตัวโต๊ด', value: 'four_tod' },
      { text: 'ลด %,จ่าย 5 ตัวโต๊ด', value: 'five_tod' },
      { text: 'ลด %,จ่าย วิ่งบน', value: 'number_up' },
      { text: 'ลด %,จ่าย วิ่งล่าง', value: 'number_down' },
      { text: 'ลด %,จ่าย วิ่งปักบน', value: 'number_pick_up' },
      { text: 'ลด %,จ่าย วิ่งปักล่าง', value: 'number_pick_down' },

    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],
    machine_id: 99,
    machine_item: {},
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {

  },
  created () {
    this.initialize()
    this.getData()
    this.GetMachines()
  },

  methods: {

    async editItem(id) {
     this.$router.push({ path: '/employee/'+id })
    },
    async deletes(id,machine){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            this.delete(id,machine)
        }
      })

      ;
    },
    async initialize () {
      await this.CheckLogin()
      this.getData()
    },
   async getData(){

     let Swal = this.$swal;

     const params = {}

     params.merchant_id = this.users.merchant_id

     await GetEmployee(params,(response)=>  {
       console.log(response)
       const { status ,data ,statusText  } = ResponseData(response)
       console.log(status)
       console.log(data)
       console.log(statusText)


       switch (status) {
         case 200 :
           console.log(data)
           this.resultData = data;

           break;


         default:
           Swal({
             icon: "error",
             title: statusText,
             text: data.message,
           })
           break;
       }

     });
    },
   async delete(id,machine){
      let Swal = this.$swal;
      const { status } = await DeleteEmployee(id,machine);
      if(status === 200){
        Swal({
          icon: 'success',
          title: 'Success',
        })
       await this.getData()
      }
    },
    CheckLogin(){

      const UserData = localStorage.getItem('user');
      if(!UserData && this.$router.currentRoute.path !== "/login"){
        window.location = "/login"
      }else{
        this.users = JSON.parse(UserData);
      }
    },
  },
}


</script>

<style scoped>
.date-picker-container{
  display: flex;
  gap: 15px;
}
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img_thumb{
  width:8rem;
  border-radius:10px;
  margin:0.2rem;
}
.search_ip{
  font-size: 1rem;
}
.search_container{
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
.search-form{
  width: 20%;
}
.list_data{
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 3px;
}
.icon-edit{
  color: #0686E8 !important;
}
.icon-delete{
  color: #f80a1f !important;
}
</style>
